
export default {
    branchoffice_warehouses: [],

    params: {
        paginate: true,
        page: 1,
        perPage: 15,
        filter: '',
        sortBy: 'id',
        sort: 'ASC'
    }

}
