import moment from 'moment';

export const request_guarantee = () => {
    return {
        consecutive: null,
        contact_warehouse_id: null,
        product_description: null,
        serial: null,
        product_id: null,
        product_to_send_id: null,
        approval_status_id: null,
        approval_reason_id: null,
        approval_reason: {},
        estimated_delivery_date: null,
        customer_received_date: null,
        contact_warehouse: {},
        product: {},
        product_to_send: {},
        approval_status: {},
        request_guarantee_attachments: [],
        request_guarantee_states: [],
        request_guarantee_state: {},
        observation: '',
        images_product: [],
        images_product_loaded: [],
        other_images: [],
        other_images_loaded: [],
        images_guide: [],
        images_guide_loaded: [],
        attachments_deleted: []
    };
};

export default {
    requests_guarantees: {
        data: []
    },
    request_guarantee: request_guarantee(),
    action: 'Crear',
    step: 1,
    step_available: 1,
    sending: false,
    modal: {
        show: false
    },
    preloader: false,
    filters: {
        page: 1,
        per_page: 10,
        paginate: true,
        contact_warehouse_id: null,
        dates: [
            moment().add('month', -2).add('days', 1).format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
        ]
    },
    steps: [
        {
            step: 1,
            code: 'PGS1',
            text: 'Solicitar',
            icon: 'fa-file-text-o'
        },
        {
            step: 2,
            code: 'PGS2',
            text: 'Recibido Propartes',
            icon: 'fa-check'
        },
        {
            step: 3,
            code: 'PGS3',
            text: 'Evaluación',
            icon: 'fa-list'
        },
        {
            step: 4,
            code: 'PGS4',
            text: 'Envío cliente',
            icon: 'fa-truck'
        },
        {
            step: 5,
            code: 'PGS5',
            text: 'Recibido cliente',
            icon: 'fa-archive'
        }
    ]
};
