import {defaultProspective} from './state';

export function setProspective(state, prospective) {

    const line = prospective.line;
    const city = prospective.city;
    const clerk = prospective.contact.clerks[0] ? prospective.contact.clerks[0] : null;

    state.prospective = {
        contact_id: prospective.contact_id,
        identification_type: prospective.contact.identification_type,
        identification: prospective.contact.identification,
        check_digit: prospective.contact.check_digit,
        name: prospective.contact.name,
        surname: prospective.contact.surname,
        surname_2: prospective.contact.surname_2,
        address: prospective.address,
        address_object: prospective.contact.address_object,
        main_telephone: prospective.telephone,
        cell_phone: prospective.contact.cell_phone,
        observations: prospective.observation,
        establishment_photo: prospective.establishment_photo,
        lines: line ? line.line_id : null,
        photo: null,
        seller_id: prospective.seller_id,
        contact_name: clerk ? clerk.name : null,
        contact_surname: clerk ? clerk.surname : null,
        gender: clerk ? clerk.gender : null,
        email: clerk ? clerk.email : null,
        latitud: prospective.latitude,
        longitud: prospective.length,
        city: {
            department_id: city ? city.department_id : null,
            city_id: city ? city.id : null
        },
        warehouse_id: null,
        position: clerk ? clerk.position : null,
    };

}

export function cleanInputCity(state) {
    state.prospective.city_id = '';
}

export function setTargetImg(state, payload) {
    state.prospective.photo = payload;
}

export function setPhotoFile(state, photo) {
    state.prospective.photo = photo;
}

export function setDepartments(state, departments) {
    state.departments = departments.map(p => {
        return {value: p.id, label: p.department};
    });
}

export function setCities(state, cities) {
    state.cities = cities.map(p => {
        return {value: p.id, label: p.city};
    });
}

export function prospectiveError(state, payload) {
    state.error = payload;
}

export function setIdentificationTypes(state, identificationTypes) {
    state.identificationTypes = identificationTypes;
}

export function setLines(state, lines) {
    state.lines = lines.map(p => {
        return {value: p.id, label: p.line_description};
    });
}

export function resetProspectiveForm(state) {
    Object.assign(state.prospective, defaultProspective());
}

export function getProspectiveWarehouseId(state, warehouse_id) {
    state.prospective.warehouse_id = warehouse_id;
}

export function setCustomers(state, customers) {
    state.customers = customers;
}

export function setEditing(state, editing) {
    state.editing = editing;
}

/**
 * Establecer un valor para el atributo address de la variable prospective.
 */
export function setAddressToProspective(state, address) {
    state.prospective.address = address;
}

/**
 * Establecer un valor para la variable sellers.
 */
export function setSellers(state, sellers) {
    state.sellers = sellers;
}

