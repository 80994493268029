
import Vue from 'vue';
import Vuex from 'vuex';

import mutations from './mutations';
import licenses from '../modules/licenses';
import paramTables from '../modules/param-tables';
import parameters from '../modules/parameters';
import tabsViews from '../modules/tabs';
import subscribers from '../modules/subscribers'
import vouchersTypes from '../modules/vouchers-types'
import branchOffices from '../modules/branch-offices'
import branchOfficeWarehouses from '../modules/branchoffices-warehouses'
import contacts from '../modules/contacts'
import contactClerks from '../modules/contacts-clerks'
import contactWarehouses from '../modules/contacts-warehouses'
import contactDocuments from '../modules/contacts-documents'
import departments from '../modules/departments'
import cities from '../modules/cities'
import lines from '../modules/lines'
import sublines from '../modules/sublines'
import users from '../modules/users'
import leaderships from '../modules/leadership'
import login from '../modules/login'
import brands from '../modules/brands'
import subbrands from '../modules/subbrands'
import categories from '../modules/categories'
import subcategories from '../modules/subcategories'
import menuSubscribers from '../modules/menu_subscribers'
import bannerB2b from '../modules/banners-b2b'
import home from '../b2b/modules/home'
import catalog from '../b2b/modules/catalog'
import ecommerce from '../modules/ecommerce'
import productDetail from '../b2b/modules/product-detail'
import cart from '../b2b/modules/cart'
import relativeProductsForm from '../b2b/modules/relative-products-form'
import ordersHistory from '../b2b/modules/orders-history'
import quotationsHistory from '../b2b/modules/quotations'
import receivable from '../b2b/modules/receivable'
import replacementAssistant from '../b2b/modules/replacement-assistant'
import payment_management from '../modules/payment-managment'
import visits from '../modules/visits'
import orders from '../modules/orders'
import coordinateseller from '../modules/coordinate-seller'
import commercialManagement from '../modules/commercial-management'
import prospectiveCustomer from '../modules/prospective-customer'
import products from '../modules/products'
import customersRestrictions from '../modules/customers-restrictions'
import lostSales  from '../modules/lost_sales'
import quotations  from '../modules/quotations'
import customerParticipation  from '../modules/customerParticipation'
import reportDynamicMatrix from './../reports-aggrid/modules/dynamic-matrix'
import reportCommercialManagement from './../reports-aggrid/modules/commercial_management'
import reportSalesProducts from './../reports-aggrid/modules/sales-products'
import roles from '../modules/roles'
import salesBudgets from '../modules/sales-budgets'
import schedule from '../modules/schedule'
import deepening from '../modules/deepening'
import guarantees from '../b2b/modules/guarantees'
import guaranteesAdmin from '../modules/guarantees_admin'
import guarantees_reasons from '../modules/guarantees_reasons'
import price_lists_config from '../modules/price_lists_config'
import activities from '../modules/activities'
import seller_route from "../modules/seller-route";
import sellers_updater from "../modules/sellers_updater";
import commercial_directions from '../modules/commercial_directions';

Vue.use(Vuex);

//=======vuex store start===========
const store = new Vuex.Store({
    state: {
        left_open: true,
        right_open: false,
        cart_open: false,
        preloader: true,
        user: {
            name: "Addison",
            picture: "images/avatar1.jpg",
            job: "Project Manager"
        }
    },
    mutations,
    modules: {
        licenses,
        paramTables,
        parameters,
        tabsViews,
        subscribers,
        vouchersTypes,
        branchOffices,
        branchOfficeWarehouses,
        contacts,
        contactClerks,
        contactWarehouses,
        contactDocuments,
        departments,
        cities,
        lines,
        sublines,
        users,
        leaderships,
        login,
        brands,
        subbrands,
        categories,
        subcategories,
        menuSubscribers,
        bannerB2b,
        home,
        catalog,
        ecommerce,
        productDetail,
        cart,
        relativeProductsForm,
        ordersHistory,
        quotationsHistory,
        receivable,
        replacementAssistant,
        payment_management,
        visits,
        orders,
        coordinateseller,
        commercialManagement,
        prospectiveCustomer,
        products,
        customersRestrictions,
        lostSales,
        quotations,
        customerParticipation,
        reportDynamicMatrix,
        reportCommercialManagement,
        reportSalesProducts,
        roles,
        salesBudgets,
        schedule,
        deepening,
        guarantees,
        guaranteesAdmin,
        seller_route,
        guarantees_reasons,
        activities,
        price_lists_config,
        sellers_updater,
        commercial_directions
    },
}); //=======vuex store end===========

export default store
