export const defaultSelectedSubline = () => {
    return {
        subline_code:'',
        subline_description:'',
        short_description:'',
        lock_buy:0,
        block_sale:0,
        margin_cost:'',
        calculates_sale_price:0,
        inventories_account:'',
        account_cost:'',
        sales_account:'',
    }
}

export default {

    sublines: [],
    selectedSubline: defaultSelectedSubline(),
    error: false,
    errorMessage: '',
    actionSubline: 'create',
    getContactEdit: true,

}
