import Vue from 'vue'
import VueRouter from 'vue-router'
import "babel-polyfill";
import store from './store/store'
import App from './App'
import routes from './routes'
import jQuery from 'jquery';
import BootstrapVue from 'bootstrap-vue';

import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.use(VueRouter)
Vue.use(BootstrapVue)

const router = new VueRouter({
    routes,
    linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {

    store.commit("routeChange", "start");

    let publicPage = ['/login', '/restablecer/contrasena', '/olvide/contrasena'];
    let authRequired = !publicPage.includes(to.path)

    let user_logued = localStorage.getItem('user')

    const loggedIn = user_logued !== null ? JSON.parse(user_logued) : false

    if (to.path.indexOf('/restablecer/contrasena/') >= 0) {
        authRequired = false
    }

    if (authRequired) {
        if (loggedIn) {
            if (to.path !== "/cambiar/contrasena") {
                if (!loggedIn.password_changed) {
                    return next('/cambiar/contrasena')
                }
            }
        } else {
            return next('/login');
        }
    }

    if (to.fullPath === '/login' && loggedIn) {
        let next_route = '/inicio';

        if (!validatePermission('E-commerce')) {
            let list = [
                {
                    to: '/terceros',
                    permission: 'Terceros',
                },
                {
                    to: '/terceros-marcas',
                    permission: 'Restricción marcas',
                },
                {
                    to: '/marcas',
                    permission: 'Marcas',
                },
                {
                    to: '/productos/cargar-imagenes',
                    permission: 'Subir Imágenes Productos',
                },
                {
                    to: '/ventas-perdidas',
                    permission: 'Ventas perdidas',
                },
                {
                    to: '/usuarios',
                    permission: 'Usuarios',
                },
                {
                    to: '/jefaturas',
                    permission: 'Jefaturas',
                },
                {
                    to: '/direcciones-comerciales',
                    permission: 'Direcciones Comerciales'
                },
                {
                    to: '/participacion-clientes',
                    permission: 'Participación de clientes por mes',
                },
                {
                    to: '/banners',
                    permission: 'Banners'
                },
                {
                    to: '/cargue_presupuesto',
                    permission: 'Cargue Presupuesto'
                },
                {
                    to: '/configuracion-profundizacion',
                    permission: 'Configuración profundización'
                },
                {
                    to: '/motivos-garantias',
                    permission: 'Motivos garantías'
                },
                {
                    to: '/actividades',
                    permission: 'Actividades'
                },
                {
                    to: '/configuracion-listas-de-precios',
                    permission: 'Configuración listas de precios'
                },
                {
                    to: '/actualizacion-vendedores',
                    permission: 'Actualización de vendedores'
                },
                {
                    to: '/manual-de-procesos',
                    permission: 'Manual de procesos'
                },
                {
                    to: '/roles-permisos',
                    role: 'Super Administrador'
                },
                {
                    to: '/ecommerce',
                    permission: 'E-commerce',
                },
                {
                    to: '/b2b/tercer/lista-clientes-prospectos',
                    permission: 'Cliente Prospecto',
                },
                {
                    to: '/b2b/pedidos',
                    permission: 'Gestión de pedidos',
                },
                {
                    to: '/b2b/cotizaciones',
                    permission: 'Gestión de cotizaciones',
                },
                {
                    to: '/b2b/promociones',
                    permission: 'Gestión comercial',
                },
                {
                    to: '/gestion-de-cobranzas',
                    permission: 'Gestión de cobranzas',
                },
                {
                    to: '/seguimiento-vendedores',
                    permission: 'Gestión de visitas',
                },
                {
                    to: '/reporte/matriz-dinamica',
                    permission: 'Analytrix Ejecución presupuesto año',
                },
                {
                    to: '/reporte/gestion-actividad-comercial',
                    permission: 'Gestión actividad comercial',
                },
                {
                    to: '/ruta-vendedor',
                    permission: 'Ruta vendedor',
                },
                {
                    to: '/agenda',
                    permission: 'Agenda'
                },
                {
                    to: '/solicitud-garantias',
                    permission: 'Gestión de Garantías'
                }
            ];

            list.map(l => {
                if (next_route === '/inicio' && validatePermission(l.permission)){
                    next_route = l.to
                }
            })
        }

        return next(next_route)
    }

    if (to.path.includes('/b2b')) {
        store.commit('hideCart', "hide")
    }

    if (to.meta.permission && to.meta.permission !== '') {
        if (!validatePermission(to.meta.permission)) {
            if (to.path.includes('/b2b')) {
                return next('/b2b/catalogo')
            }

            return next(from.path)
        }
    } else if (to.meta.role && to.meta.role !== '') {
        if (!validateRole(to.meta.role)) {
            if (to.path.includes('/b2b')) {
                return next('/b2b/catalogo')
            }

            return next(from.path)
        }
    }

    next();
})

function validatePermission(permission) {

    let permissions = JSON.parse(localStorage.getItem('permissions'))

    return !!permissions.find(p => p.name === permission);
}

function validateRole(role) {
    let user = JSON.parse(localStorage.getItem('user'))

    return user.role === role;
}

router.afterEach((to, from) => {
    document.title = to.meta.title
    store.commit("routeChange", "end");
    store.commit('left_menu', "close");
})

// Configurando jQuery como una variable global
window.$ = window.jQuery = jQuery;

Vue.prototype.$VUE_APP_API_URL = process.env.VUE_APP_API_URL;

new Vue({
    el: "#app",
    render: h => h(App),
    router,
    store,
    template: '<App/>',
    components: {
        App
    }
}).$mount('#app')


