import axios from 'axios'
import { getApiDomain } from "./util/domains";

const $http = axios.create();

const api_domain = getApiDomain()

$http.interceptors.request.use(req => {

    req.url = process.env.VUE_APP_API_URL + req.url

    let user = JSON.parse(localStorage.getItem('user')) || [];

    if (user && user.token) {
        req.headers.Authorization = `Bearer ${user.token}`
    } else {
        req.headers.Authorization = ''
    }

    return req

}, error => {
    console.log(`interceptor request ${error}`)
})

$http.interceptors.response.use(resp => resp, error => {

    let originalReq = error.config

    if (error.response.status === 401 && !originalReq._retry) {
        if (error.response.data.message === "TOKEN_INVALID" || error.response.data.message === "TOKEN_EXPIRED") {
            window.location.href = "/#/login"
            localStorage.clear()
        }
    }

    return Promise.reject(error)
})

$http.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        localStorage.clear();

        if (!window.location.href.includes('/login')) {
            window.location.reload();
        }
    }

    return Promise.reject(error);
});


export default $http;
