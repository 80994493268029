import $http from '../../axios-intance';
import {Notification} from 'element-ui';

/**
 * Obtener lista de precios.
 */
export async function fetchPriceLists({commit, state}) {
    await $http.get('/api/parametros-por-codigo/LDP', {params: state.params})
        .then((response) => {

            response.data.data.map(r => {
                r.view_detail = false;
                r.price_list_config = []
            });

            commit('setPriceLists', response.data);
        })
        .catch((error) => {
            console.error(error);
            Notification.error({
                title: 'Error',
                message: 'Error al obtener las listas de precios',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

/**
 * Obtener lista de precios.
 */
export async function fetchPriceListConfig({commit, state}, price_list) {

    let params = {
        price_list_id: price_list.id
    }

    await $http.get('/api/configuracion-lista-de-precios', {params})
        .then((response) => {

            let price_lists = JSON.parse(JSON.stringify(state.price_lists));

            price_lists.data.map(t => {
                if (t.id === price_list.id){
                    t.price_list_config = response.data.data
                }
            });

            commit('setPriceLists', price_lists);
        })
        .catch((error) => {
            console.error(error);
            Notification.error({
                title: 'Error',
                message: 'Error al obtener las listas de precios',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

/**
 * Crear lista de precios.
 */
export async function updatePriceLists({commit, state, dispatch}) {

    const data = {
        parameters: state.price_lists.data
    }

    await $http.post('/api/actualizar-parametros', data)
        .then(async (response) => {
            await dispatch('fetchPriceLists');
            Notification.success({
                title: 'Éxito!',
                message: 'Orden actualizado exitosamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            Notification.error({
                title: 'Error',
                message: 'Error al ordenar las listas de precios',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

/**
 * Crear lista de precios.
 */
export async function updatePriceList({commit, state, dispatch}, price_list) {
    await $http.put('/api/parametros/' + price_list.id, price_list)
        .then(async (response) => {
            Notification.success({
                title: 'Éxito!',
                message: 'Lista de precios actualizada exitosamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            console.log(error);
            Notification.error({
                title: 'Error',
                message: 'Error al guardar la lista de precios',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

/**
 * Crear lista de precios.
 */
export async function updateConfig({commit, state, dispatch}) {

    state.config._method = 'PUT'

    await $http.post('/api/configuracion-lista-de-precios/' + state.config.id, state.config)
        .then(async (response) => {
            commit('setModalShow', false)
            commit('updateConfigInList', response.data.data);
            Notification.success({
                title: 'Éxito!',
                message: 'Configuración actualizada exitosamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            console.log(error);
            Notification.error({
                title: 'Error',
                message: 'Error al guardar la configuración',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}
