import $http from '../../../axios-intance';
import {Notification} from 'element-ui';

// Obtener banners
export async function fetchProducts({commit, state, dispatch, rootState}, clear_page = false) {

    if (clear_page) {
        commit('setPage', 1);
        commit('resetProducts');
    }

    if (!state.loading || state.params.page === 1) {

        commit('setLoading', true);

        commit('setScroll', 0);

        let requestParameters = {params: state.params};

        await $http.get('/api/productos-reposicion', requestParameters)
            .then((response) => {
                let products = response.data.data;

                commit('setProducts', products);

                commit('setLoading', false);

                if (!state.scroll_disabled && state.products_assistant.length <= 3){
                    commit('setPage', (state.params.page + 1));
                    dispatch('fetchProducts')
                }
            }).catch((error) => {
                Notification.error({
                    title: '¡Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box'
                });
                commit('setLoading', false);
            });
    }
}

// Obtener banners
export async function fetchPricesProducts({commit, state, rootState}) {
    let products = state.products.data;
    await $http.post('/api/calcular-precios/' + rootState.login.user_b2b.id, products)
        .then((response) => {
            commit('setProductsData', response.data.data);
        }).catch((error) => {
        });

}

//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter) {
    commit('setFilter', filter.target.value);
    dispatch('fetchProducts');
}

export function setFilterReference({commit, dispatch}, reference) {
    commit('setReference', reference.target.value);
    dispatch('fetchProducts');
}

export function setPerPagePagination({commit, dispatch}, perPage) {
    commit('setPerPage', parseInt(perPage.target.value));
    dispatch('fetchProducts');
}

export function setPagePagination({commit, dispatch}, page) {
    commit('setPage', parseInt(page));
    dispatch('fetchProducts');
}

export function setSortByPagination({commit, dispatch}, sortBy) {
    commit('setSortBy', sortBy);
    // dispatch('fetchProducts')
}

export function setSortPagination({commit, dispatch}, sort) {
    commit('setSort', sort);
    // dispatch('fetchProducts')
}

export async function fetchLines({dispatch, state, rootState, commit}) {
    if (!state.lines.length) {
        await dispatch('lines/fetchLines', {params: {paginate: false}}, {root: true});
        commit('setLines', rootState.lines.lines);
    }
}

export async function fetchBrands({dispatch, state, rootState, commit}) {
    let user_b2b = rootState.login.user_b2b;

    await dispatch('brands/fetchBrands', {
        params: {
            paginate: false,
            lines: state.params.lines,
            warehouse_id: user_b2b.id
        }
    }, {root: true});

    commit('setBrands', rootState.brands.brands);
}

export async function fetchSublines({
                                        dispatch,
                                        state,
                                        rootState,
                                        commit
                                    }, requestParameters = {params: state.category_params}) {
    await dispatch('sublines/listSublines', requestParameters, {root: true});
    commit('setGroups', rootState.sublines.sublines);
}

export async function fetchCategories({
                                          dispatch,
                                          state,
                                          rootState,
                                          commit
                                      }, requestParameters = {params: state.category_params}) {
    await dispatch('categories/fetchCategories', requestParameters, {root: true});
    commit('setCategories', rootState.categories.categories);

}
