import $http from '../../axios-intance';
import {MessageBox, Notification} from 'element-ui';

export async function authentication({commit, state}) {

    commit('setLowerCaseAuthEmail');

    return await new Promise(async (resolve, reject) => {
        await $http.post('/api/login', state.auth)
            .then((response) => {
                if (response.data.data.user) {
                    commit('setLoguedType', response.data.data.user.type);
                    commit('setPermissions', response.data.data.permissions);
                    commit('login', response.data.data);
                    localStorage.setItem('user', JSON.stringify(response.data.data.user));
                    localStorage.setItem('subscriber', JSON.stringify(response.data.data.subscriber));
                    resolve(response);
                }
            })
            .catch((error) => {
                console.log(error.response.data.message);
                Notification.error({
                    title: 'Error!',
                    message: error.response.data.message,
                    type: 'error',
                    duration: 5000,
                    customClass: 'notification-box',
                });
                reject(error);
            });
    });
}

export async function forgotPassword({state}) {
    return new Promise(async (resolve, reject) => {
        await $http.post('/api/contrasena/olvido', state.forgot)
            .then((response) => {
                Notification.success({
                    title: 'Bien hecho',
                    message: '¡Hemos enviado por correo electrónico el enlace para restablecer su contraseña!',
                    type: 'success',
                    duration: 5000,
                    customClass: 'notification-box',
                });
                resolve(response);
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: 'No podemos encontrar un usuario con esa dirección de correo electrónico.',
                    type: 'error',
                    duration: 5000,
                    customClass: 'notification-box',
                });
                reject(error);
            });
    });
}

export async function getTokenResetPassword({commit}, token) {
    return new Promise(async (resolve, reject) => {
        await $http.get('/api/contrasena/buscar/' + token)
            .then((response) => {
                commit('setForgotPassword', response.data);
                resolve(response);
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: 'Este token de restablecimiento de contraseña no es válido.',
                    type: 'error',
                    duration: 5000,
                    customClass: 'notification-box',
                });
                reject(error);
            });
    });
}

export async function resetPassword({commit, state}) {
    return await new Promise(async (resolve, reject) => {
        await $http.post('/api/contrasena/restablecer', state.forgot)
            .then((response) => {
                commit('setForgotPassword', {});
                Notification.success({
                    title: 'Bien hecho',
                    message: '¡Contraseña restablecida exitosamente!',
                    type: 'success',
                    duration: 5000,
                    customClass: 'notification-box',
                });
                resolve(response);
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: 'Ocurrio un error durante el restablecimiento de la contraseña. Por favor intente nuevamente.',
                    type: 'error',
                    duration: 5000,
                    customClass: 'notification-box',
                });
                reject(error);
            });
    });
}

export async function changePassword({commit, state}) {
    return await new Promise(async (resolve, reject) => {
        await $http.post('/api/cambiar/contrasena', state.change)
            .then((response) => {

                let user = JSON.parse(localStorage.getItem('user'));
                user.password_changed = true;
                localStorage.setItem('user', JSON.stringify(user));

                Notification.success({
                    title: 'Bien hecho',
                    message: '¡Contraseña actualizada exitosamente!',
                    type: 'success',
                    duration: 5000,
                    customClass: 'notification-box',
                });
                resolve(response);
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: 'Ocurrio un error durante la actualización de la contraseña. Por favor intente nuevamente.',
                    type: 'error',
                    duration: 5000,
                    customClass: 'notification-box',
                });
                reject(error);
            });
    });
}

export async function fetchUserB2b({dispatch, state}) {
    let user = JSON.parse(localStorage.getItem('user'));
    let params = {
        params: {
            ide: user.contact_warehouse_id
        }
    };

    return await new Promise(async (resolve, reject) => {

        await $http.get('/api/establecimientos-vendedor', params).then(async (response) => {
            await dispatch('loginB2b', response.data.data);
            resolve(response);
        }).catch((error) => {
            Notification.error({
                title: '¡Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box'
            });
            reject(error);
        });
    });
}

export async function loginB2b({commit, state}, customer) {
    localStorage.setItem('user_b2b', JSON.stringify(customer));
    commit('setUserLoguedB2b', customer);
}

export async function logout({commit}) {
    let user = JSON.parse(localStorage.getItem('user'));
    await $http.put('/api/logout', user).then(response => {
        commit('logout');
        localStorage.removeItem('user');
        localStorage.removeItem('user_b2b');
        localStorage.removeItem('permissions');
    }).catch(error => console.log(error));
}

export async function getPermissionsByUser({commit, state}) {

    let permissions_as_string = localStorage.getItem('permissions');

    if (permissions_as_string) {
        let permissions = JSON.parse(permissions_as_string);

        commit('setPermissions', permissions);
    }

    if (state.user_logued) {
        await $http.get('/api/permisos-usuario', state.auth)
            .then((response) => {
                commit('setPermissions', response.data.data);
            });
    }
}

export async function getFirstSubscriber({commit}) {

    return new Promise(async (resolve, reject) => {
        await $http.get('/api/suscriptor')
            .then(response => {
                localStorage.setItem('subscriber', JSON.stringify(response.data.data));
                commit('setSubscriber', response.data.data);
                resolve();
            }).catch((error) => {
                console.log(error);
                reject('Error al obtener el suscriptor');
            });
    });
}

export async function saveSubscriber({commit, state}) {
    localStorage.setItem('subscriber', JSON.stringify(state.subscriber));

    await $http.post('/api/actualizar-suscriptor/' + state.subscriber.id, state.subscriber)
        .then(response => {
            Notification.success({
                title: 'Muy bien!',
                message: 'Parámetros guardados exitosamente',
                type: 'success',
                duration: 1500,
                customClass: 'notification-box'
            });
        }).catch((error) => {
            console.log(error);
        });
}

export async function changeMaintenanceMode({commit, state}) {

    let message = 'Está seguro de activar el modo mantenimiento? Esto denegará el acceso a los usuarios no Administradores del sistema.';
    let message_success = 'El modo mantenimiento se ha activado exitosamente';

    if (!state.subscriber.maintenance_mode) {
        message = 'Está seguro de inactivar el modo mantenimiento? Esto permitirá el acceso a todos los usuarios del sistema.';
        message_success = 'El modo mantenimiento se ha inactivado exitosamente';
    }

    MessageBox.confirm(message, 'Modo mantenimiento', {
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        customClass: 'notification-warning'
    }).then(async (result) => {
        await $http.post('/api/suscriptor/modo-mantenimiento')
            .then(response => {

                commit('setSubscriber', response.data.data);

                Notification.success({
                    title: 'Bien hecho',
                    message: message_success,
                    type: 'success',
                    duration: 5000,
                    customClass: 'notification-box',
                });
            }).catch((error) => {
                console.log(error);
            });
    }).catch(() => {
        let subscriber = state.subscriber;
        subscriber.maintenance_mode = !subscriber.maintenance_mode;
        commit('setSubscriber', subscriber);
    });
}

