import $http from '../../axios-intance'

export async function getCoordinates({commit,state}){
	await $http.get('/api/coordinate-client').then((response)=>{
		commit("setCoordinates",response.data);
	}).catch((error)=>{
		console.log(error);
	})
}

export async function getSellers({commit,state}){
	await $http.get('/api/sellers').then((response)=>{
		commit("setSellers",response.data)
	}).catch((error)=>{
		console.log(error)
	})
}
export async function getVisit({commit,state},requestParameters={params:state.params}){
    if(state.params.id!="" && state.params.date!=""){
	await $http.get(`/api/coordinate-seller`,requestParameters).then((response)=>{
		commit("setVisit",response.data);
		// console.log(response.data);
	}).catch((error)=>{
		console.log(error);
    })
    }
}
export async function getMarkers({commit,state},requestParameters={params:state.params}){
    if(state.params.id!="" && state.params.date!=""){
	await $http.get(`/api/coordinate-marker`,requestParameters).then((response)=>{
		commit("setMarkers",response.data);
	}).catch((error)=>{
		console.log(error);
    })
    }
}
export async function getLines({commit,state},requestParameters={params:state.params}){
    if(state.params.id!="" && state.params.date!=""){
	await $http.get(`/api/line-coordinate`,requestParameters).then((response)=>{
		commit("setLines",response.data);
	}).catch((error)=>{
		console.log(error);
    })
    }
}
