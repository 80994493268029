import {defaultSelectedLeadership} from './state';

export function setLeaderships(state, leaderships) {
    state.leaderships = leaderships;
}

export function setLeadership(state, leadership) {

    let sellers = [];

    if (leadership.leadership_sellers) {
        leadership.leadership_sellers.map(ls => {
            sellers.push(ls.seller_id);
        });
    }

    leadership.seller_list = sellers;

    state.selectedLeadership = leadership;

}

export function setSellersSelect(state, payload) {
    state.sellers_select = payload;
}

export function setAction(state, action) {
    state.action = action;
}

export function setError(state, error) {
    state.error = error;
}

export function LeadershipError(state, payload) {
    state.errorMessage = payload;
    state.leaderships = [];
}

export function departmentError(state, payload) {
    state.error = true;
    state.error = state.errorMessage = payload;
}

export function setGetLeadership(state, getLeadershipEdit) {
    state.getLeadershipEdit = getLeadershipEdit;
}

export function resetSelectedLeadership(state) {
    Object.assign(state.selectedLeadership, defaultSelectedLeadership());
}

export function setSubscribers(state, subscribers) {
    state.subscribers = subscribers;
}

export function setSubscribersFiltered(state, subscribers_filtered) {
    state.subscribers_filtered = subscribers_filtered;
}

export function setBranchOffices(state, branchoffices) {
    state.branchoffices = branchoffices;
}

export function setBranchOfficeWarehouses(state, branchoffice_warehouses) {
    state.branchoffice_warehouses = branchoffice_warehouses;
}

export function setModules(state, modules) {
    state.modules = modules;
}

export function setRoles(state, roles) {
    state.roles = roles;
}

export function setModulesFiltered(state, modules_filtered) {
    state.modules_filtered = modules_filtered;
}

export function photoChange(state, photo) {
    state.selectedLeadership.photo = photo;
}

export function setContacts(state, contacts) {
    state.contacts = contacts;
}

export function setContact(state, contact) {
    /*if(state.clearDataLeadership){
        state.selectedLeadership.name = ''
        state.selectedLeadership.surname = ''
        state.selectedLeadership.email = ''
        state.selectedLeadership.photo = ''
    }*/
    state.contact = contact;
}

export function setGroupedContacts(state, grouped_contacts) {
    state.grouped_contacts = grouped_contacts;
}

export function setClerks(state, clerks) {
    state.clerks = clerks;
}

export function setFindForId(state, findForId) {
    state.findForId = findForId;
}

export function setClearDataLeadership(state, clearDataLeadership) {
    state.clearDataLeadership = clearDataLeadership;
}

export function setDataLeadership(state, data) {
    state.selectedLeadership.name = data.name;
    state.selectedLeadership.surname = data.surname;
    state.selectedLeadership.email = data.email;
    state.selectedLeadership.clerk_id = data.clerk_id;
    state.selectedLeadership.photo = data.photo;
    state.selectedLeadership.contact_id = data.contact_id;
    state.selectedLeadership.type = data.type;
}

export function setContactId(state, id) {
    state.selectedLeadership.contact_id = id;
}

export function setClerkId(state, id) {
    state.selectedLeadership.clerk_id = id;
}

export function setDisabledClerk(state, disabledClerk) {
    state.disabledClerk = disabledClerk;
}

export function setContactsWarehouses(state, contacts_warehouses) {
    state.contacts_warehouses = contacts_warehouses;
}

export function clearContactId(state) {
    if (state.clearDataLeadership) {
        state.selectedLeadership.contact_id = '';
        state.selectedLeadership.name = '';
        state.selectedLeadership.surname = '';
        state.selectedLeadership.email = '';
        state.selectedLeadership.photo = '';
    }
}

export function setMailList(state, mail_list) {
    state.selectedLeadership.mail_list = mail_list;
}

export function setUsers(state, users) {
    state.users = users;
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter) {
    state.params.page = 1;
    state.params.filter = filter;
}

export function setPerPage(state, perPage) {
    state.params.page = 1;
    state.params.perPage = perPage;
}

export function setPage(state, page) {
    state.params.page = page;
}

export function setSortBy(state, sortBy) {
    state.params.sortBy = sortBy;
}

export function setSort(state, sort) {
    state.params.sort = sort;
}

