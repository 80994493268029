import {event, timePickerOptionsEnd, timePickerOptionsStart} from './state';
import moment from 'moment';

export function setSellers(state, sellers) {
    state.sellers = sellers;
}

export function setLeaderships(state, leaderships) {
    state.leaderships = leaderships;
}

export function setColorToContactWarehouses(state, deepening_configurations) {
    let contacts_warehouses = JSON.parse(JSON.stringify(state.contacts_warehouses));

    contacts_warehouses.map(cw => {
        cw.color_criteria_ids = [];

        if (!!cw.deepening) {
            deepening_configurations.map(dc => {
                if (dc.criteria_value.name_parameter === cw.deepening) {

                    if (dc.event_type.code_parameter === 'EVET1' && dc.quantity < cw.days_from_last_visit) {  // Visita fisica
                        cw.color_criteria_ids.push('EVET1');
                    }

                    if (dc.event_type.code_parameter === 'EVET2' && dc.quantity < cw.days_from_virtual_date) {  // Cita virtual
                        cw.color_criteria_ids.push('EVET2');
                    }

                    if (dc.event_type.code_parameter === 'EVET3' && dc.quantity < cw.days_from_phone_call) {  // Llamada telefonica
                        cw.color_criteria_ids.push('EVET3');
                    }
                }
            });
        }
    });

    state.contacts_warehouses = contacts_warehouses;
}

export function setContactsWarehouses(state, contacts_warehouses) {
    const pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');

    if (state.coordinates.value) {
        let coordinates = state.coordinates.value.split(',');

        if (!!coordinates[0] && !!coordinates[1] && pattern.test(coordinates[0]) && pattern.test(coordinates[1])) {

            let lat1 = coordinates[0];
            let lng1 = coordinates[1];

            contacts_warehouses.map(cw => {

                cw.distance = 'N/R';
                cw.distance_number = 100000;

                let lat2 = cw.latitude;
                let lng2 = cw.length;

                if (pattern.test(lat2) && pattern.test(lng2)) {
                    const R = 6378137;
                    const dLat = (lat2 - lat1) * Math.PI / 180;
                    const dLong = (lng2 - lng1) * Math.PI / 180;
                    const dLat1 = (lat1) * Math.PI / 180;

                    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(dLat1) * Math.cos(dLat1) * Math.sin(dLong / 2) * Math.sin(dLong / 2);

                    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

                    let distance = (Math.round(R * c * 0.001 * 10) / 10);

                    cw.distance = distance + ' Kms';
                    cw.distance_number = distance;
                }
            });
        }
    }

    state.contacts_warehouses = contacts_warehouses.sort((a, b) => {
        return a.distance_number - b.distance_number;
    });

    state.contacts_warehouses_filtered = state.contacts_warehouses;
}

export function setContacts(state, contacts) {
    state.contacts = contacts;
}

export function setHolidays(state, holidays) {
    state.holidays = holidays;
}

export function setDeepenings(state, deepening_configurations) {
    let deepenings = [];

    deepening_configurations.map(dc => {
        let d = deepenings.find(d => d.id === dc.criteria_value_id);

        if (!d) {
            deepenings.push(dc.criteria_value);
        }
    });

    state.deepenings = deepenings;
}

export function updateDistance(state, event) {
    const pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');

    let lat1;
    let lng1;

    if (event && event.contact_warehouse) {
        const contact_warehouse = event.contact_warehouse;

        state.title_distance = 'Dist ' + contact_warehouse.description.substring(0, 6) + '...';

        lat1 = contact_warehouse.latitude;
        lng1 = contact_warehouse.length;
    } else {
        state.title_distance = 'Dist';

        navigator.geolocation.getCurrentPosition(
            position => {
                lat1 = position.coords.latitude;
                lng1 = position.coords.longitude;
            },
            error => {
                console.log(error.message);
            },
        );
    }

    if (!!lat1 && !!lng1 && pattern.test(lat1) && pattern.test(lng1)) {
        state.contacts_warehouses.map(cw => {

            cw.distance = 'N/R';
            cw.distance_number = 100000;

            let lat2 = cw.latitude;
            let lng2 = cw.length;

            if (pattern.test(lat2) && pattern.test(lng2)) {
                const R = 6378137;
                const dLat = (lat2 - lat1) * Math.PI / 180;
                const dLong = (lng2 - lng1) * Math.PI / 180;
                const dLat1 = (lat1) * Math.PI / 180;

                const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(dLat1) * Math.cos(dLat1) * Math.sin(dLong / 2) * Math.sin(dLong / 2);

                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

                let distance = (Math.round(R * c * 0.001 * 10) / 10);

                cw.distance = distance + ' Kms';
                cw.distance_number = distance;
            }
        });
    }

    state.contacts_warehouses = state.contacts_warehouses.sort((a, b) => {
        return a.distance_number - b.distance_number;
    });

    state.contacts_warehouses_filtered = state.contacts_warehouses;
}

export function setModalShow(state, show) {
    state.modal.show = show;
}

export function setModalAdvancedShow(state, show) {
    state.modal_advanced.show = show;
}

export function setEventTypeSelected(state, event_type_selected) {
    state.event_type_selected = event_type_selected;
}

export function setTimePickerOptions(state) {
    state.time_picker_options_start = timePickerOptionsStart();
    state.time_picker_options_end = timePickerOptionsEnd();

    if (state.action !== 'Ver') {
        const current_date = moment().format('YYYY-MM-DD');

        if ((state.action === 'Crear' || state.action === 'Editar') && state.event.start_date === current_date) {
            const current_hour = moment().format('HH');
            const current_minute = moment().format('mm');

            let minute = '00';
            let hour = current_hour;

            if (+current_minute < 30) {
                minute = '30';
            } else {
                if (+current_hour < 9) {
                    hour = '0' + (+current_hour + 1);
                } else {
                    hour = +current_hour + 1;
                }
            }

            let start_time = hour + ':' + minute;

            state.time_picker_options_start.start = start_time;
            state.time_picker_options_end.start = start_time;
        }
    }
}

export function setTimePickerOptionsEnd(state, time_picker_options_end) {
    state.time_picker_options_end = time_picker_options_end;
}

export function resetEvent(state, event_data) {
    state.event = event();

    state.event.start_date = moment(event_data.startTime).format('YYYY-MM-DD');
    state.event.end_date = moment(event_data.endTime).format('YYYY-MM-DD');

    state.event.start_time = moment(event_data.startTime).format('HH:mm');
    state.event.end_time = moment(event_data.endTime).format('HH:mm');
}

export function setEvent(state, event) {
    if (state.parameters.executed) {
        if (!!event.originalStartTime && !!event.originalEndTime) {
            event.start_date = moment(event.originalStartTime).format('YYYY-MM-DD');
            event.end_date = moment(event.originalEndTime).format('YYYY-MM-DD');

            event.start_time = moment(event.originalStartTime).format('HH:mm');
            event.end_time = moment(event.originalEndTime).format('HH:mm');
        } else {
            event.startTime = moment(event.start_date + ' ' + event.start_time).toDate();
            event.endTime = moment(event.end_date + ' ' + event.end_time).toDate();
        }
    } else {
        if (!!event.startTime && !!event.endTime) {
            event.start_date = moment(event.startTime).format('YYYY-MM-DD');
            event.end_date = moment(event.endTime).format('YYYY-MM-DD');

            event.start_time = moment(event.startTime).format('HH:mm');
            event.end_time = moment(event.endTime).format('HH:mm');
        } else {
            event.startTime = moment(event.start_date + ' ' + event.start_time).toDate();
            event.endTime = moment(event.end_date + ' ' + event.end_time).toDate();
        }
    }

    event.attachments_loaded = [];
    event.attachments_deleted = [];

    state.event = event;
}

export function setEventDescription(state, description) {
    state.event.description = description;
}

export function setEventAddress(state, address) {
    state.event.address = address;
}

export function setEventTelephone(state, telephone) {
    state.event.telephone = telephone;
}

export function setEventEndTime(state, end_time) {
    state.event.end_time = end_time;
}


export function setEventEndDate(state, end_date) {
    state.event.end_date = end_date;
}

export function setEventLeaderShipId(state, leadership_id) {
    state.event.leadership_id = leadership_id;
}

export function setEventSellerId(state, seller_id) {
    state.event.seller_id = seller_id;
}

export function setEventContactWarehouseId(state, contact_warehouse_id) {
    state.event.contact_warehouse_id = contact_warehouse_id;
}

export function setEventEditMode(state, edit_mode) {
    state.event.edit_mode = edit_mode;
}

export function setAction(state, action) {
    state.action = action;
}

export function setDisabledObservation(state, disabled_observation) {
    state.disabled_observation = disabled_observation;
}

export function setEvents(state, events) {
    events.map(e => {
        e.attachments_loaded = e.attachments;

        if (!!e.visit_customer) {
            let checkin_date = moment(e.visit_customer.checkin_date);
            let checkout_date = moment(e.visit_customer.checkout_date);

            e.visit_customer.checkin_date = checkin_date.format('YYYY-MM-DD');
            e.visit_customer.checkout_date = checkout_date.format('YYYY-MM-DD');

            e.visit_customer.checkin_time = checkin_date.format('HH:mm');
            e.visit_customer.checkout_time = checkout_date.format('HH:mm');
        }

    });

    state.events = events;
}

export function setEventsDay(state, events_day) {
    state.events_day = events_day;
}

export function setLoadingEditor(state, loading_editor) {
    state.loading_editor = loading_editor;
}

export function setParametersStartDate(state, start_date) {
    state.parameters.start_date = start_date;
}

export function setParametersEndDate(state, end_date) {
    state.parameters.end_date = end_date;
}

export function setParametersSellerId(state, seller_id) {
    state.parameters.seller_id = seller_id;
}

export function setParametersContactId(state, contact_id) {
    state.parameters.contact_id = contact_id;
}

export function setEditMode(state, edit_mode) {
    state.edit_mode = edit_mode;
}

export function addAttachmentDeleteEvent(state, attachment) {
    state.event.attachments_deleted.push(attachment);
}

export function deleteAttachmentEvent(state, attachment) {
    let attachments = JSON.parse(JSON.stringify(state.event.attachments));

    let index_attachment = attachments.findIndex(a => a.id === attachment.id);

    if (index_attachment >= 0) {
        attachments.splice(index_attachment, 1);
    }

    state.event.attachments = attachments;
}

export function setAllowClose(state, allow_close) {
    state.allow_close = allow_close;
}

export function setCoordinates(state, coordinates) {
    state.coordinates.value = coordinates;
}

export function setCoordinatesUser(state, coordinates) {
    state.coordinates.user = coordinates;
}

export function setEventTypes(state, event_types) {
    state.event_types = event_types;
}

export function setShowAgenda(state, show_agenda) {
    state.show_agenda = show_agenda;
}

export function setCurrentView(state, current_view) {
    state.current_view = current_view;
    state.current_view = current_view;
}

export function setSelectedDate(state, selected_date) {
    state.selected_date = selected_date;
}

export function setLeadershipIdParams(state, user_id) {

    let leadership = state.leaderships.find(l => l.user_id === user_id);

    if (leadership) {
        state.parameters.leadership_id = [leadership.id];
    }
}

/**
 * Establecer un valor para la variable types.
 */
export function setTypes(state, types) {
    state.types = types;
}

/**
 * Establecer un valor para la variable mode_weekend.
 */
export function setModeWeekend(state, mode_weekend) {
    state.mode_weekend = mode_weekend;
}

/**
 * Establecer un valor para la variable loader.
 */
export function setLoader(state, loader) {
    state.loader = loader;
}

/**
 * Establecer un valor para el atributo show de la variable modal_settings.
 */
export function setShowToModalSettings(state, show) {
    state.modal_settings.show = show;
}


/**
 * Establecer el valor por defecto de la variable timePickerOptionsEnd.
 */
export function resetTimePickerOptions(state) {
    state.time_picker_options_start = timePickerOptionsStart();
    state.timePickerOptionsEnd = timePickerOptionsEnd();
}

/**
 * Establecer un valor para el atributo commercial_direction_id de la variable event.
 */
export function setCommercialDirectionIdToEvent(state, commercial_direction_id) {
    state.event.commercial_direction_id = commercial_direction_id;
}

/**
 * Establecer un valor para el atributo commercial_direction_id de la variable params.
 */
export function setCommercialDirectionIdToParams(state, commercial_direction_id) {
    state.parameters.commercial_direction_id = commercial_direction_id;
}