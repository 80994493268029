import $http from "../../axios-intance";
import {Notification, MessageBox} from 'element-ui';

//Obtener los documentos
export async function fetchDocuments({commit, state, rootState}) {

    // if (!state.documents.length) {
    await $http.get(`/api/documentos?contact_id=${rootState.contacts.selectedContact.id}`)
        .then((response) => {
            commit('setDocuments', response.data.data)
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('documentError', error.message)
        })
    // }
}

//Crear documento
export async function addDocument({commit, state, dispatch, rootState}) {

    state.selectedDocument.contact_id = rootState.contacts.selectedContact.id;

    await $http.post('/api/documentos', state.selectedDocument)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'El documento ha sido cargado',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
            commit('resetSelectedDocument')
            dispatch('fetchDocuments')
            state.error = false
        })
        .catch((error) => {

            let message;

            if (error.response.status == 422) {
                message = "Algunos campos no se registraron correctamente"
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('documentError', error.message)
        })
}

//Consultar documento por id
export async function getDocument({commit, state}, id) {

    if (state.selectedDocument.id != id) {

        await $http.get(`/api/documentos/${id}`)
            .then((response) => {
                commit('setDocument', response.data.data)
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                commit('documentError', error.message)
            })
    }

}

//Descargar documento
export async function downloadDocument({}, documentObject) {
    await $http({
        url: documentObject.path,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentObject.name + '.' + documentObject.extension);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 3000,
            customClass: 'notification-box',
        });

    })
}

//Actualizar documento
export async function updateDocument({commit, state, dispatch}) {

    await $http.put(`/api/documentos/${state.selectedDocument.id}`, state.selectedDocument)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'El documento ha sido actualizado',
                duration: 3000,
                customClass: 'notification-box',
            });
            commit('setActionDocuments', 'create')
            commit('resetSelectedDocument')
            dispatch('fetchDocuments')
            state.error = false
        })
        .catch((error) => {
            let message;

            if (error.response.status == 422) {
                message = "Algunos campos no se registraron correctamente"
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('documentError', error.message)
        })
}


//Eliminar documento
export async function removeDocument({commit, dispatch}, document) {

    await $http.delete(`/api/documentos/${document.id}`)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'El documento ha sido eliminado',
                duration: 3000,
                customClass: 'notification-box',
            });
            dispatch('fetchDocuments')
        })
        .catch((error) => {
            MessageBox.alert(error.message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('documentError', e.message)
        })
}


// Obtiene los tipos de documento
export async function fetchDocumentsTypes({dispatch, state, rootState, commit}) {
    if (!state.documentsTypes.length) {
        await dispatch('parameters/listParameter', {params: {idParamTable: 2, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
        commit('setDocumentsTypes', mapParameters(rootState.parameters.parameters))
    }
}

export function documentChangePath({commit}, path) {
    commit('setDocumentPath', path)
}

//Función para mapear los parametros y retornarlos en un mismo formato para todos los select
function mapParameters(parameters) {
    return parameters.map(p => {
        return {value: p.id, label: p.name_parameter}
    })
}

