export function setImagesFiles(state, images) {
    state.loadImages.images = images;
    state.loadImages.totalCuantity = images.length;
    state.loadImages.loadedQuantity = 0;
    state.loadImages.processedQuantity = 0;

    //Errors
    state.loadImages.errors.has = false;
    state.loadImages.errors.message = '';
    state.loadImages.errors.data = [];
}

export function resetImagesFiles(state) {
    state.loadImages.images = [];
    state.loadImages.totalCuantity = 0;
    state.loadImages.loadedQuantity = 0;
    state.loadImages.processedQuantity = 0;

    //Errors
    state.loadImages.errors.has = false;
    state.loadImages.errors.message = '';
    state.loadImages.errors.data = [];
}

export function setPreloader(state, preloader) {
    state.preloader = preloader
}
