import {
    defaultContacts,
    defaultSelectedClerk,
    defaultSelectedContact,
    defaultSelectedDocument,
    defaultSelectedWarehouse
} from './state';

export function setContacts(state, contacts) {
    state.contacts = contacts;
}

export function setContact(state, contact) {

    if (!!contact.warehouses && !!contact.warehouses.length) {
        contact.warehouses.map(w => {
            if (!!w.criteria_values && !!w.criteria_values.length) {
                w.customer_type_id = w.criteria_values[0].parameter_id;
            }
        });
    }

    state.selectedContact = contact;

    // Mapea y obtiene la lista de precios del warehouse
    let price_list = state.selectedContact.warehouses.map(p => {
        return {value: p.price_list_id};
    });

    if (price_list.length > 0) {
        // Asigna la lista de precios del warehouse al customer
        state.selectedContact.customer.price_list_id = price_list[0].value;
    }


}

export function setAction(state, action) {
    state.action = action;
}

export function contactError(state, payload) {
    state.error = true;
    state.errorMessage = payload;
    state.todo = [];
}

export function setGetContactEdit(state, getContactEdit) {
    state.getContactEdit = getContactEdit;
}

export function setPositionGeneral(state, dataMarker) {
    state.selectedContact.length = dataMarker.lng;
    state.selectedContact.latitude = dataMarker.lat;
}

//Establece el valor por defecto de selectedContact
export function resetSelectedContact(state) {
    let id = state.selectedContact.id;
    Object.assign(state.selectedContact, defaultSelectedContact());
    state.selectedContact.id = id;
}

//Establece el valor por defecto de contacts
export function resetContacts(state) {
    Object.assign(state.contacts, defaultContacts());

}

export function setIsCustomer(state, is_customer) {
    state.is_customer = is_customer;
    state.params.is_customer = is_customer;
}

export function setCustomerCategories(state, customerCategories) {
    state.customerCategories = customerCategories;
    state.params.filter_customer = customerCategories;
}

export function setIsProvider(state, is_provider) {
    state.is_provider = is_provider;
    state.params.is_provider = is_provider;
}

export function setProviderCategories(state, providerCategories) {
    state.providerCategories = providerCategories;
    state.params.filter_provider = providerCategories;
}

export function setIsEmployee(state, is_employee) {
    state.is_employee = is_employee;
    state.params.is_employee = is_employee;

}

export function setEmployeeCategories(state, employeeCategories) {
    state.employeeCategories = employeeCategories;
    state.params.filter_employee = employeeCategories;
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter) {
    state.params.page = 0;
    state.params.query = filter;
}

export function setPerPage(state, perPage) {
    state.params.page = 1;
    state.params.perPage = perPage;
}

export function setPage(state, page) {
    state.params.page = page;
}

export function setSortBy(state, sortBy) {
    state.params.sortBy = sortBy;
}

export function setSort(state, sort) {
    state.params.sort = sort;
}

export function setTooltipParamTable(state, paramTable) {

    state.parameters.map((p) => {
        if (p.paramtable_id == paramTable.id) {
            p.tooltip = paramTable.code_table + ' - ' + paramTable.name_table;
        }
    });

}

//------------------------ PESTAÑA GENERAL------------------------//

// Establece un valor para identificationTypes
export function setIdentificationTypes(state, identificationTypes) {
    state.identificationTypes = identificationTypes;
}

// Establece un valor para lines
export function setLines(state, lines) {
    state.lines = lines.map(p => {
        return {value: p.id, label: p.line_description};
    });
}

// Establece un valor para departments
export function setDepartments(state, departments) {
    state.departments = departments.map(p => {
        return {value: p.id, label: p.department};
    });
}

// Establece un valor para cities
export function setCities(state, cities) {
    state.cities = cities.map(p => {
        return {value: p.id, label: p.city};
    });
}

// Establece un valor para pointsOfSale
export function setPointsOfSale(state, pointsOfSale) {
    state.pointsOfSale = pointsOfSale;
}

// Establece un valor para personClasses
export function setPersonClasses(state, personClasses) {
    state.personClasses = personClasses;
}

// Establece un valor para taxpayerTypes
export function setTaxpayerTypes(state, taxpayerTypes) {
    state.taxpayerTypes = taxpayerTypes;
}

// Establece un valor para taxRegimes
export function setTaxRegimes(state, taxRegimes) {
    state.taxRegimes = taxRegimes;
}

//------------------------ PESTAÑA CLIENTES------------------------//

// Establece un valor para categoriesCustomers
export function setCategoriesCustomers(state, categoriesCustomers) {
    state.categoriesCustomers = categoriesCustomers;
}

// Establece un valor para sellersCustomers
export function setSellersCustomers(state, sellersCustomers) {
    state.sellersCustomers = sellersCustomers;
}

// Establece un valor para priceListsCustomers
export function setPriceListsCustomers(state, priceListsCustomers) {
    for (let i in priceListsCustomers) {
        state.priceListsCustomers.push({
            value: priceListsCustomers[i].id,
            label: priceListsCustomers[i].name_parameter
        });
    }
}

// Establece un valor para zones
export function setZones(state, zones) {
    state.zones = zones;
}

export function setLogoCustomer(state, logo) {
    state.selectedContact.customer.logo = logo;
}

//------------------------ PESTAÑA PROVEEDORES------------------------//

// Establece un valor para categoriesProviders
export function setCategoriesProviders(state, categoriesProviders) {
    state.categoriesProviders = categoriesProviders;
}

// Establece un valor para sellersProviders
export function setSellersProviders(state, sellersProviders) {
    state.sellersProviders = sellersProviders;
}

// Establece un valor para priceListsProviders
export function setPriceListsProviders(state, priceListsProviders) {
    state.priceListsProviders = priceListsProviders;
}

// Establece un valor para paymentBanks
export function setPaymentBanks(state, paymentBanks) {
    state.paymentBanks = paymentBanks;
}

export function setLogoProvider(state, logo) {
    state.selectedContact.provider.logo = logo;
}

//------------------------ PESTAÑA EMPLEADOS------------------------//

// Establece un valor para categoriesEmployees
export function setCategoriesEmployees(state, categoriesEmployees) {
    state.categoriesEmployees = categoriesEmployees;
}

// Establece un valor para costCenters
export function setCostCenters(state, costCenters) {
    state.costCenters = costCenters;
}

// Establece un valor para positions
export function setPositions(state, positions) {
    state.positions = positions;
}

export function setPhotoEmployee(state, photo) {
    state.selectedContact.employee.photo = photo;
}

//------------

// Establece un valor para icaCodes
export function setIcaCodes(state, icaCodes) {
    state.icaCodes = icaCodes;
}

/**
 * Establecer un valor para la variable customer_types.
 */
export function setCustomerTypes(state, customer_types) {
    state.customer_types = customer_types;
}

/**
 * Establecer un valor para la variable operations_center.
 */
export function setOperationsCenter(state, operations_center) {
    state.operations_center = operations_center;
}

