import $http from '../../axios-intance';
import {Notification, MessageBox} from 'element-ui';

/**
 * Obtener usuarios con rol Administrador.
 */
export async function fetchUsers({commit, state}) {
    if (!state.users.length) {
        await $http.get('/api/usuarios-por-rol/Administrador;Director Comercial;Coordinador Comercial;Servicio Cliente;Asistente Comercial;RRHH')
            .then((response) => {
                commit('setUsers', response.data.data);
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box',
                });
            });
    }
}

/**
 * Obtener Jefaturas.
 */
export async function fetchLeaderships({commit, state}) {
    await $http.get('/api/jefaturas', {params: {paginate:false}})
        .then((response) => {
            commit('setLeaderships', response.data.data);
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
        });
}

/**
 * Obtener direcciones comerciales.
 */
export async function fetchCommercialDirections({commit, state}, requestParameters = {params: state.params}) {
    await $http.get('/api/direcciones-comerciales', requestParameters)
        .then((response) => {
            commit('setCommercialDirections', response.data.data);
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
        });
}

/**
 * Crear dirección comercial.
 */
export async function addCommercialDirection({commit, state}) {
    await $http.post('/api/direcciones-comerciales', state.commercial_direction)
        .then((response) => {
            Notification.success({
                title: 'Muy bien!',
                message: 'Dirección Comercíal creada exitosamente',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
        });
}

/**
 * Actualizar dirección comercial.
 */
export async function updateCommercialDirection({commit, state}) {
    await $http.put('/api/direcciones-comerciales/' + state.commercial_direction.id, state.commercial_direction)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'Dirección Comercíal actualizada exitosamente',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
        });
}

/**
 * Obtener dirección comercial por id.
 */
export async function getCommercialDirection({commit, state}, id) {
        await $http.get(`/api/direcciones-comerciales/${id}`)
            .then((response) => {
                commit('setCommercialDirection', response.data.data);
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box',
                });
            });
}

/**
 * Eliminar dirección comercial.
 */
export async function deleteCommercialDirection({commit, state, dispatch}, commercial_direction_id) {
    await $http.delete('/api/direcciones-comerciales/' +  commercial_direction_id)
        .then((response) => {
            Notification.success({
                title: 'Muy bien!',
                message: 'Dirección Comercíal eliminada exitosamente',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            dispatch('fetchCommercialDirections');
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
        });
}
