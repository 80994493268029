export function getParams (state){
    return state.params
}

export function getCustomerCategories(state){
    return state.customerCategories
}

export function getProviderCategories(state){
    return state.providerCategories
}

export function getEmployeeCategories(state){
    return state.employeeCategories
}
