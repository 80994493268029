export const defaultDates = () => {

    const end = new Date();
    const start = new Date();

    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);

    return [formatDate(start), formatDate(end)]

}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export default {
    cities:[],
	leaderships: [],
	managements: {
		data: [],
		total_avg_month_sales: 0,
		total_month_sales: 0,
		total_month_orders: 0,
		total_accomplishment: 0
	},
	error: false,
	errorMessage: '',
	sellers: [],
	params: {
		paginate: true,
		page: 1,
		perPage: 15,
		sortBy: 'avg_sales',
		sort: 'DESC',
		filter: '',
		seller_id: '',
        city_id: '',
        filter_date: defaultDates(),
		leadership_id: null,
        would_you_run: false,
		only_prospects: false
	},
    preloader: false
}

