export function setProducts(state, products) {

    if (state.params.page === 1) {
        state.products_catalog = [];
    }

    products.data.forEach(p => {
        p.cant = 1;

        if (p.price.percentage > 0) {
            p.total = p.price.price - p.price.price * p.price.percentage / 100;
            p.total_suggested_price = p.suggested_price.price - p.suggested_price.price * p.price.percentage / 100;
        } else {
            p.total = p.price.price;
            p.total_suggested_price = p.suggested_price.price;
        }

        if (!state.params.only_promotions || p.price.percentage > 0) {
            state.products_catalog.push(p);
        }
    });

    state.products = products;

    state.scroll_disabled = !state.products.data.length;
}

export function setSelectedProduct(state, selectedProduct) {
    state.selectedProduct = selectedProduct;
}

export function setRelated(state, related) {
    state.selectedProduct.related = related;
}

export function setShowAvailabilityModal(state, showAvailabilityModal) {
    state.modal.showAvailabilityModal = showAvailabilityModal;
}

export function setPromo(state, promos) {
    state.promos = promos;
}

export function productError(state, payload) {
    state.error = true;
    state.errorMessage = payload;
    state.products = [];
}

export function setContactsWarehouses(state, contactsWarehouses) {
    state.contactsWarehouses = contactsWarehouses;
}

export function setSelectedContactWarehouse(state, selectedContactWarehouse) {
    state.selectedContactWarehouse = selectedContactWarehouse;
}

export function setBrands(state, brands) {
    state.brands = brands.map(p => {
        return {value: p.id, label: p.description, imagen: p.thumbnails, imagen_min: p.thumbnails_min};
    });
}

export function setCategories(state, categories) {
    state.categories = categories.map(p => {
        return {value: p.id, label: p.description};
    });
}

export function setGroups(state, groups) {
    state.groups = groups.map(p => {
        return {value: p.id, label: p.subline_description};
    });
}

export function setProductsData(state, products) {
    state.products.data = products;
}


//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter) {
    state.params.page = 1;
    state.params.filter = filter;
}

export function setReference(state, reference) {
    state.params.page = 1;
    state.params.reference = reference;
}

export function setPerPage(state, perPage) {
    state.params.page = 1;
    state.params.perPage = perPage;
}

export function setPage(state, page) {
    state.params.page = page;
}

export function setSortBy(state, sortBy) {
    state.params.sortBy = sortBy;
}

export function setSort(state, sort) {
    state.params.sort = sort;
}

export function setFilterLine(state, line = []) {
    state.params.filter_lines = line;
}

export function setFilterBrand(state, brand = []) {
    state.params.filter_brands = brand;
    state.category_params.filter_brands = brand;
}

export function setFilterGroup(state, sublines = []) {
    state.params.filter_groups = sublines;
    state.category_params.filter_groups = sublines;
}

export function setFilterCategories(state, categories = []) {
    state.category_params.lines = state.params.lines;
    state.params.filter_categories = categories;
}

export function resetProducts(state) {
    state.products = {
        data: {}
    };

    state.products_catalog = [];
}

export function setLoading(state, loading) {
    state.loading = loading;
}


export function setScrollDisabled(state, scroll_disabled) {
    state.scroll_disabled = scroll_disabled;
}


export function setScroll(state, scroll_state) {
    state.scroll_state = scroll_state;
}

/**
 * Establecer un valor para la variable disabled_buttons.
 */
export function setDisabledButtons(state, disabled_buttons) {
    state.disabled_buttons = disabled_buttons;
}

/**
 * Establecer un valor para la variable suggested_price.
 */
export function setSuggestedPrice(state, suggested_price) {
    state.suggested_price = suggested_price;
}

/**
 * Establecer un valor para la variable applications.
 */
export function setApplications(state, applications) {
    state.applications = applications;
}