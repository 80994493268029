import $http from '../../axios-intance'
import { Notification } from 'element-ui'

// Obtener banners
export async function fetchBanners ({ commit, state }, requestParameters = { params: state.params }) {
	await $http.get('/api/banners', requestParameters).then((response) => {
		commit('setBanners', response.data.data)
	}).catch((error) => {
		Notification.error({
			title: '¡Error!',
			message: error.message,
			type: 'error',
			duration: 1500,
			customClass: 'notification-box'
		})
		commit('bannerError', error.message)
	})
}

// Crear banner
export async function addBanner ({ commit, state }) {

    let formData = new FormData();

    for (let key in state.selectedBanner) {
        if (key !== 'url_big_banner' && key !== 'url_small_banner' ) {
            formData.append(key, state.selectedBanner[key])
        }
    }

	await $http.post('/api/banners', formData).then((response) => {
		Notification.success({
			title: '¡Éxito!',
			message: 'El banner ha sido agregado',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box'
		})
		commit('resetSelectedBanner')
	}).catch((error) => {
		Notification.error({
			title: '¡Error!',
			message: error.message,
			dangerouslyUseHTMLString: true,
			type: 'error',
			duration: 1500,
			customClass: 'notification-box'
		})
		commit('bannerError', error.message)
	})
}

// Obtener banner individual
export async function getBanner ({ commit, state }, id) {
	if (state.getBannerEdit) {
		await $http.get(`/api/banners/${id}`).then((response) => {
			commit('setBanner', response.data.data)
		}).catch((error) => {
			Notification.error({
				title: '¡Error!',
				message: error.message,
				type: 'error',
				duration: 1500,
				customClass: 'notification-box'
			})
			commit('bannerError', error.message)
		})
		commit('setGetBannerEdit', false)
	}
}

// Editar banner
export async function updateBanner ({ commit, state }) {

    let formData = new FormData();

    for (let key in state.selectedBanner) {
        if (key !== 'url_big_banner' && key !== 'url_small_banner' ) {
            formData.append(key, state.selectedBanner[key])
        }
    }

	$http.post(`/api/banners/${state.selectedBanner.id}`, formData).then((response) => {
		Notification.success({
			title: '¡Éxito!',
			message: 'El banner ha sido actualizado',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box'
		})
		commit('setBanner', response.data.data)
	}).catch((error) => {
		Notification.error({
			title: '¡Error!',
			message: error.message,
			type: 'error',
			duration: 1500,
			customClass: 'notification-box'
		})
		commit('bannerError', error.message)
	})
}

// Eliminar banner
export async function removeBanner ({ commit, dispatch }, banner) {
	$http.delete(`/api/banners/${banner.id}`).then((response) => {
		Notification.success({
			title: '¡Éxito!',
			message: 'El banner ha sido eliminado',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box'
		})
		dispatch('fetchBanners')
	}).catch((error) => {
		Notification.error({
			title: '¡Error!',
			message: error.message,
			type: 'error',
			duration: 1500,
			customClass: 'notification-box'
		})
		commit('bannerError', error.message)
	})
}

/* Paginación */
export function setFilterPagination ({ commit, dispatch }, filter) {
	commit('setFilter', filter.target.value)
	dispatch('fetchBanners')
}

export function setPerPagePagination ({ commit, dispatch }, perPage) {
	commit('setPerPage', parseInt(perPage.target.value))
	dispatch('fetchBanners')
}

export function setPagePagination ({ commit, dispatch }, page) {
	commit('setPage', parseInt(page))
	dispatch('fetchBanners')
}

export function setSortByPagination ({ commit, dispatch }, sortBy) {
	commit('setSortBy', sortBy)
}

export function setSortPagination ({ commit, dispatch }, sort) {
	commit('setSort', sort)
}

// Obtener líneas
export async function fetchLines ({ dispatch, state, rootState, commit }) {
	if (!state.lines.length) {
		await dispatch('lines/fetchLines', {params:{paginate:false}}, {root: true})
		commit('setLines', rootState.lines.lines)
	}
}

// Obtener parámetros
function mapParameters(parameters) {
	return parameters.map(p => {
		return {value: p.id, label: p.name_parameter}
	})
}

export async function fetchParameters ({ dispatch, state, rootState, commit }) {
	if (!state.parameters.length) {
		await dispatch('parameters/listParameter', {params:{idParamTable: 14, paginate:false}}, {root: true})
		commit('setParameters', mapParameters(rootState.parameters.parameters))
	}
}

// Carga los banner
export function bigBannerImage({ commit }, big_banner) {
	commit('setBigBannerImage', big_banner)
}

export function smallBannerImage({ commit }, small_banner) {
	commit('setSmallBannerImage', small_banner)
}


// listar banners
export async function listBanner({commit, state}, requestParameters={parameter: state.params}){
	await $http.get(`/api/banner-list`, requestParameters)
		.then((response) => {
			commit('setBanners', response.data)
		})
		.catch((error) => {
			Notification.error({
				title: '¡Error!',
				message:  error.message,
				type: 'error',
				duration: 2000,
				customClass: 'notification-box',
			});
		commit('bannerError', error.message)
	})
}
