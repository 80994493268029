/**
 * Establecer un valor para la variable quotations.
 */
export function setQuotations(state, quotations) {
    state.quotations = quotations;
}

/**
 * Establecer un valor para el atributo contact_id de la variable params.
 */
export function setContactIdToParams(state, contact_id) {
    state.params.contact_id = contact_id;
}

/**
 * Establecer un valor para el atributo warehouse_id de la variable params.
 */
export function setWarehouseIdToParams(state, warehouse_id) {
    state.params.warehouse_id = warehouse_id;
}