const loadImages = {
    loader: '',
    totalCuantity: 0,
    loadedQuantity: 0,
    processedQuantity: 0,
    images: [],
    errors: {
        has: false,
        message: [],
        data: []
    }
}

export default {
    loadImages,
    preloader: false,
}
