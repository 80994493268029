import $http from '../../axios-intance';
import {Notification} from 'element-ui';

export async function fetchSellers({commit, state}, requestParameters = {params: state.params}) {
    commit('setPreloader', true);

    await $http.get('/api/estadisticas-vendedores', requestParameters).then((response) => {
        commit('setSellers', response.data.data);
        commit('setPreloader', false);
    }).catch((error) => {
        commit('setPreloader', false);
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
    });
}

export async function fetchSellersContactWareHouse({commit, state}, seller_id) {
    state.params.seller_filter = seller_id;
    let requestParameters = {params: JSON.parse(JSON.stringify(state.params))};

    if (!state.set_all_view_details) {
        commit('setPreloader', true);
    }

    await $http.get('/api/estadisticas-vendedores-clientes', requestParameters).then((response) => {
        commit('setActivity', response.data.data.activity);
        commit('setSellersWareHouse', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('sellerError', error.message);
    });

    if (!state.set_all_view_details) {
        commit('setPreloader', false);
    }
}

export async function fetchSellersContactWareHouseNotVisits({commit, state}, seller_id) {
    state.params.seller_filter = seller_id;
    let requestParameters = {params: JSON.parse(JSON.stringify(state.params))};

    commit('setPreloader', true);

    await $http.get('/api/estadisticas-vendedores-clientes-no-visitados', requestParameters).then((response) => {

        commit('setSellersWareHouseNotVisits', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('sellerError', error.message);
    });

    commit('setPreloader', false);
}


export async function fetchSellersContactWareHouseDetail({commit, state}, requestParameters = {params: state.params}) {

    commit('setPreloader', true);

    return new Promise(async (resolver, reject) => {
        await $http.get('/api/estadisticas-vendedores-details-visits', requestParameters).then((response) => {

            let data = {
                customer_id: requestParameters.params.filter_contact_warehouse,
                sellers_contacts: response.data.data
            };

            commit('setSellersContact', data);
            commit('setPreloader', false);
            return resolver(data);

        }).catch((error) => {
            commit('setPreloader', false);
            Notification.error({
                title: '¡Error!',
                message: error.response.data.message,
                type: 'error',
                duration: 5000,
                customClass: 'notification-box'
            });
            return reject(error);
        });
    });


}


export async function fetchSellersExcel({state, commit}) {
    commit('setPreloader', true);
    await $http({
        url: '/api/estadisticas-vendedores-excel',
        method: 'GET',
        responseType: 'blob',
        params: state.params
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            console.log(url, link);
            link.href = url;
            link.setAttribute('download', 'Gestion Visitas.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 3000,
                customClass: 'notification-box'
            });
        });
    commit('setPreloader', false);
}


export async function fetchCities({commit}, requestParameters) {
    await $http.get('/api/ciudades', requestParameters)
        .then((response) => {
            commit('setCities', response.data.data);
        })
        .catch((error) => {
            swal({
                title: 'error',
                text: error.message,
                type: 'error'
            });
            commit('CityError', error.message);
        });
}

export async function getSellers({commit, state}, reset_seller = true) {
    let leadership_id = state.params.leadership_id;

    if (reset_seller) {
        commit('setSellerIdParams', null);
    }

    if (leadership_id === null) {
        await $http.get('/api/sellers').then((response) => {
            commit('setSellersSelect', response.data.data);
        });

    } else {
        await $http.get('/api/sellers/' + leadership_id).then((response) => {
            commit('setSellersSelect', response.data.data);
        });
    }
}

export async function fetchVisits({commit, state}) {
    await $http.get('/api/seguimiento-vendedores').then((response) => {
        commit('setSellers', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('visitError', error.message);
    });
}

export async function getLeaderships({commit, state}) {
    await $http.get('/api/leaderships').then((response) => {
        commit('setLeaderships', response.data.data);
    });
}

export async function updateVisit({commit, state}) {


    await $http.put('/api/visits-customers/' + state.visit.id, state.visit)
        .then((response) => {
            Notification.success({
                title: 'Muy bien!',
                message: 'Visita actualizada exitosamente',
                type: 'success',
                duration: 1500,
                customClass: 'notification-box'
            });
        });
}
