<template>
<div>
    <div class="imagen">
        <figure><img src="../../assets/img/Logo-erp.svg" class="load" ></figure>
    </div>

    <vue-content-loading v-bind="$attrs" :width="300" :height="80">
        <rect x="15" y="0" rx="3" ry="3" width="70" height="7" />
        <rect x="95" y="0" rx="3" ry="3" width="100" height="7" />
        <rect x="205" y="0" rx="3" ry="3" width="10" height="7" />
        <rect x="30" y="10" rx="3" ry="3" width="90" height="7" />
        <rect x="130" y="10" rx="3" ry="3" width="60" height="7" />
        <rect x="195" y="10" rx="3" ry="3" width="60" height="7" />
        <rect x="15" y="20" rx="3" ry="3" width="130" height="7" />
        <rect x="155" y="20" rx="3" ry="3" width="130" height="7" />
        <rect x="30" y="30" rx="3" ry="3" width="90" height="7" />
        <rect x="130" y="30" rx="3" ry="3" width="60" height="7" />
        <rect x="195" y="30" rx="3" ry="3" width="60" height="7" />
        <rect x="15" y="40" rx="3" ry="3" width="130" height="7" />
        <rect x="155" y="40" rx="3" ry="3" width="130" height="7" />
        <rect x="15" y="60" rx="3" ry="3" width="30" height="7" />
    </vue-content-loading>

</div>
</template>

<script>
import VueContentLoading from 'vue-content-loading'

export default {
    name: 'loader',
    components:{
        VueContentLoading
    }
}
</script>

<style>
.load{
    height:74px;
    filter: grayscale(100%) opacity(28%);
    margin-left: 36%;
    margin-bottom: 50px;
    position: relative;
}
.imagen figure::before {
	position: absolute;
	top: 84px;
	left: 495px;
	z-index: 2;
	display: block;
	content: '';
	width: 5%;
	height: 61px;
    border-radius: 30px;
	background: -webkit-linear-gradient(left, rgba(255,255,255) 0%, rgba(255,255,255,.6) 100%);
	background: linear-gradient(to right, rgba(255,255,255) 0%, rgba(255,255,255,.6) 100%);
	-webkit-transform: skewX(0deg);
	transform: skewX(0deg);
}
.imagen figure::before {
	-webkit-animation: shine 3s;
	animation: shine 3s;
}
@-webkit-keyframes shine {
	from {
		left: 520px;
	}
    to {
        left: 820px

    }
}
@keyframes shine {
	from {
		left: 520px;
	}
    to{
        left: 820px
    }
}



    @media (max-width: 374px) {
       .load{
            height:20px;
            filter: grayscale(100%) opacity(28%);
            margin-left: 92px !important;
            margin-bottom: 10px
        }
    }
    @media (min-width: 375px) and (max-width:425px ){
       .load{
            height:22px;
            filter: grayscale(100%) opacity(28%);
            margin-left: 119px !important;
            margin-bottom: 10px
        }
    }
    @media (min-width: 1331px) and (max-width:1340px ){
       .load{
            height:65px;
            filter: grayscale(100%) opacity(28%);
            margin-left: 272px !important;
            margin-bottom: 10px
        }
    }
    @media (min-width: 1160px) and (max-width:1330px ){
       .load{
            height:52px;
            filter: grayscale(100%) opacity(28%);
            margin-left: 242px !important;
            margin-bottom: 10px
        }
    }
    @media (min-width: 819px) and (max-width:1159px ){
       .load{
            height:45px;
            filter: grayscale(100%) opacity(28%);
            margin-left: 242px !important;
            margin-bottom: 10px
        }
    }
    @media (min-width: 426px) and (max-width:818px ){
       .load{
            height:35px;
            filter: grayscale(100%) opacity(28%);
            margin-left: 116px !important;
            margin-bottom: 10px
        }
    }

</style>

