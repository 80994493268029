export const commercial_direction = () => {
	return {
		name: [],
        director_id: null,
		commercial_direction_leaderships_ids: null
	}
}

export default {
	commercial_directions: {data:[]},
    users: [],
    commercial_direction: commercial_direction(),
	action: 'create',
	leaderships: [],
	params: {
		paginate: true,
		page: 1,
		per_page: 15,
		filter: ''
	}
}
