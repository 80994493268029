export const price_list = () => {
    return {
        id: null,
        code_parameter: null,
        name_parameter: null,
        numeric_data_first: null,
        numeric_data_second: null
    };
};

export const config = () => {
    return {
        price_list_id: null,
        line_id: null,
        min_value_customer_list: null,
        min_value_for_list_change: null,
        min_value_for_no_freight_charge: null,
        freight_value: null,
        price_list: {},
        line: {}
    };
};

export default {
    price_lists: {data: []},
    price_lists_filtered: [],
    price_list: price_list(),
    config: config(),
    modal: {show: false},
    editing: false,
    params: {
        filter: '',
        order_by: 'numeric_data_first'
    }
};
