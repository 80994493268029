import $http from '../../axios-intance'
import {Notification} from "element-ui";

export async function getRoles({commit, state}) {
    await $http.get('/api/roles')
        .then((response) => {
            commit('setRoles', response.data.data)
        })
}

export async function getPermissions({commit, state}) {
    await $http.get('/api/permisos')
        .then((response) => {
            commit('setPermissions', response.data.data)
        })
}

export async function getPermissionsByRole({commit, state}) {
    if (state.role.selected) {
        await $http.get('/api/permisos-rol/' + state.role.selected)
            .then((response) => {
                commit('setPermissionsAssigned', response.data.data)
            })
    }
}

export async function changePermissionToRole({commit, state}, permission_id) {
    await $http.post('/api/cambiar-permisos-rol/' + state.role.selected + '/' + permission_id)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'Permiso cambiado exitosamente',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            })
        }).catch((error) => {
            Notification.error({
                title: 'Error!',
                message: 'Error al cambiar el permiso',
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
        })
}

export async function getSql({commit, state}, file) {
    return new Promise(async (resolve, reject) => {
        await $http.get('/api/sql-file/' + file)
            .then((response) => {
                resolve(response.data)
            }).catch((error) => {
                reject('Error')
            })
    })
}
