<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
    import Vue from 'vue';

    import ElementUI from 'element-ui';
    import 'element-ui/lib/theme-chalk/index.css';
    import locale from 'element-ui/lib/locale/lang/es'

    Vue.use(ElementUI, {locale})

    import preloader from "./components/layout/preloader";
    import loader from "./components/layout/loader";

    import VeeValidate, {Validator} from 'vee-validate';
    import es from 'vee-validate/dist/locale/es';

    Vue.use(VeeValidate, {
        fieldsBagName: 'vvFields',
        events: 'change'
    });

    Validator.localize('es', es);

    import moment from 'moment'

    Vue.prototype.moment = moment

    import VueCurrencyFilter from 'vue-currency-filter'

    Vue.use(VueCurrencyFilter, {
        symbol: '$', // El símbolo, por ejemplo €
        thousandsSeparator: ',', // Separador de miles
        fractionCount: 0, // ¿Cuántos decimales mostrar?
        fractionSeparator: '.', // Separador de decimales
        symbolPosition: 'front', // Posición del símbolo. Puede ser al inicio ('front') o al final ('') es decir, si queremos que sea al final, en lugar de front ponemos una cadena vacía ''
        symbolSpacing: true // Indica si debe poner un espacio entre el símbolo y la cantidad
    })

    import {mapState, mapMutations, mapActions} from 'vuex';

    export default {
        name: 'app',
        components: {
            preloader,
            loader
        },
        async created() {
            let user = JSON.parse(localStorage.getItem('user'))
            this.setUserLogued(user)

            let user_b2b = JSON.parse(localStorage.getItem('user_b2b'))
            this.setUserLoguedB2b(user_b2b)

            await this.getPermissionsByUser();
            await this.getFirstSubscriber();

            if (user && !this.validatePermission('Panel administrativo')) {
                await this.$router.push("/b2b/inicio")
            }
        },
        methods: {
            ...mapActions('login', ['getPermissionsByUser', 'getFirstSubscriber']),
            ...mapMutations('login', ['setUserLogued', 'setPermissions']),
            ...mapMutations('login', ['setUserLoguedB2b']),
            validatePermission(permission) {
                let permissionExists = this.permissions.find(p => p.name === permission)

                return !!permissionExists
            }
        },
        computed: {
            ...mapState('login', ['user_b2b', 'permissions'])
        }
    }

</script>
<style src="./assets/css/form_layouts.css"></style>
<!--<style lang="sass" src="./assets/sass/buttons/buttons.scss"></style>-->
<style lang="scss" src="./assets/sass/bootstrap/bootstrap.scss"></style>
<style src="font-awesome/css/font-awesome.min.css"></style>
<style src="themify-icons/css/themify-icons.css"></style>
<style src="./assets/css/style.css"></style>
<style>
    html {
        background-color: #fff;
    }
</style>
