export const activity = () => {
    return {
        id: null,
        custom_id: null,
        order: null,
        code: null,
        code_deepening: null,
        text: null,
        hide_seller: false,
        allow_past_dates: false,
        apply_checkin_checkout: false,
        close_automatically: false,
        weekends: false,
        apply_for_bearings: false,
        apply_for_averages: false,
        maximum_distance: 0,
        minimum_duration: 15,
        apply_for_valid_day: false,
        validate_distance_checkin: true,
        validate_distance_checkout: true,
        contact_restriction: [],
        active: true
    };
};

export default {
    activities: [],
    activity: activity(),
    modal: {show: false},
    editing: false,
    params: {
        filter: ''
    },
    contacts: [
        {id: 1, name: 'CLIENTE SOLO CORRERÍAS'},
        {id: 6, name: '999996 - BARRIDO DE ZONA'},
        {id: 7, name: '999997 - ACT.EXTERNA (DISTINTA UBIC. CLIENTE)'},
        {id: 2, name: '999998 - PROPARTES SEDE ADMON 116'},
        {id: 3, name: 'VIVIENDA ASESOR'},
        {id: 4, name: 'CLIENTES'},
        {id: 5, name: 'CLIENTES DESPLAZAMIENTO'}
    ]
};
