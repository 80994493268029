export const defaultSelectedCustomer = () => {
	return {
	}
}

export default {
	customers: [],
	selectedCustomer: defaultSelectedCustomer(),
	error: false,
	errorMessage: '',
	coordinates:[],
	coordinatesMarker:[],
	// coordinatesSeller:[],
	sellers:[],
	coordinatesVisit:[],

	typeIdentifications:[],
    list_prices:[],
	params: {
		id:'',
		employee_type:'',
		paginate: true,
		page: 1,
		filter: '',
		only_prospects: false
	},
    modal_price_list: {show:false},
}
