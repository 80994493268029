export default {
    receivables: [],
    error: false,
    errorMessage: '',

    params: {
        paginate: true,
        page: 1,
        perPage: 15,
        sortBy:'invoice_id',
        sort: 'ASC',
        contact_id: 0
    },
}
