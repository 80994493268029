export const defaultSelectedBrand = () => {
	return {
		brand_code: '',
		description: '',
		image: '',
		image_min: '',
		provider_id: '',
		maker_id: '',
		lock_buy: 0,
		block_sale: 0,
		deploy_sales_force_app:0,
		deploy_b2c:0,
		deploy_b2b:0,
		active: true
	}
}

export default {
	brands: [],
	providers: [],
	selectedBrand: defaultSelectedBrand(),
	error: false,
	errorMessage: '',
	action: 'create',
	actionSubbrand: 'create',
	brandEdit: null,
	getBrandEdit: true,
	params: {
		paginate: true,
		page: 1,
		perPage: 15,
		filter: '',
		sortBy: 'brand_code',
		sort: 'ASC'
	}
}