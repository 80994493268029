import moment from 'moment'

export function setReceivables(state, receivables) {
    let descuento =  receivables.some(p => p.document.defeated == true)

    receivables.forEach(p => {
        const fecha1 = moment(p.document.voucher_date);
        const fecha2 = moment();

        p.day = fecha2.diff(fecha1, 'days') + 1;

        const fec_exp1 = moment(p.document.due_date);
        const fec_exp2 = moment();

        p.expiration = fec_exp2.diff(fec_exp1, 'days');

        if((p.expiration <= 0) || (!p.document.due_date)){
            p.expiration = null
        }

        if (p.document.documents_products[0] && p.document.apply_prompt_payment_discount && !descuento) {
            p.discount = p.document.documents_products[0].total_value_brut * 5 / 100
        } else {
            p.discount = 0
        }

        p.pay=false

        if(p.pay_receivables !== '') {
            let total_original = 0
            p.pay_receivables.forEach(r => {
                let nuevo_saldo = total_original + r.debit_value - r.applied_value
                r.saldo = nuevo_saldo
                total_original = nuevo_saldo
            })
        }
    });

    state.receivables = receivables;
}
export function receivableError(state, payload) {
    state.error = true
    state.errorMessage = payload
    state.receivables = []
}
//------------------------ PAGINACIÓN ------------------------//

export function setPerPage(state, perPage) {
    state.params.page = 1
    state.params.perPage = perPage
}

export function setPage(state, page) {
    state.params.page = page
}

export function setSortBy(state, sortBy) {
    state.params.sortBy = sortBy
}

export function setSort(state, sort) {
    state.params.sort = sort
}
