import $http from '../../../axios-intance'
import { Notification } from 'element-ui'

export async function getrelated({commit, state}, id, requestParameters = {params: state.params}) {
	await $http.get(`/api/relacionados/${id}`, requestParameters)
	.then((response) => {
		commit('setRelatedCart', response.data.data)
	})
	.catch((error) => {
		Notification.error({
			title: 'Error!',
			message: error.message,
			type: 'error',
			duration: 1500,
			customClass: 'notification-box',
		})
		commit('cartError', error.message)
	})
}