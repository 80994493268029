import $http from '../../../axios-intance'
import { Notification } from 'element-ui'

/**
 * Solicitud para obtener cotizaciones.
 */
export async function fetchQuotations({ commit, state }) {
	const params = state.params

	await $http.get('/api/cotizaciones', {params})
		.then((response) => {
			commit('setQuotations', response.data.data)
		}).catch((error) => {
			Notification.error({
				title: '¡Error!',
				message: error.message,
				type: 'error',
				duration: 1500,
				customClass: 'notification-box'
			})
		})
}