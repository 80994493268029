export const defaultSelectedSubscriber = () => {
    return {
        license_id: '',
        identification_type: '',
        class_person: '',
        taxpayer_type: '',
        tax_regimen: '',
        company_type: '',
        identification: '',
        check_digit: '',
        name: '',
        short_name: '',
        email: '',
        web_page: '',
        id_legal_representative: '',
        name_legal_representative: '',
        logo: '',
        state: 1,
        configuration: {
            product_digits: '',
            seller_management:0,
            brand_management: 0,
            line_management: 0,
            category_management:0,
            line_description: '',
            subline_description: '',
            category_description: '',
            subcategory_description: '',
            type_description: '',
            recalculate_sales_price: 0,
            percentage_minimum_variation: '',
            rounding_factor: '',
            type_voucher_inventory: '',
            account_entry:'',
            account_exit: '',
            consecutive_product_code: '',
            consecutive_transfer: '',
            consecutive_sales_orders: '',
            consecutive_purchase_orders: '',
            folios_electronic_invoice: '',
            last_date_close: '',
            firm_date: '',
            base_retention_source_products: '',
            percentage_retention_source_products: '',
            base_retention_source_services: '',
            percentage_retention_source_services: '',
            percentage_reteiva: '',
            code_activity_ica: '',
            code_ciiu: '',
            percentage_reteica: '',
            bill_to_pay: '',
            receivable: '',
            account_heritage_utility: '',
            account_heritage_lost: '',
            account_pyg_result_exercise: '',
            monthly_utility_transfer_or_lost: 0,
            annual_utility_transfer_or_lost: 0,
            type_voucher_result_exercise: '',
            first_logo: '',
        },
        branch_office: {
            name:'',
            address:'',
            telephone:'',
            fax:'',
            main:1,
            city_id:'',
            latitude:'',
            length:'',
            branchoffice_type:1,
            city: {
                department_id: ""
            }
        }
    }
}

export default {
    subscribers: [],
    selectedSubscriber: defaultSelectedSubscriber(),
    error: false,
    errorMessage: '',
    action: 'create',
    check: null,

    departments: [],
    cities: [],
    licenses: [],
    identificationTypes: [],
    companyTypes: [],
    classPersons: [],
    taxpayerTypes: [],
    taxRegimens: [],

    getSubscriberEdit: true,

    parameters:[
        { paramtable_id: 1, tooltip: ''},
        { paramtable_id: 2, tooltip: ''},
        { paramtable_id: 4, tooltip: ''},
        { paramtable_id: 3, tooltip: ''},
        { paramtable_id: 11, tooltip: ''},
    ],

    params: {
        paginate: true,
        page: 1,
        perPage: 15,
        filter: '',
        sortBy: 'id',
        sort: 'ASC'
    },

}
