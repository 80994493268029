import {defaultSelectedRestriction} from './state'

export function setContactsWarehouses(state, contactWarehouses) {
    state.contacts_warehouses = contactWarehouses
}

export function setPerPage(state, perPage) {
    state.params.page = 1
    state.params.perPage = perPage
}

export function setPage(state, page) {
    state.params.page = page
}

export function setFilter(state, filter) {
    state.params.page = 1
    state.params.filter = filter
}

export function setSelectedContactWarehouse(state, selected_contact_warehouse) {
    state.selected_contact_warehouse = selected_contact_warehouse
}

export function resetSelectedRestriction(state){
    state.selected_restriction = defaultSelectedRestriction()
}

export function setRestrictions(state, restrictions) {
    state.restrictions = restrictions
}

export function setLines(state, lines) {
    state.lines = lines
}

export function setBrands(state, brands) {
    state.brands = brands

}

export function resetElement(state) {
    state.element.line = null;
    state.element.brand = null;
}
