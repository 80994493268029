export default [
    {
        path: '/reportes',
        component: resolve => require(['./../index.vue'], resolve),
        children: [
            {
                path: '/reportes/matriz-dinamica',
                component: resolve => require(['../views/dynamic-matrix/index.vue'], resolve),
                meta: {
                    title: 'Gestion Presupuesto'
                }
            },
            {
                path: '/reportes/detalle-matriz-dinamica',
                component: resolve => require(['../views/sales-products/index.vue'], resolve),
                meta: {
                    title: 'Detalle matriz dinámica'
                }
            },
            {
                path: '/reportes/gestion-actividad-comercial',
                component: resolve => require(['../views/commercial_management/index.vue'], resolve),
                meta: {
                    title: 'Gestión actividad comercial'
                }
            },
            {
                path: '/reportes/detalle-gestion-actividad-comercial',
                component: resolve => require(['../views/commercial_management/detail.vue'], resolve),
                meta: {
                    title: 'Detalle Gestión actividad comercial'
                }
            },
            {
                path: '/reportes/solicitud-garantias',
                component: resolve => require(['../views/guarantees/index.vue'], resolve),
                meta: {
                    title: 'Gestión de garantías'
                }
            }
        ]
    },

];
