export default {
	receivables:[],
	error: false,
	errorMessage: '',
	sellers: [],
	leaderships: [],
	params: {
		filter_days: '',
		filter_name: '',
		paginate: true,
		page: 1,
		perPage: 15,
		sortBy: 'days_defeated',
		sort: true,
		id:'',
		employee_type:'',
		leadership_id: [],
		seller_id: [],
        would_you_run: false
	},
    preloader: false
}
