import $http from '../../../axios-intance';
import {Notification} from 'element-ui';

export async function fetchBanner({dispatch, state, rootState, commit}, requestParameters = {params: state.params}) {
    if (!state.banners.length) {

        requestParameters.params.lines = rootState.login.user_b2b.line.line.line_code;
        requestParameters.params.contact_id = rootState.login.user_b2b.contact_id;

        await dispatch('bannerB2b/listBanner', requestParameters /* {params:{idParameter:19, idLine:21}} */, {root: true});
        commit('setBanners', rootState.bannerB2b.banners);
    }
}

export async function fetchBrand({dispatch, state, rootState, commit}) {
    let user_b2b = rootState.login.user_b2b;

    let params = {
        paginate: false,
        lines: [user_b2b.line.line.line_code],
        warehouse_id: user_b2b.id
    }

    await dispatch('brands/fetchBrands', {params}, {root: true});

    if (!!rootState.brands.brands.length) {

        let brands = rootState.brands.brands;

        brands = brands.concat(brands)
        brands = brands.concat(brands)

        commit('setBrands', brands);
    }
}

export async function fetchPromotions({dispatch, state, rootState, commit}) {

    let requestParameters = {
        params: {
            contact_warehouse_id: rootState.login.user_b2b.id,
            price_list_id: rootState.login.user_b2b.price_list_id
        }
    };

    await $http.get(`/api/productos-promociones`, requestParameters)
        .then((response) => {
            commit('setProds', response.data.data);
        })
        .catch((error) => {

        });
}

export async function fetchSalesBudgets({dispatch, state, rootState, commit}, id) {
    // let contact_warehouse_id = rootState.login.user_b2b.id
    let contact_id = rootState.login.user_b2b.contact_id;

    await $http.post(`/api/presupuesto-de-ventas`, {seller_id: id, contact_id: contact_id})
        .then((response) => {
            commit('setSalesBudgetShow', response.data);
        })
        .catch((error) => {

        });
}

export async function calculatePromotions({commit, state, rootState}) {

    let contact_warehouse_id = rootState.login.user_b2b.id;

    await $http.get('/api/calcular-promociones/' + contact_warehouse_id);
}
