export function setBrands(state, brands) {
    state.brands = brands.map(p => {
        return {value: p.id, label: p.description, imagen: p.thumbnails}
    })
}

export function setSellers(state, sellers) {
    state.sellers = sellers
}

export function setContacts(state, contacts) {
    state.contacts = contacts
}
