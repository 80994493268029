import {defaultSelectedVouchersType} from "./state";

export function setVouchersTypes(state, vouchersTypes){
	state.vouchersTypes = vouchersTypes;
}

export function setVouchersType(state, vouchersType){
	state.selectedVouchersType = vouchersType;
}
export function setAction(state, action){
	state.action = action;
}

export function vouchersTypeError(state, payload){
	state.error = true
	state.errorMessage = payload
	state.vouchersTypes = []
}

export function setGetVouchersTypeEdit(state, getVouchesTypeEdit) {
	state.getVouchesTypeEdit = getVouchesTypeEdit;
}

export function resetSelectedVouchersType(state) {
	Object.assign(state.selectedVouchersType, defaultSelectedVouchersType())
}
//--------------------tipo de comprobante-----------------------------//
export function setBranchOffices(state, branchOffices) {
	state.branchOffices = branchOffices.map(p => {
		return {value: p.id, label: p.name}
	})
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter){
	state.params.page = 1
	state.params.filter = filter
}

export function setPerPage(state, perPage){
	state.params.page = 1
	state.params.perPage = perPage
}

export function setPage(state, page){
	state.params.page = page
}

export function setSortBy(state, sortBy){
	state.params.sortBy = sortBy
}

export function setSort(state, sort){
	state.params.sort = sort
}
