<template>
<div>
    <div class="div_preload">
            <p class="d-block"> <img src="../../assets/img/loader.gif" alt="loading..." height="64" width="64"></p>
    </div>
    <div class="div_preload_two">
        <p class="text_preload text-center" v-html="mensaje"/>
    </div>
</div>
</template>
<script>
export default {
    name: 'preloader',
    props:[
        "mensaje"
    ]
}
</script>
<style>
.div_preload{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 100000;
    background: #FFFFFF;
    opacity:0.75;
    left: 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.div_preload_two{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 100000;
    left: 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.text_preload{
    margin-top: 130px;
    z-index: 200000;
    position: absolute;
    color: #1c2175;
    font-weight: bold;
    font-size: 17px;
}

</style>
