import $http from '../../../axios-intance';
import {MessageBox, Notification} from 'element-ui';

// Obtener contenido del carrito
export async function fetchCartItems({commit, state}) {
    await $http.get('/api/items-carrito').then((response) => {
        commit('setCartItems', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('cartError', error.message);
    });
}

// Validar disponiblidad
export async function validateAvailabilityAction({commit, state}) {
    await $http.post('/api/validar-disponibilidad/' + state.cart.id).then((response) => {
        commit('relativeProductsForm/setProductsForm', response.data.data, {root: true});
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('cartError', error.message);
    });
}

// Agregar items al carrito
export async function addCartItems({commit, state, rootState, dispatch}, products) {
    products.original_quantity = products.original_quantity != '' || products.original_quantity != 0 ? products.original_quantity : 0;

    await $http.post('/api/items-carrito', products).then(async (response) => {
        let current_cart = JSON.parse(JSON.stringify(state.cart));

        commit('setCart', response.data.data);

        let current_price_list;

        if (current_cart.price_list_id) {
            current_price_list = state.cart.price_lists.find(pl => pl.id === current_cart.price_list_id);
        } else {
            current_price_list = state.cart.price_lists.find(pl => pl.id === rootState.login.user_b2b.price_list_id);
        }

        let new_price_list = state.cart.price_lists.find(pl => pl.id === state.cart.price_list_id);

        if (new_price_list && current_price_list) {

            let custom_class = 'alert-lists ';

            let message = 'De la lista ' + current_price_list.code_parameter + ' - ' + current_price_list.name_parameter + ' a la lista ' + new_price_list.code_parameter + ' - ' + new_price_list.name_parameter;
            let diff = current_price_list.numeric_data_first !== new_price_list.numeric_data_first;
            custom_class += current_price_list.numeric_data_first > new_price_list.numeric_data_first ? 'bg-danger' : 'bg-success';

            if (diff) {
                MessageBox.alert(message, 'El pedido ha cambiado de lista de precios', {
                    type: 'info',
                    duration: 5000,
                    customClass: custom_class,
                    iconClass: 'text-white',
                    confirmButtonClass: 'btn-light text-dark',
                    center: true
                });
            }
        }

    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('cartError', error.message);
    });
}

export async function getCartTotalized({commit, state}) {
    await $http.get('/api/totalizar-carrito/' + state.cart.id).then(async (response) => {

        commit('setCart', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('cartError', error.message);
    });
}

/**
 * Obtener lista de precios.
 */
export async function fetchPriceListConfig({commit, state, rootState}, price_list) {

    let params = {
        price_list_id: rootState.login.user_b2b.price_list_id
    };

    await $http.get('/api/configuracion-lista-de-precios', {params})
        .then((response) => {

            let price_list_config = response.data.data;

            price_list_config.map(pl => {
                if (pl.line_id === rootState.login.user_b2b.line.line_id) {
                    commit('setPriceListConfig', pl);
                }
            });
        })
        .catch((error) => {
            console.error(error);
            Notification.error({
                title: 'Error',
                message: 'Error al obtener las listas de precios',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

// Eliminar items del carrito
export async function removeCartItems({commit, state}, item) {
    await $http.delete(`/api/items-carrito/${item.id}`).then((response) => {
        let current_cart = JSON.parse(JSON.stringify(state.cart));

        commit('setCart', response.data.data);

        let current_price_list;

        if (current_cart.price_list_id) {
            current_price_list = state.cart.price_lists.find(pl => pl.id === current_cart.price_list_id);
        } else {
            current_price_list = state.cart.price_lists.find(pl => pl.id === rootState.login.user_b2b.price_list_id);
        }

        let new_price_list = state.cart.price_lists.find(pl => pl.id === state.cart.price_list_id);

        if (new_price_list && current_price_list) {

            let custom_class = 'alert-lists ';

            let message = 'De la lista ' + current_price_list.code_parameter + ' - ' + current_price_list.name_parameter + ' a la lista ' + new_price_list.code_parameter + ' - ' + new_price_list.name_parameter;
            let diff = current_price_list.numeric_data_first !== new_price_list.numeric_data_first;
            custom_class += current_price_list.numeric_data_first > new_price_list.numeric_data_first ? 'bg-danger' : 'bg-success';

            if (diff) {
                MessageBox.alert(message, 'El pedido ha cambiado de lista de precios', {
                    type: 'info',
                    duration: 5000,
                    customClass: custom_class,
                    iconClass: 'text-white',
                    confirmButtonClass: 'btn-light text-dark',
                    center: true
                });
            }
        }
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('cartError', error.message);
    });
}

// Crear nuevo carrito
export async function newCart({commit, state}, cart) {
    await $http.post('/api/carrito', cart).then((response) => {
        commit('setCart', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('cartError', error.message);
    });
}

// Obtener los datos del carrito
export async function fetchCart({commit, state}, parameters) {
    await $http.get(`/api/carrito/${parameters.contact_id}/${parameters.branchoffice_id}/${parameters.user_id}`, {params: parameters}).then((response) => {

        let cart = response.data.data;

        if (cart.recalculate_prices) {
            Notification.warning({
                title: 'Estimado usuario.',
                message: 'Los valores de está cotización se han actualizado debido a que los precios han cambiado desde la ultima vez que se modificó esta cotización.',
                type: 'warning',
                duration: 6000,
                customClass: 'notification-box'
            });
        }

        commit('setCart', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('cartError', error.message);
    });
}

export async function finaliceQuotation({dispatch, rootState, commit}, cart) {

    return await new Promise(async (resolve, reject) => {
        await $http.post(`/api/finalizar-cotizacion`, cart).then((response) => {
            commit('setCart', {});
            resolve();
        }).catch((error) => {
            commit('cartError', error.message);
            reject();
        });
    });

}

/* Agregar items al carrito */
export async function addToCart({state, rootState, dispatch}, {
    id,
    cant,
    price,
    line_id,
    subline_id,
    brand_id,
    promotion_price,
    percentage_iva_sale,
    suggested_price
}) {

    let product = {
        'product_id': id,
        'seller': rootState.login.user_b2b.seller_id,
        'line_id': line_id,
        'subline_id': subline_id,
        'brand_id': brand_id,
        'applies_inventory': false,
        'quantity': cant,
        'unit_value': price.price,
        'unit_value_suggested_price': suggested_price.price,
        'iva_percentage': percentage_iva_sale,
        'discount_value': Math.round(price.price * price.percentage / 100),
        'discount_value_suggested_price': Math.round(suggested_price.price * price.percentage / 100),
        'original_quantity': cant
    };

    // Validar si el carrito existe
    if (rootState.cart.cart.id == null || rootState.cart.cart.id == '') {
        // Preparar datos obligatorios del carrito
        let cart = {
            warehouse_id: rootState.login.user_b2b.id,
            vouchertype_id: 1,
            prefix: 'Pd',
            contact_id: rootState.login.user_b2b.contact_id,
            inventory_operation: 'pedido',
            in_progress: true,
            pay_condition: rootState.login.user_b2b.pay_condition,
            send_point: rootState.login.user_b2b.send_point,
            seller_id: rootState.login.user_b2b.seller_id,
            user_id: rootState.login.user_logued.id,
            documents_information: {
                price_list: rootState.login.user_b2b.price_list_id
            }
        };

        // Crear carrito
        await dispatch('newCart', cart);
    }

    // Asignar el id del carrito
    product.document_id = rootState.cart.cart.id;

    // Agregar producto al carrito
    await dispatch('addCartItems', product);
}

/* Agregar items relacionados al carrito */
export async function addToCartFinalize({rootState, dispatch}, {
    id,
    cant,
    price,
    line_id,
    subline_id,
    brand_id,
    percentage_iva_sale
}) {
    let product = {
        'document_id': rootState.cart.cart.id,
        'product_id': id,
        'seller': rootState.login.user_b2b.seller_id,
        'line_id': line_id,
        'subline_id': subline_id,
        'brand_id': brand_id,
        'applies_inventory': true,
        'quantity': cant ? cant : 1,
        'unit_value': price.price,
        'iva_percentage': percentage_iva_sale,
        'discount_value': Math.round(price.price * price.percentage / 100)
    };

    await dispatch('addCartItems', product);

    // Actualizar disponibilidad
    /*await $http.post('/api/actualizar-disponibilidad', product).then((response) => {

    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        })
        commit('cartError', error.message)
    })*/
}

/* Agregar información del carrito */
export async function addInformation({commit, state}, information) {
    await $http.post('/api/informacion-carrito', information).then((response) => {
        commit('setInformation', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('cartError', error.message);
    });
}

/* Finalizar proceso de pedido */
export async function finalizeOrderAction({dispatch, rootState, commit}, cart) {

    await $http.post(`/api/finalizar-pedido`, cart).then((response) => {
        dispatch('fetchCart', {
            contact_id: rootState.login.user_b2b.contact_id,
            branchoffice_id: rootState.login.user_b2b.id,
            user_id: rootState.login.user_logued.id
        });

        commit('setPdf', response.data.data.filename);
        commit('setCart', {});
        commit('setLastCart', response.data.data);
        commit('cartError', '');
    })
        .catch((error) => {
            commit('cartError', error.message);
        });
}

export async function validateIfExistsOrder({dispatch, rootState, commit}, cart) {

    return await new Promise(async (resolve, reject) => {

        await $http.post(`/api/validar-existencia-pedido`, {id: cart.id}).then((response) => {

            let validation = response.data.data;

            if (validation) {
                resolve(validation);
            } else {
                reject('El número de pedido ya existe en base de datos');
            }

        }).catch((error) => {
            reject('Error al validar la existencia del pedido');
        });
    });
}

export async function validateContactInformation({rootState}) {
    return await new Promise(async (resolve, reject) => {
        await $http.get(`/api/validar-datos-tercero/${rootState.login.user_b2b.contact.id}`)
            .then((response) => {

                let errors = response.data.data;

                if (!!errors) {

                    let errors_ = '<h6>La validación de los datos del cliente prospecto retornó los siguientes errores. Por favor corríjalos e intente nuevamente</h6>';
                    errors_ += '<ul>';

                    for (const key in errors) {
                        errors[key].map(error => {
                            errors_ += '<li>' + error + '</li>';
                        });
                    }

                    errors_ += '</ul>';

                    reject(errors_);
                } else {
                    resolve(errors);
                }

            }).catch((error) => {
                console.error(error);
                reject('Error al validar los datos del tercero');
            });
    });
}

export async function createContactInSiesa({rootState, commit}) {
    return await new Promise(async (resolve, reject) => {
        await $http.post(`/api/crear-prospecto-siesa/${rootState.login.user_b2b.contact.id}`)
            .then((response) => {

                localStorage.setItem('user_b2b', JSON.stringify(response.data.data));
                commit('login/setUserLoguedB2b', response.data.data, {root: true});
                resolve();

            }).catch((error) => {
                console.error(error);
                reject('Error al crear el tercero');
            });
    });
}

export async function getTotalOrder({dispatch, rootState, commit}, cart) {
    return await new Promise(async (resolve, reject) => {
        await $http.get(`/api/total-pedido/${cart.id}`)
            .then((response) => {
                resolve(response.data.data);
            }).catch((error) => {
                reject('Error al consultar el valor total del pedido');
            });
    });
}

export async function updateAvailabilityProducts({dispatch, rootState, commit}, cart) {

    return await new Promise(async (resolve, reject) => {

        await $http.post(`/api/actualizar-disponibilidad-productos`, {id: cart.id}).then((response) => {

            let validation = response.data.data;

            resolve(validation);

        }).catch((error) => {
            reject('Error al actualizar la disponibilida de los productos');
        });
    });
}

export async function sendOrderBySoap({dispatch, rootState, commit}, cart) {

    return await new Promise(async (resolve, reject) => {

        let params = {
            id: cart.id,
            buy_order: cart.buy_order,
            observation: cart.observation
        };

        await $http.post(`/api/enviar-pedido-siesa`, params).then((response) => {

            let validation = response.data.data;

            if (validation) {
                resolve(validation);
            } else {
                reject('Se ha presentado un error al enviar el pedido');
            }

        }).catch((error) => {
            reject('Error enviando el pedido');
        });
    });
}

export async function syncSingleOrder({dispatch, rootState, commit}, cart) {

    return await new Promise(async (resolve, reject) => {

        await $http.post(`/api/obtener-pedido-siesa`, {id: cart.id}).then((response) => {

            let document_id = response.data.data;

            resolve(document_id);

        }).catch((error) => {
            reject('Se ha presentado un error al obtener la información del pedido');
        });
    });
}

export async function finalizeCart({dispatch, rootState, commit}, cart) {

    return await new Promise(async (resolve, reject) => {

        await $http.post(`/api/finalizar-carrito`, {id: cart.id}).then((response) => {

            let finalized = response.data.data;

            if (finalized) {
                resolve(finalized);
            } else {
                reject('Error al finalizar el pedido');
            }

        }).catch((error) => {
            reject('Error al finalizar el pedido');
        });
    });
}

export async function generatePdf({dispatch, rootState, commit}, order_id) {

    return await new Promise(async (resolve, reject) => {

        await $http.post(`/api/generar-pdf-pedido`, {id: order_id}).then((response) => {

            let filename = response.data.data;

            commit('setPdf', response.data.data);

            resolve(filename);

        }).catch((error) => {
            reject('Error al generar el pdf del pedido');
        });
    });
}


export async function getPdf({commit, state}, id) {
    await $http.get(`/api/imprimir-pedido/${id}`)
        .then((response) => {
            commit('setPdf', response.data.data.pdf);
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('cartError', error.message);
        });
}

export async function downloadDocument({state}) {

    let name = state.pdf.split('/').pop();
    // let extension = name.split('.').pop()
    await $http({
        url: state.pdf,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 3000,
            customClass: 'notification-box',
        });

    });
}

export async function getProductFreight({commit, state, rootState}) {
    await $http.get('/api/calcular-precio/' + rootState.login.user_b2b.id + '/32893')
        .then((response) => {
            commit('setFreight', response.data.data);
        });
}

export async function removeFreight({dispatch, state}) {
    let freight = state.cart.documents_products.find(f => f.product_id === 32893);

    if (freight) {
        await dispatch('removeCartItems', freight);
    }
}

export async function getContactPropartes({commit}) {
    await $http.get('/api/establecimientos?contact_id=9631').then((response) => {
        commit('setContactPropartes', response.data.data);
    });
}


export async function getContact({commit}, warehouse) {

    await $http.get(`/api/establecimientos-vendedor?ide=${warehouse}`).then((response) => {
        commit('ecommerce/setSelectedCustomer', response.data.data, {root: true});
        localStorage.setItem('user_b2b', JSON.stringify(response.data.data));
        commit('login/setUserLoguedB2b', response.data.data, {root: true});
    });
}

export async function validateExistenceContactByIdentification({commit}, identification) {

    await $http.get(`/api/tercero-identificacion/${identification}`)
        .then((response) => {
            commit('login/setShowAlertContact', !!response.data.data, {root: true});
        });
}


// Eliminar items del carrito
export async function deleteCart({commit}, id) {
    await $http.delete(`/api/carrito/${id}`).then((response) => {

    }).catch((error) => {

    });
}



