import {defaultSelectedDocument} from "./state";

export function setDocuments(state, documents) {
    state.documents = documents;
}

export function setDocument(state, document) {
    state.selectedDocument = JSON.parse(JSON.stringify(document));
}

export function setActionDocuments(state, action) {
    state.actionDocument = action;
}

export function documentError(state, error) {
    state.error = true
    state.errorMessage = error
}

// Establece un valor para documentsTypes
export function setDocumentsTypes(state, documentsTypes) {
    state.documentsTypes = documentsTypes
}

//Establece el valor por defecto de selectedDocument
export function resetSelectedDocument(state) {
    let id = state.selectedDocument.id
    Object.assign(state.selectedDocument, defaultSelectedDocument())
    if (id) {
        state.selectedDocument.id = id
    }
}

export function setDocumentPath(state, path) {
    state.selectedDocument.path = path;
}
