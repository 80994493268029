export const defaultSelectedClerk = () => {
    return {
        name: '',
        surname: '',
        identification_type: '',
        identification: '',
        gender: '',
        birthdate: '',
        city_id: '',
        address: '',
        stratum: '',
        main_telephone: '',
        secondary_telephone: '',
        cell_phone: '',
        email: '',
        observation: '',
        position: '',
        city:{
            department_id: ""
        }
    }
}

export default {

    clerks: [],
    selectedClerk: defaultSelectedClerk(),
    error: false,
    errorMessage: '',
    actionClerk: 'create',
    citiesClerks: [],
    documentsTypes: [],
    getContactEdit: true,

}
