import $http from '../../axios-intance';
import {Notification} from 'element-ui';

/**
 * Obtener motivos.
 */
export async function fetchReasons({commit, state}) {
    await $http.get('/api/parametros-por-codigo/GAR01')
        .then((response) => {
            commit('setReasons', response.data.data);
        })
        .catch((error) => {
            Notification.error({
                title: 'Error',
                message: 'Error al obtener los motivos',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

/**
 * Crear motivo.
 */
export async function createReason({commit, state, dispatch}) {
    await $http.post('/api/parametros', state.reason)
        .then(async (response) => {
            commit('setModalShow', false);
            await dispatch('fetchReasons');
            Notification.success({
                title: 'Éxito!',
                message: 'Motivo creado exitosamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            console.log(error);
            Notification.error({
                title: 'Error',
                message: 'Error al guardar el motivo',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

/**
 * Crear motivo.
 */
export async function updateReason({commit, state, dispatch}) {
    await $http.put('/api/parametros/' + state.reason.id, state.reason)
        .then(async (response) => {
            commit('setModalShow', false);
            await dispatch('fetchReasons');
            Notification.success({
                title: 'Éxito!',
                message: 'Motivo actualizado exitosamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            console.log(error);
            Notification.error({
                title: 'Error',
                message: 'Error al guardar el motivo',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}
