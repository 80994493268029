
export function setProduct(state, product) {
	product.cant = 1
	product.total = product.price. price
	state.selectedProduct = product
	state.related = product.related_products
	state.imagen = product.products_attachments == '' ? product.brand.image : product.products_attachments[0].url
}

export function setProductCant(state, product) {
	state.selectedProduct.cant = product.cant
}

export function setRelated(state, related) {
	related.forEach(p => {
		p.cant = null, p.click=0, p.total = p.price.price, p.add = false
	})
	state.related = related
}

export function productError(state, payload) {
	state.error = true
	state.errorMessage = payload
	state.products = []
}

export function setBrands(state, brands) {
	state.brands = brands.map(p => {
		return {value:p.id, label: p.description, imagen:p.thumbnails}
	})
}

export function setCategories(state, categories) {
	state.categories = categories.map(p => {
		return {value:p.id, label: p.description}
	})
}

export function setGroups(state, groups) {
	state.groups = groups.map(p => {
		return {value:p.id, label: p.subline_description}
	})
}

export function setPriceList(state, price_list) {
	state.params.price_list_id = price_list
}