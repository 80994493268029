import b2bRoutes from './b2b/router';
import reports_routes from './reports-aggrid/router';

const baseRoutes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/inicio',
        component: resolve => require(['./default.vue'], resolve),
        children: [
            {
                path: '',
                component: resolve => require(['./views/ecommerce/maintenance.vue'], resolve),
                meta: {
                    title: 'E-commerce',
                    permission: 'E-commerce',
                    breadcrumb: [{
                        text: 'E-commerce',
                        href: '/',
                    }]
                }
            },
            {
                path: '/licencias',
                component: resolve => require(['./views/licenses/index.vue'], resolve),
                meta: {
                    title: 'Licencias',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Licencias',
                        href: '#/licencias',
                    }]
                }
            },
            {

                path: '/terceros-marcas',
                component: resolve => require(['./views/customers-restrictions/index.vue'], resolve),
                meta: {
                    title: 'Restricción de Marcas',
                    permission: 'Restricción marcas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Restricción de Marcas',
                        href: '#/terceros-marcas',
                    }]
                }
            },
            {
                path: '/ocultar-marcas/:id',
                component: resolve => require(['./views/customers-restrictions/maintenance.vue'], resolve),
                meta: {
                    title: 'Restricción de Marcas',
                    permission: 'Restricción marcas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Restricción de Marcas',
                        href: '#/ocultar-marcas',
                    }]
                }
            },
            {
                path: '/ventas-perdidas',
                component: resolve => require(['./views/lost_sales/index.vue'], resolve),
                meta: {
                    title: 'Ventas perdidas',
                    permission: 'Ventas perdidas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Ventas perdidas',
                        href: '#/ventas-perdidas',
                    }]
                }
            },
            {
                path: '/terceros',
                component: resolve => require(['./views/contacts/index.vue'], resolve),
                meta: {
                    title: 'Terceros',
                    permission: 'Terceros',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Terceros',
                        href: '#/terceros',
                    }]
                }
            },
            {
                path: '/terceros/:id/editar',
                component: resolve => require(['./views/contacts/maintenance.vue'], resolve),
                meta: {
                    title: 'Terceros',
                    permission: 'Terceros',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Terceros',
                        href: '#/terceros',
                    }, {
                        text: 'Terceros',
                        href: '#/terceros/:id/editar',
                    }]
                }
            },
            {
                path: '/terceros/:idv/ver',
                component: resolve => require(['./views/contacts/maintenance.vue'], resolve),
                meta: {
                    title: 'Terceros',
                    permission: '',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Terceros',
                        href: '#/terceros',
                    }, {
                        text: 'Terceros',
                        href: '#/terceros/:idv/ver',
                    }]
                }
            },
            {
                path: '/usuarios',
                component: resolve => require(['./views/users/index.vue'], resolve),
                meta: {
                    title: 'Usuarios',
                    permission: 'Usuarios',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Usuarios',
                        href: '#/usuarios',
                    }]
                }
            },
            {
                path: '/manual-de-procesos',
                component: resolve => require(['./views/process_manual/index.vue'], resolve),
                meta: {
                    title: 'Manual de procesos',
                    // permission: 'Manual de procesos',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Manual de procesos',
                        href: '#/manual-de-procesos',
                    }]
                }
            },
            {
                path: '/jefaturas',
                component: resolve => require(['./views/leadership/index.vue'], resolve),
                meta: {
                    title: 'Jefaturas',
                    permission: 'Jefaturas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Jefaturas',
                        href: '#/jefaturas',
                    }]
                }
            },
            {
                path: '/jefaturas/crear',
                component: resolve => require(['./views/leadership/maintenance.vue'], resolve),
                meta: {
                    title: 'Jefaturas',
                    permission: 'Jefaturas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Jefaturas',
                        href: '#/licencias',
                    }, {
                        text: 'Crear Jefatura',
                        href: 'jefaturas/crear',
                    }]
                }
            },
            {
                path: '/jefaturas/:idv/ver',
                component: resolve => require(['./views/leadership/maintenance.vue'], resolve),
                meta: {
                    title: 'Jefaturas',
                    permission: 'Jefaturas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Jefaturas',
                        href: '#/jefaturas',
                    }, {
                        text: 'Detalle Licencia',
                        href: '#/jefaturas/:idv/ver',
                    }]
                }
            },
            {
                path: '/jefaturas/:id/editar',
                component: resolve => require(['./views/leadership/maintenance.vue'], resolve),
                meta: {
                    title: 'Jefaturas',
                    permission: 'Jefaturas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Jefaturas',
                        href: '#/jefaturas',
                    }, {
                        text: 'Detalle Licencia',
                        href: '#/jefaturas/:id/editar',
                    }]
                }
            },
            {
                path: '/direcciones-comerciales',
                component: resolve => require(['./views/commercial_directions/index.vue'], resolve),
                meta: {
                    title: 'Direcciones Comerciales',
                    permission: 'Direcciones Comerciales',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Direcciones Comerciales',
                        href: '#/direcciones-comerciales',
                    }]
                }
            },
            {
                path: '/direcciones-comerciales/crear',
                component: resolve => require(['./views/commercial_directions/maintenance.vue'], resolve),
                meta: {
                    title: 'Direcciones Comerciales',
                    permission: 'Direcciones Comerciales',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Direcciones Comerciales',
                        href: '#/licencias',
                    }, {
                        text: 'Crear Jefatura',
                        href: 'direcciones-comerciales/crear',
                    }]
                }
            },
            {
                path: '/direcciones-comerciales/:idv/ver',
                component: resolve => require(['./views/commercial_directions/maintenance.vue'], resolve),
                meta: {
                    title: 'Direcciones Comerciales',
                    permission: 'Direcciones Comerciales',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Direcciones Comerciales',
                        href: '#/direcciones-comerciales',
                    }, {
                        text: 'Detalle Licencia',
                        href: '#/direcciones-comerciales/:idv/ver',
                    }]
                }
            },
            {
                path: '/direcciones-comerciales/:id/editar',
                component: resolve => require(['./views/commercial_directions/maintenance.vue'], resolve),
                meta: {
                    title: 'Direcciones Comerciales',
                    permission: 'Direcciones Comerciales',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Direcciones Comerciales',
                        href: '#/direcciones-comerciales',
                    }, {
                        text: 'Detalle Licencia',
                        href: '#/direcciones-comerciales/:id/editar',
                    }]
                }
            },
            {
                path: '/usuarios/crear',
                component: resolve => require(['./views/users/maintenance.vue'], resolve),
                meta: {
                    title: 'Usuarios',
                    permission: 'Usuarios',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Usuarios',
                        href: '#/licencias',
                    }, {
                        text: 'Crear Usuario',
                        href: 'usuarios/crear',
                    }]
                }
            },
            {
                path: '/usuarios/:id/editar',
                component: resolve => require(['./views/users/maintenance.vue'], resolve),
                meta: {
                    title: 'Usuarios',
                    permission: 'Usuarios',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Usuarios',
                        href: '#/usuarios',
                    }, {
                        text: 'Detalle Licencia',
                        href: '#/usuarios/:id/editar',
                    }]
                }
            },
            {
                path: '/usuarios/:idv/ver',
                component: resolve => require(['./views/users/maintenance.vue'], resolve),
                meta: {
                    title: 'Usuarios',
                    permission: 'Usuarios',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Usuarios',
                        href: '#/usuarios',
                    }, {
                        text: 'Detalle Licencia',
                        href: '#/usuarios/:idv/ver',
                    }]
                }
            },
            {
                path: '/roles-permisos-u2',
                component: resolve => require(['./views/roles/maintenance.vue'], resolve),
                meta: {
                    title: 'Roles',
                    role: 'Super Administrador',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Roles',
                        href: '#/roles',
                    }]
                }
            },
            {
                path: '/roles-permisos',
                component: resolve => require(['./views/roles_permissions/maintenance.vue'], resolve),
                meta: {
                    title: 'Roles',
                    role: 'Super Administrador',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Roles',
                        href: '#/roles',
                    }]
                }
            },
            {
                path: '/reporte/:report',
                component: resolve => require(['./views/report/index.vue'], resolve),
                meta: {
                    title: 'Matriz dinámica',
                    breadcrumb: [
                        {
                            text: ' Dashboard',
                            href: '#/'
                        },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Matriz dinámica',
                            href: '#/reporte'
                        }
                    ]
                }
            },
            {
                path: '/participacion-clientes',
                component: resolve => require(['./views/customerParticipation/index.vue'], resolve),
                meta: {
                    title: 'Participación de clientes por mes',
                    permission: 'Participación de clientes por mes',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Participación de clientes por mes',
                        href: '#/participacion-clientes',
                    }]
                }
            },
            {
                path: 'blank',
                component: resolve => require(['./components/pages/blank.vue'], resolve),
                meta: {
                    title: 'Blank',
                    permission: 'Blank',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Extra Pages',
                        href: '#',
                    }, {
                        text: 'Blank',
                        href: '#/blank'
                    }]
                }
            },
            {
                path: '/marcas',
                component: resolve => require(['./views/brands/index.vue'], resolve),
                meta: {
                    title: 'Marcas',
                    permission: 'Marcas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Marcas',
                        href: '#/marcas',
                    }]
                }
            },
            {
                path: '/marcas/:id/editar',
                component: resolve => require(['./views/brands/maintenance.vue'], resolve),
                meta: {
                    title: 'Marcas',
                    permission: 'Marcas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    }, {
                        text: 'Administración Starcommerce',
                        href: '#',
                    }, {
                        text: 'Marcas',
                        href: '#/marcas',
                    }, {
                        text: 'Marcas',
                        href: '#/marcas/:id/editar',
                    }]
                }
            },
            {
                path: '/banners',
                component: resolve => require(['./views/banners_b2b/index.vue'], resolve),
                meta: {
                    title: 'Banners',
                    permission: 'Banners',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Banners',
                            href: '#/banners'
                        }
                    ]
                }
            },
            {
                path: '/banners/crear',
                component: resolve => require(['./views/banners_b2b/maintenance.vue'], resolve),
                meta: {
                    title: 'Banners',
                    permission: 'Banners',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Banners',
                            href: '#/banners'
                        }
                    ]
                }
            },
            {
                path: '/banners/:id/editar',
                component: resolve => require(['./views/banners_b2b/maintenance.vue'], resolve),
                meta: {
                    title: 'Banners',
                    permission: 'Banners',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'B2B',
                            href: '#'
                        },
                        {
                            text: 'Banners',
                            href: '#/banners'
                        },
                        {
                            text: 'Banners',
                            href: '#/banners/:id/editar'
                        }
                    ]
                }
            },
            {
                path: '/cargue_presupuesto',
                component: resolve => require(['./views/sales_budgets/maintenance.vue'], resolve),
                meta: {
                    title: 'Cargue de Presupuesto',
                    permission: 'Cargue Presupuesto',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'B2B',
                            href: '#'
                        },
                        {
                            text: 'Cargue de Presupuesto',
                            href: '#/cargue_presupuesto'
                        }
                    ]
                }
            },
            {
                path: '/ecommerce',
                component: resolve => require(['./views/ecommerce/maintenance.vue'], resolve),
                meta: {
                    title: 'E-commerce',
                    permission: 'E-commerce',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'B2B',
                            href: '#'
                        },
                        {
                            text: 'E-commerce',
                            href: '#/ecommerce'
                        }
                    ]
                }
            },
            {
                path: '/b2b/tercer/lista-clientes-prospectos',
                component: resolve => require(['./views/prospective-customer/index.vue'], resolve),
                meta: {
                    title: 'Clientes Prospectos',
                    permission: 'Cliente Prospecto',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'B2B',
                            href: '#'
                        },
                        {
                            text: 'Clientes Prospectos',
                            href: '#/cliente-prospecto'
                        }
                    ]
                }
            },
            {
                path: '/b2b/tercer/cliente-prospecto/:id?',
                component: resolve => require(['./views/prospective-customer/maintenance.vue'], resolve),
                meta: {
                    title: 'Cliente Prospecto',
                    permission: 'Cliente Prospecto',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'B2B',
                            href: '#'
                        },
                        {
                            text: 'Cliente Prospecto',
                            href: '#/cliente-prospecto'
                        }
                    ]
                }
            },
            {
                path: '/gestion-de-cobranzas',
                component: resolve => require(['./views/payment-managment/paymentManagment.vue'], resolve),
                meta: {
                    title: 'Gestión de cobranzas',
                    permission: 'Gestión de cobranzas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/',
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#',
                        },
                        {
                            text: 'Gestión de cobranzas',
                            href: '#/gestion-de-cobranzas',
                        }
                    ]
                }
            },
            {
                path: '/ruta-vendedor',
                component: resolve => require(['./views/coordinate-seller/coordinate-seller.vue'], resolve),
                meta: {
                    title: 'Ruta vendedor',
                    permission: 'Ruta vendedor',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'B2B',
                            href: '#'
                        },
                        {
                            text: 'Ruta vendedor',
                            href: '#/ruta-vendedor'
                        }
                    ]
                }
            },
            {
                path: '/seguimiento-vendedores',
                component: resolve => require(['./views/visits/index.vue'], resolve),
                meta: {
                    title: 'Seguimiento Actividad Comercial',
                    permission: 'Gestión de visitas',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'B2B',
                            href: '#'
                        },
                        {
                            text: 'Seguimiento Actividad Comercial',
                            href: '#/seguimiento-vendedores'
                        }
                    ]
                }
            },
            {
                path: '/b2b/pedidos',
                component: resolve => require(['./views/orders/index.vue'], resolve),
                meta: {
                    title: 'Gestión de pedidos',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'B2B',
                            href: '#'
                        },
                        {
                            text: 'Gestión de pedidos',
                            href: '/b2b/pedidos'
                        }
                    ]
                }
            },
            {
                path: '/b2b/cotizaciones',
                component: resolve => require(['./views/quotations/index.vue'], resolve),
                meta: {
                    title: 'Gestión de cotizaciones',
                    permission: 'Gestión de cotizaciones',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'B2B',
                            href: '#'
                        },
                        {
                            text: 'Gestión de cotizaciones',
                            href: '#/cotizaciones'
                        }
                    ]
                }
            },
            {
                path: '/b2b/promociones',
                component: resolve => require(['./views/commercial-management/index.vue'], resolve),
                meta: {
                    title: 'Gestión comercial',
                    permission: 'Gestión comercial',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'B2B',
                            href: '#'
                        },
                        {
                            text: 'Gestión comercial',
                            href: '#/promociones'
                        }
                    ]
                }
            },
            {
                path: '/productos',
                component: resolve => require(['./views/products/Index.vue'], resolve),
                meta: {
                    title: 'Productos',
                    permission: 'Productos',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Productos',
                            href: '#/promociones'
                        }
                    ]
                }
            },
            {
                path: '/productos/cargar-imagenes',
                component: resolve => require(['./views/products/LoadImages.vue'], resolve),
                meta: {
                    title: 'Subir Imágenes Productos',
                    permission: 'Subir Imágenes Productos',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Subir Imágenes Productos',
                            href: '#/promociones'
                        }
                    ]
                }
            },
            {
                path: '/agenda',
                component: resolve => require(['./views/schedule/index.vue'], resolve),
                meta: {
                    title: 'Agenda',
                    permission: 'Agenda',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Agenda',
                            href: '#/agenda'
                        }
                    ]
                }
            },
            {
                path: '/configuracion-profundizacion',
                component: resolve => require(['./views/deepening/index.vue'], resolve),
                meta: {
                    title: 'Configuración profundización',
                    permission: 'Configuración profundización',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Configuración profundización',
                            href: '#/configuracion-profundizacion'
                        }
                    ]
                }
            },
            {
                path: '/configuracion-listas-de-precios',
                component: resolve => require(['./views/price_lists_config/index.vue'], resolve),
                meta: {
                    title: 'Configuración listas de precios',
                    permission: 'Configuración listas de precios',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Configuración listas de precios',
                            href: '#/configuracion-listas-de-precios'
                        }
                    ]
                }
            },
            {
                path: '/solicitud-garantias',
                component: resolve => require(['./views/guarantees_admin/index.vue'], resolve),
                meta: {
                    title: 'Gestión de garantías',
                    permission: 'Gestión de Garantías',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Gestión de Garantías',
                            href: '#/solicitud-garantias'
                        }
                    ]
                }
            },
            {
                path: '/motivos-garantias',
                component: resolve => require(['./views/guarantees_reasons/index.vue'], resolve),
                meta: {
                    title: 'Motivos garantías',
                    permission: 'Motivos garantías',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Motivos garantías',
                            href: '#/motivos-garantias'
                        }
                    ]
                }
            },
            {
                path: '/actividades',
                component: resolve => require(['./views/activities/index.vue'], resolve),
                meta: {
                    title: 'Actividades',
                    permission: 'Actividades',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Actividades',
                            href: '#/actividades'
                        }
                    ]
                }
            },
            {
                path: '/actualizacion-vendedores',
                component: resolve => require(['./views/sellers_updater/index.vue'], resolve),
                meta: {
                    title: 'Actualización de vendedores',
                    permission: 'Actualización de vendedores',
                    breadcrumb: [{
                        text: ' Dashboard',
                        href: '#/'
                    },
                        {
                            text: 'Administración Starcommerce',
                            href: '#'
                        },
                        {
                            text: 'Actualización de vendedores',
                            href: '#/actualizacion-vendedores'
                        }
                    ]
                }
            }
        ]
    },
    {
        path: '/500',
        component: resolve => require(['./components/pages/500.vue'], resolve),
        meta: {
            title: '500',
            breadcrumb: ``
        }
    },
    {
        path: '/login',
        component: resolve => require(['./components/pages/login.vue'], resolve),
        meta: {
            title: 'Login',
            breadcrumb: ``
        }
    },
    {
        path: '/olvide/contrasena',
        component: resolve => require(['./components/pages/forgot_password.vue'], resolve),
        meta: {
            title: 'Olvide mi contraseña',
            breadcrumb: ``
        }
    },
    {
        path: '/restablecer/contrasena/:token',
        component: resolve => require(['./components/pages/reset_password.vue'], resolve),
        meta: {
            title: 'Restablecer contraseña',
            breadcrumb: ``
        }
    },
    {
        path: '/cambiar/contrasena',
        component: resolve => require(['./components/pages/change_password.vue'], resolve),
        meta: {
            title: 'Cambiar contraseña',
            breadcrumb: []
        }
    },
    {
        path: '*',
        component: resolve => require(['./components/pages/404.vue'], resolve),
        meta: {
            title: '404',
            breadcrumb: ``
        }
    },
    {
        path: '/b2b/vendedor/pedidos/nuevo',
        component: resolve => require(['./b2b/views/new-order-app.vue'], resolve),
        meta: {
            title: '',
            breadcrumb: []
        }
    },
    {
        path: '/b2b/vendedor/dashboard',
        component: resolve => require(['./default.vue'], resolve),
        meta: {
            title: 'Indicadores',
            breadcrumb: []
        }
    },
    {
        path: '/b2b/novedades',
        component: resolve => require(['./default.vue'], resolve),
        meta: {
            title: 'Novedades',
            breadcrumb: []
        }
    }
];

let routes = baseRoutes.concat(b2bRoutes);

routes = routes.concat(reports_routes);

export default routes;
