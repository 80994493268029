import $http from '../../../axios-intance';

export async function getSales({commit, state}) {
    commit('setLoading', true);
    await $http.get('/api/reporte-presupuesto-de-ventas', {params: state.params})
        .then(async (response) => {
            await commit('setSales', response.data);
            commit('setLoading', false);
        });
}

export async function getLeaderships({commit, }) {
    await $http.get('/api/leaderships')
        .then((response) => {
            commit('setLeaderships', response.data.data);
        });
}
