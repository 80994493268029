import $http from '../../../axios-intance'
import { Notification } from 'element-ui'

// Obtener banners
export async function fetchOrders({ commit, state }, requestParameters = { params: state.params }) {
	await $http.get('/api/pedidos', requestParameters)
		.then((response) => {
			commit('setOrders', response.data.data)
		}).catch((error) => {
			Notification.error({
				title: '¡Error!',
				message: error.message,
				type: 'error',
				duration: 1500,
				customClass: 'notification-box'
			})
		})
}

export async function fetchBrand({ dispatch, state, rootState, commit }) {
	if (!state.brands.length) {
		await dispatch('brands/fetchBrands', { params: { paginate: false } }, { root: true })
		commit('setBrands', rootState.brands.brands)
	}
}

export async function fetchSublines({ dispatch, state, rootState, commit }, requestParameters = { params: state.category_params }) {
	await dispatch('sublines/listSublines', requestParameters, { root: true })
	commit('setGroups', rootState.sublines.sublines)
}

export async function fetchCategories({ dispatch, state, rootState, commit }, requestParameters = { params: state.category_params }) {
	console.log(state.categories)
	await dispatch('categories/fetchCategories', requestParameters, { root: true })
	commit('setCategories', rootState.categories.categories)

}

function mapParameters(parameters) {
	return parameters.map(p => {
		return {value: p.id, label: p.name_parameter}
	})
}

// Obtiene las clases de personas
export async function fetchDocumentStatus({dispatch, state, rootState, commit}) {
	if (!state.status.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 64, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setDocumentStatus', mapParameters(rootState.parameters.parameters))
	}
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({ commit, dispatch }, filter) {
	commit('setFilter', filter.target.value)
	dispatch('fetchProducts')
}

export function setFilterReference({ commit, dispatch }, reference) {
	commit('setReference', reference.target.value)
	dispatch('fetchProducts')
}

export function setPerPagePagination({ commit, dispatch }, perPage) {
	commit('setPerPage', parseInt(perPage.target.value))
	dispatch('fetchProducts')
}

export function setPagePagination({ commit, dispatch }, page) {
	commit('setPage', parseInt(page))
	dispatch('fetchProducts')
}

export function setSortByPagination({ commit, dispatch }, sortBy) {
	commit('setSortBy', sortBy)
		// dispatch('fetchProducts')
}

export function setSortPagination({ commit, dispatch }, sort) {
	commit('setSort', sort)
		// dispatch('fetchProducts')
}
