import $http from '../../axios-intance';
import {Notification} from 'element-ui';

//Obtener las sedes
export async function fetchBranchOffices({commit, state}, requestParameters = {params: state.params}) {
    await $http.get('/api/sedes', requestParameters)
        .then((response) => {
            commit('setBranchOffices', response.data.data)
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('BranchOfficeError', error.message)
        })
}

//Crear sucursal
export async function addBranchOffice({commit, state}) {
    await $http.post('/api/sedes', state.selectedBranchOffice)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'La sede ha sido creada',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('resetSelectedBranchOffice')
            state.error = false
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: message,
                dangerouslyUseHTMLString: true,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('BranchOfficeError', error.message)

        })
}

//Consultar sucursal por id
export async function getBranchOffice({commit, state}, id) {
    if (state.getBranchOfficeEdit) {
        await $http.get(`/api/sedes/${id}`)
            .then((response) => {
                commit('setBranchOffice', response.data.data)
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 2000,
                    customClass: 'notification-box',
                });
                commit('BranchOfficeError', error.message)
            })
        commit('setGetBranchOfficeEdit', false)
    }
}

//Actualizar sucursal
export function updateBranchOffice({commit, state}) {

    $http.put(`/api/sedes/${state.selectedBranchOffice.id}`, state.selectedBranchOffice)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'La sede ha sido actualizada',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('setBranchOffice', response.data.data)
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            })
            commit('BranchOfficeError', error.message)
        })
}

//Eliminar sucursal
export async function removeBranchOffice({commit, dispatch}, branchOffice) {

    $http.delete(`/api/sedes/${branchOffice.id}`)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'La sede ha sido eliminada',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            dispatch('fetchBranchOffices')
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('BranchOfficeError', e.message)
        })
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter) {
    commit('setFilter', filter.target.value)
    dispatch('fetchBranchOffices')
}

export function setPerPagePagination({commit, dispatch}, perPage) {
    commit('setPerPage', parseInt(perPage.target.value))
    dispatch('fetchBranchOffices')
}

export function setPagePagination({commit, dispatch}, page) {
    commit('setPage', parseInt(page))
    dispatch('fetchBranchOffices')
}

export function setSortByPagination({commit, dispatch}, sortBy) {
    commit('setSortBy', sortBy)
    // dispatch('fetchBranchOffices')
}

export function setSortPagination({commit, dispatch}, sort) {
    commit('setSort', sort)
    // dispatch('fetchBranchOffices')
}

// Obtiene los departamentos
export async function fetchDepartments({dispatch, state, rootState, commit}) {
    if (!state.departments.length) {
        await dispatch('departments/fetchDepartments', {}, {root: true})
        commit('setDepartments', rootState.departments.departments)
    }
}

// Obtiene las ciudades
export async function fetchCities({dispatch, state, rootState, commit}) {
    let id = state.selectedBranchOffice.city.department_id
    if (id) {
        await dispatch('cities/fetchCities', {params: {department_id: id}}, {root: true})
        commit('setCities', rootState.cities.cities)
    }
}

//------------------------ PESTAÑA BODEGAS------------------------//

export function addWareHouse({state, commit}) {
    let warehouses = state.selectedBranchOffice.warehouses
    state.selectedWarehouse.action = 'c'
    warehouses.push(state.selectedWarehouse)
    commit('setWarehouses', warehouses)
}

export function updateWareHouse({state, commit}) {
    let warehouses = state.selectedBranchOffice.warehouses.map((wh) => {
        if (wh.code == state.selectedWarehouse.warehouse_code) {
            if (wh.action != 'c') {
                state.selectedWarehouse.action = 'u'
            }
            return state.selectedWarehouse
        } else {
            return wh
        }
    })
    commit('setWarehouses', warehouses)

}

export function deleteWareHouse({state, commit}, warehouse) {
    let warehouses = state.selectedBranchOffice.warehouses.map((wh) => {
        if (wh.warehouse_code == warehouse.warehouse_code) {
            wh.action = 'd'
        }
        return wh
    })
    commit('setWarehouses', warehouses)

}

