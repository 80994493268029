export const defaultSelectedHome = () =>{
    return{
        search:'',
        reference:''
    }
}
export default {
    selectedHome: defaultSelectedHome(),
    banners: [],
    brands:[],
    products:[],
	error: false,
    errorMessage: '',
    prods: [],
    salesBudget:[],
    params: {
        idParameter:20,
        contact_id: null,
        lines:''
    },
    modalService:{
        show:false
    },
    modalSales:{
        show:false
    }
}
