export default {
	cartInitial:[],
	cart: {
        documents_product: [],
		documents_information: {
			price_list: ''
		},
        price_lists: []
	},
	total: 0,
	pdf: '',
	show_pdf: false,
	error: false,
	errorMessage: '',
	last_cart: {
		parameter: {
			name_parameter: ''
		}
	},
    price_list_config: {

    },
    contactPropartes:{
	    minimum_order_value: 600000
    }
}
