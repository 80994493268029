export const defaultSelectedVouchersType = () => {
	return {
		branchoffice_id:'',
		code_voucher_type:'',
		name_voucher_type:'',
		short_name:'',
		prefix:'',
		consecutive_number:'',
		electronic_bill:0,
		resolution:'',
		affectation:'',
		state:1,
		only_consecutive:0,
	}
}
export default {
	vouchersTypes: [],
	selectedVouchersType: defaultSelectedVouchersType(),
	error: false,
	errorMessage: '',
	action: 'create',
	branchOffices:[],

	getVouchesTypeEdit: true,


	params: {
		paginate: true,
		page: 1,
		perPage: 15,
		filter: '',
		sortBy: 'code_voucher_type',
		sort: 'ASC'
	}
}
