import $http from '../../axios-intance';
import {Notification} from 'element-ui';

export async function getReceivables({commit, state}, requestParameters = {params: state.params}) {
    commit('setPreloader', true);
    await $http.get('/api/cartera-vendedor/', requestParameters)
        .then((res) => {
            commit('setReceivables', res.data.data);
        }).catch((error) => {
            Notification.error({
                title: '¡Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box'
            });
            commit('receivableError', error.message);
        });
    commit('setPreloader', false);
}

export function setFilterContact({commit, dispatch, state}) {
    commit('setFilterWarehouse', state.params.filter_name);
    dispatch('getReceivables');
}

export function setFilterDate({commit, dispatch}, filter) {
    commit('setFilterDay', filter.target.value);
    dispatch('getReceivables');
}

export function setPerPagePagination({commit, dispatch}, perPage) {
    commit('setPerPage', parseInt(perPage.target.value));
    dispatch('getReceivables');
}

export async function setPagePagination({commit, dispatch}, page) {
    commit('setPage', parseInt(page));
    await dispatch('getReceivables');
}

export async function getSellers({commit, state}) {
    let leadership_id = state.params.leadership_id;

    commit('setSellerIdParams', null);

    if (leadership_id === null) {
        await $http.get('/api/sellers').then((response) => {
            commit('setSellers', response.data.data);
        });

    } else {
        await $http.get('/api/sellers/' + leadership_id).then((response) => {
            commit('setSellers', response.data.data);
        });
    }
}


export async function getLeaderships({commit, state}) {
    await $http.get('/api/leaderships').then((response) => {
        commit('setLeaderships', response.data.data);
    });
}
