import { defaultSelectedParamTable, defaultSelectedParameter  } from "./state";

export function setParamTables(state, paramTables){
    state.paramTables = paramTables;
}

export function setParamTable(state, paramTable){
    state.selectedParamTable = paramTable;
}
export function setAction(state, action){
    state.action = action;
}

export function paramTableError(state, payload){
    state.error = true
    state.errorMessage = payload
    state.paramTable = []
}

export function setGetParamTableEdit(state, getParamTableEdit) {
    state.getParamTableEdit = getParamTableEdit;
}

export function resetSelectedParamTable(state) {
    let id = state.selectedParamTable.id
    Object.assign(state.selectedParamTable, defaultSelectedParamTable())
    state.selectedParamTable.id = id
}

export function setParameters(state, parameters){
    state.parameters = parameters;
}
export function parameterError(state, payload){
    state.error = true
    state.errorMessage = payload
}

export function resetSelectedParameter(state) {
    let id = state.selectedParameter.id
    Object.assign(state.selectedParameter, defaultSelectedParameter())
    if(id){
        state.selectedParameter.id = id
    }
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter){
  state.params.page = 1
  state.params.filter = filter
}

export function setPerPage(state, perPage){
  state.params.page = 1
  state.params.perPage = perPage
}

export function setPage(state, page){
  state.params.page = page
}

export function setSortBy(state, sortBy){
  state.params.sortBy = sortBy
}

export function setSort(state, sort){
  state.params.sort = sort
}
