export function setRoles(state, roles) {
	state.roles = roles;
}

export function setPermissions(state, permissions) {
    permissions.map(permission => {
        permission.checked = false
    })
    state.permissions = permissions;
}

export function setPermissionsAssigned(state, permissions) {
    state.permissions.map(state_permission => {
        let assigned = false
        permissions.map(permission => {
            if (permission.id === state_permission.id){
                state_permission.checked = true
                assigned = true
            }
        })
        if (!assigned){
            state_permission.checked = false
        }
    })
}
