export const deepeningConfiguration = () => {
    return {
        id: null,
        quantity: null,
    }
}

export default {
    deepening_configurations: null,
    deepening_configuration: deepeningConfiguration(),
    preloader: false
}
