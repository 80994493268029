export function setQuotations (state, quotations) {
	state.quotations = quotations
}

export function setFilterDate(state, date) {
	state.params.filter_date = date
}

export function setFilterStatus(state, status) {
	state.params.filter_status = status
}

export function setFilter (state, filter) {
	state.params.page = 1
	state.params.filter = filter
}

export function setPerPage(state, perPage) {
	state.params.page = 1
	state.params.perPage = perPage
}

export function setPage (state, page) {
	state.params.page = page
}

export function setSortBy (state, sortBy) {
	state.params.sortBy = sortBy
}

export function setSort (state, sort) {
	state.params.sort = sort
}

export function setSellers (state, sellers) {
	state.sellers = sellers
}

export function setPreloader (state, preloader) {
    state.preloader = preloader
}

export function setLeaderships (state, leaderships) {
	state.leaderships = leaderships
}

export function setLeadershipIdParams(state, user_id) {

    let leadership = state.leaderships.find(l => l.user_id === user_id);

    if (leadership) {
        state.params.leadership_id = [leadership.id];
    }
}


export function setSellerIdParams(state, seller_id) {
    state.params.seller_id = seller_id;
}

