import $http from "../../axios-intance";
import { Notification } from 'element-ui';
//Obtener los tipos de comprobantes
export async function fetchVouchersTypes({commit, state}, requestParameters = {params: state.params}) {
	await $http.get('/api/tipos-de-comprobantes', requestParameters)
	.then((response) => {
		commit('setVouchersTypes', response.data.data)
	})
	.catch((error) => {
		Notification.error({
			title: 'Error!',
			message:  error.message,
			type: 'error',
			duration: 2000,
			customClass: 'notification-box',
		});
		commit('VouchersTypeError', error.message)
	})
}

//Crear tipo de comprobante
export async function addVouchersType({commit, state, dispatch}) {
	$http.post('/api/tipos-de-comprobantes', state.selectedVouchersType)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: ' El Tipo de Comprobante ha sido creado',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box',
		});
		dispatch('fetchVouchersTypes')
		commit('resetSelectedVouchersType')
		state.error = false
	})
	.catch((error) => {
		Notification.error({
			title: 'Error!',
			message:  error.message,
			type: 'error',
			duration: 2000,
			customClass: 'notification-box',
		});
		commit('VouchersTypeError', error.message)
	})
}

//Consultar tipo de comprobante por id
export async function getVouchersType({commit, state}, id) {
	if (state.getVouchesTypeEdit) {
		await $http.get(`/api/tipos-de-comprobantes/${id}`)
		.then((response) => {
			commit('setVouchersType', response.data.data)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message:  error.message,
				type: 'error',
				duration: 2000,
				customClass: 'notification-box',
			});
			commit('VouchersTypeError', error.message)
		})
		commit('setGetVouchersTypeEdit', false)
	}
}


//Actualizar tipo de comprobante
export async function updateVouchersType({commit, state, dispatch}) {
	await $http.put(`/api/tipos-de-comprobantes/${state.selectedVouchersType.id}`, state.selectedVouchersType)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: ' El Tipo de Comprobante ha sido actualizado',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box',
		});
		commit('setVouchersType',  response.data.data)
		dispatch('fetchVouchersTypes')
	})
	.catch((error) => {
		Notification.error({
			title: 'Error!',
			message:  error.message,
			type: 'error',
			duration: 2000,
			customClass: 'notification-box',
		})
		commit('VouchersTypeError', error.message)
	})
}


//Eliminar tipo de comprobante
export async function removeVouchersType({commit, dispatch}, vouchersType) {
	$http.delete(`/api/tipos-de-comprobantes/${vouchersType.id}`)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: ' El Tipo de Comprobante ha sido eliminado',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box',
		});
		dispatch('fetchVouchersTypes')
	})
	.catch((error) => {
		Notification.error({
			title: 'Error!',
			message:  error.message,
			type: 'error',
			duration: 2000,
			customClass: 'notification-box',
		});
		commit('VouchersTypeError', error.message)
	})
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter){
	commit('setFilter', filter.target.value)
	dispatch('fetchVouchersTypes')
}

export function setPerPagePagination({commit, dispatch}, perPage){
	commit('setPerPage', parseInt(perPage.target.value))
	dispatch('fetchVouchersTypes')
}

export function setPagePagination({commit, dispatch}, page){
	commit('setPage', parseInt(page))
	dispatch('fetchVouchersTypes')
}

export function setSortByPagination({commit, dispatch}, sortBy){
	commit('setSortBy', sortBy)
	// dispatch('fetchVouchersTypes')
}

export function setSortPagination({commit, dispatch}, sort){
	commit('setSort', sort)
	// dispatch('fetchVouchersTypes')
}

export async function fetchBranchOffices({dispatch, state, rootState, commit}) {
	if (!state.branchOffices.length) {
		await dispatch('branchOffices/fetchBranchOffices', {}, {root: true})
		commit('setBranchOffices', rootState.branchOffices.branchOffices)
	}
}