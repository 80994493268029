import moment from 'moment';

export default {
	quotations: {data:[]},
	params: {
		page: 1,
		filter_date: [
            moment().add(-1, 'months').add(1, 'days').toDate(),
            moment().toDate()
        ],
		contact_id: null,
		warehouse_id: null
	},
}
