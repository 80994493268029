import {defaultSelectedSubcategory} from "./state";

export function setSubcategories(state, subcategories) {
    state.subcategories = subcategories;
}

export function setSubcategory(state, subcategory) {
    state.selectedSubcategory = JSON.parse(JSON.stringify(subcategory));
}

export function setActionSubcategories(state, action) {
    state.actionSubcategory = action;
}

export function subcategoryError(state, error) {
    state.error = true
    state.errorMessage = error
}

//Establece el valor por defecto de selectedSubcategory
export function resetSelectedSubcategory(state) {
    let id = state.selectedSubcategory.id
    Object.assign(state.selectedSubcategory, defaultSelectedSubcategory())
    if (id) {
        state.selectedSubcategory.id = id
    }
}
