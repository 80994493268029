export const reason = () => {
    return {
        id: null,
        code_parameter: null,
        name_parameter: null,
        reason_type_id: null,
        reason_type: null
    };
};

export default {
    reasons: [],
    reason: reason(),
    modal: {show: false},
    editing: false,
    params: {
        filter: ''
    },
    reason_types: [
        {id: 1, type: 'APROBACIÓN'},
        {id: 2, type: 'RECHAZO'}
    ]
};
