export function setCities(state, cities){
    state.cities = cities;
}

export function setCity(state, city){
    state.selectedCity = city;
}

export function cityError(state, payload){
    state.error = true
    state.error = errorMessage = payload
    state.todo = []
}
