export function setSales(state, sales) {
    state.sales = sales;
}

export function setLoading(state, loading) {
    state.loading = loading;
}

/**
 * Establecer un valor para el atributo seller_id de la variable params.
 */
export function setSellerIdToParams(state, seller_id) {
    state.params.seller_id = seller_id;
}

/**
 * Establecer un valor para el atributo leadership_id de la variable params.
 */
export function setLeadershipIdToParams(state, user_id) {

    let leadership = state.leaderships.find(l => l.user_id === user_id);

    if (leadership) {
        state.params.leadership_id = leadership.id;
    }
}

/**
 * Establecer un valor para la variable leaderships.
 */
export function setLeaderships(state, leaderships) {
    state.leaderships = leaderships;
}

