import { defaultSelectedBanner } from './state'

export function setBanners(state, banners) {
    state.banners = banners
}
export function setBrands(state, brands) {
    state.brands = brands.map(p => {
        return {value:p.id, brand: p.description, img:p.image}
    })
}

export function setProds(state, prods) {
	state.prods = prods
}

export function setModalServiceShow(state, modalServiceShow) {
    state.modalService.show = modalServiceShow
}

export function setModalSalesShow(state, modalSalesShow) {
    state.modalSales.show = modalSalesShow
}
export function setSalesBudgetShow(state, salesBudget) {

    salesBudget.map(s => {
        s.viewDetail = false
    })

    state.salesBudget = salesBudget
}

/* export function setBanner (state, banner) {
	state.selectedBanner = banner
}

export function bannerError (state, payload) {
	state.error = true
	state.errorMessage = payload
	state.banners = []
}

export function setLines(state, lines) {
	state.lines = lines.map(p => {
		return {value: p.id, label: p.line_description}
	})
}


 */
