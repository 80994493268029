import $http from '../../../axios-intance'
import {Notification} from 'element-ui'

export async function getProduct({commit, state, rootState}, product_id, requestParameters = {params: state.params}) {
    await $http.get('/api/calcular-precio/' + rootState.login.user_b2b.id + '/' + product_id)
        .then((response) => {
            commit('setProduct', response.data.data)
        }).catch((error) => {
        })
}

export async function getrelated({commit, state, rootState}, id) {

    let params = {
        params: {
            price_list_id: rootState.login.user_b2b.price_list_id,
            warehouse: rootState.login.user_b2b.id,
            line: rootState.login.user_b2b.line.line.line_code
        }
    }

    await $http.get(`/api/relacionados/${id}`, params)
        .then((response) => {
            commit('setRelated', response.data.data)
        }).catch((error) => {
            Notification.error({
                title: '¡Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            })
            commit('productError', error.message)
        })
}

export async function fetchBrand({dispatch, state, rootState, commit}) {
    if (!state.brands.length) {
        await dispatch('brands/fetchBrands', {params: {paginate: false}}, {root: true})
        commit('setBrands', rootState.brands.brands)
    }
}

export async function fetchCategories({dispatch, state, rootState, commit}) {
    if (!state.categories.length) {
        await dispatch('categories/fetchCategories', {params: {paginate: true, line: true}}, {root: true})
        commit('setCategories', rootState.categories.categories)
    }
}

export async function fetchSublines({dispatch, state, rootState, commit}) {
    if (!state.groups.length) {
        await dispatch('sublines/listSublines', {params: {line: true}}, {root: true})
        commit('setGroups', rootState.sublines.sublines)
    }
}


//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter) {
    commit('setFilter', filter.target.value)
    dispatch('fetchProducts')
}

export function setPerPagePagination({commit, dispatch}, perPage) {
    commit('setPerPage', parseInt(perPage.target.value))
    dispatch('fetchProducts')
}

export function setPagePagination({commit, dispatch}, page) {
    commit('setPage', parseInt(page))
    dispatch('fetchProducts')
}

export function setSortByPagination({commit, dispatch}, sortBy) {
    commit('setSortBy', sortBy)
    // dispatch('fetchProducts')
}

export function setSortPagination({commit, dispatch}, sort) {
    commit('setSort', sort)
    // dispatch('fetchProducts')
}
