import {defaultAuth, defaultUserB2b, defaultUserLogued} from './state';

export function login(state, auth) {
    state.user_logued = auth.user;
    state.status = true;
}

export function loginFail(state, error) {
    state.user = false;
}

export function logout(state) {
    state.user_logued = JSON.parse(JSON.stringify(defaultUserLogued()));
    state.user_b2b = JSON.parse(JSON.stringify(defaultUserB2b()));
    state.auth = defaultAuth();
    state.status = false;
}

export function setUserLogued(state, user_logued) {
    state.user_logued = user_logued;
}

export function setUserLoguedB2b(state, user_b2b) {
    state.user_b2b = user_b2b;
}

export function setForgotPassword(state, forgot) {
    forgot.password = '';
    forgot.password_confirmation = '';
    state.forgot = forgot;
}

export function setLoguedType(state, logued_type) {
    state.logued_type = logued_type;
}

export function setLowerCaseAuthEmail(state) {
    state.auth.email = state.auth.email.toLowerCase();
}

export function setPermissions(state, permissions) {
    state.permissions = permissions;
    localStorage.setItem('permissions', JSON.stringify(permissions));
}

export function resetAuth(state) {
    state.auth = defaultAuth();
}

export function setSubscriber(state, subscriber) {
    state.subscriber = subscriber;
}

/**
 * Establecer un valor para el atributo number_bearing_activities de la variable subscriber.
 */
export function setNumberBearingActivitiesToSubscriber(state, number_bearing_activities) {
    state.subscriber.number_bearing_activities = number_bearing_activities;
}

/**
 * Establecer un valor para el atributo minutes_bearing de la variable subscriber.
 */
export function setMinutesBearingToSubscriber(state, minutes_bearing) {
    state.subscriber.minutes_bearing = minutes_bearing;
}

/**
 * Establecer un valor para la variable show_alert_contact.
 */
export function setShowAlertContact(state, show_alert_contact) {
    state.show_alert_contact = show_alert_contact;
}

/**
 * Establecer un valor para la variable potential_client_prospect.
 */
export function setPotentialClientProspect(state, potential_client_prospect) {
    state.potential_client_prospect = potential_client_prospect;
}
