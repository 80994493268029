export const defaultDates = () => {

    const end = new Date();
    const start = new Date();

    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);

    return [formatDate(start), formatDate(end)]

}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


export default {

    brands: [],
    sellers: [],
    contacts: [],
    params: {
        dates: defaultDates(),
        lines: '',
        brands: [],
        seller_id: null,
        contact_id: null
    }

}
