export const defaultSelectedUser = () => {
	return {
		name: '',
		surname: '',
		email: '',
		photo: '',
		password: 'secret',
		modules: '',
		module_id: '',
		session_id: '',
		subscribers: '',
		branchoffice_warehouse_id: '',
		subscriber_id: '',
		branchoffice_id: '',
		contact_id: '',
		clerk_id: '',
		type: 'e',
        deleted_at: 0,
        roles:[],
		contact_warehouse_id: '',
		role: '',
        mail_list: []
	}
}

export default {
	users: [],
    selectedUser: defaultSelectedUser(),
    roles:[],
	action: 'create',
	error: false,
	errorMessage: '',
	getUserEdit: true,
	branchoffices: [],
	subscribers: [],
	subscribers_filtered: [],
	branchoffice_warehouses: [],
	modules: [],
	modules_filtered: [],
	contacts: [],
	grouped_contacts: [],
	clerks: [],
	disabledClerk: true,
	contact : null,
	contacts_warehouses: [],
	findForId: false,
	clearDataUser: true,
	params: {
		paginate: true,
		page: 1,
		perPage: 15,
		filter: '',
		sortBy: 'name',
		sort: 'ASC'
	}
}
