import $http from '../../axios-intance';
import {Notification} from 'element-ui';

export async function fetchCustomers({commit, state}, requestParameters = {params: state.params}) {
    await $http.get('/api/establecimientos-vendedor', requestParameters).then((response) => {
        commit('setCustomers', response.data.data);
    }).catch((error) => {
        commit('ecommerceError', error.message);
    });
}

export async function fetchListsPrices({state, commit}) {
    if (!state.list_prices.length) {
        await $http.get(`/api/parametros?paramtable_id=14`)
            .then((response) => {
                commit('setListPrices', response.data.data);
            })
            .catch((error) => {
                commit('parameterError', error.message);
            });
    }
}

export async function changePriceList({state, commit}) {
    await $http.post(`/api/cliente-prospecto/lista-de-precios`, state.selectedCustomer)
        .then((response) => {
            commit('setModalPriceList', false);
            Notification.success({
                title: 'Muy bien!',
                message: 'Lista de precios actualizada correctamente',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: 'Error al actualizar la lista de precios',
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
        });
}

export async function loginB2b({commit, state}) {
    localStorage.setItem('user_b2b', JSON.stringify(state.selectedCustomer));
    commit('login/setUserLoguedB2b', state.selectedCustomer, {root: true});
}

export async function runGetTypeIdentification({commit, state}) {
    await $http.get('api/extra-data/type-identification')
        .then(response => {
            return response.data;
        })
        .then(response => {
            commit('getTypeIdentification', response[0].extra_parameters);
        })
        .catch(error => {
            Notification.error({
                title: '¡Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box'
            });
            commit('ecommerceError', error.message);
        });
}

export function setFilterPagination({commit, dispatch}, filter) {
    commit('setFilter', filter.target.value);
    dispatch('fetchCustomers');
}

export function setPerPagePagination({commit, dispatch}, perPage) {
    commit('setPerPage', parseInt(perPage.target.value));
    dispatch('fetchCustomers');
}

export function setPagePagination({commit, dispatch}, page) {
    commit('setPage', parseInt(page));
    dispatch('fetchCustomers');
}

export function setSortByPagination({commit, dispatch}, sortBy) {
    commit('setSortBy', sortBy);
    // dispatch('fetchLicenses')
}

export function setSortPagination({commit, dispatch}, sort) {
    commit('setSort', sort);
}

/** Eliminar cliente prospecto */
export async function removeContact({commit, dispatch}, params) {

    await $http.delete(`/api/establecimientos/${params.params.id}`)
        .then((response) => {
            Notification.success({
                title: 'Éxito!',
                message: 'El establecimiento ha sido eliminado',
                type: 'success',
                duration: 1500,
                customClass: 'notification-box',
            });
            dispatch('fetchCustomers', params);
        })
        .catch((error) => {
            Notification.error({
                title: '¡Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('ecommerceError', error.message);
        });
}
