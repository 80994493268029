export const defaultSelectedBanner = () => {
	return {
		line_id: '',
		parameter_id: '',
		name: '',
		url_big_banner: null,
		big_banner: null,
		url_small_banner: null,
		small_banner: null,
		since: '',
		until: '',
		state: false,
		range_date: []
	}
}

export default {
	banners: [],
	selectedBanner: defaultSelectedBanner(),
	getBannerEdit: true,
	error: false,
	errorMessage: '',
	action: 'create',
	range_date: [],
	lines: [],
	parameters: [],
	params: {
		pagination: true,
		page: 1,
		perPage: 15,
		filter: '',
		sortBy: 'until',
		sort: 'DESC',
		bannerSize: 'big'
	}
}
