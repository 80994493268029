export const defaultSelectedBranchOffice = () => {
    return {
        subscriber_id:1,
        name:'',
        address:'',
        telephone:'',
        fax:'',
        main:0,
        city_id:'',
        latitude:'',
        length:'',
        branchoffice_type:0,
        warehouses: [],
        city: {
            department_id: ""
        }
    }
}

export const defaultSelectedWarehouse = () => {
    return {
        warehouse_code: '',
        warehouse_description: '',
        warehouse_status: 1,
        update_inventory: 0,
        exits_no_exist: 0,
        negative_balances: 0,
        last_date_physical_inventory: '',
        number_physical_inventory: '',
        action: 'n'
    }
}


export default {
    branchOffices: [],
    selectedBranchOffice: defaultSelectedBranchOffice(),
    selectedWarehouse: defaultSelectedWarehouse(),
    error: false,
    errorMessage: '',
    action: 'create',
    actionWarehouse: 'create',

    departments: [],
    cities: [],

    getBranchOfficeEdit: true,

    params: {
        paginate: true,
        page: 1,
        perPage: 15,
        filter: '',
        sortBy: 'id',
        sort: 'ASC'
    }

}
