export const data = () => {
    return {
        file: null,
        disable_incorporation: 1,
        only_validation: 1
    }
}

export default {
    preloader: false,
    data: data()
}
