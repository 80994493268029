import {defaultSelectedSubbrand} from "./state";

export function setSubbrands(state, subbrands) {
    state.subbrands = subbrands;
}

export function setSubbrand(state, subbrand) {
    state.selectedSubbrand = JSON.parse(JSON.stringify(subbrand));
}

export function setActionSubbrands(state, action) {
    state.actionSubbrand = action;
}

export function subbrandError(state, error) {
    state.error = true
    state.errorMessage = error
}

//Establece el valor por defecto de selectedSubbrand
export function resetSelectedSubbrand(state) {
    let id = state.selectedSubbrand.id
    Object.assign(state.selectedSubbrand, defaultSelectedSubbrand())
    if (id) {
        state.selectedSubbrand.id = id
    }
}

export function setImagenCarga(state, image) {
    state.selectedSubbrand.image = image
}

export function setDocumentPath(state, data_sheet) {
    state.selectedSubbrand.data_sheet = data_sheet;
}
