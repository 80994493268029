export default {
	counting: false,
	time: 0,
	brand: [],
	related: [],
	product: [],
	group: [],
	category: [],
	imagen: '',
	error: false,
	errorMessage: '',
	productsForm: [],
	params: {
		price_list_id: 0
	}
}