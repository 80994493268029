export default{
    customers: [],
	coordinates:[],
	coordinatesMarker:[],
	// coordinatesSeller:[],
	sellers:[],
    coordinatesVisit:[],
    params:{
        id:'',
        date:''
    },
    lines:[]
}
