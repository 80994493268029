export const defaultSelectedParamTable = () => {
    return {
        subscriber_id:'',
        code_table:'',
        name_table:'',
        desc_code_parameter:'Código',
        desc_name_parameter:'Descripcion',
        desc_alphanum_data_first:'',
        desc_alphanum_data_second:'',
        desc_alphanum_data_third:'',
        desc_numeric_data_first:'',
        desc_numeric_data_second:'',
        desc_numeric_data_third:'',
        desc_date_data_first:'',
        desc_date_data_second:'',
        desc_date_data_third:'',
        desc_image:'',
        observation:'',
        protected:0
    }
}

export const defaultSelectedParameter = () => {
    return{
        code_parameter:'',
        name_parameter:'',
        alphanum_data_first:'',
        alphanum_data_second:'',
        alphanum_data_third:'',
        numeric_data_first:'',
        numeric_data_second:'',
        numeric_data_third:'',
        date_data_first:'',
        date_data_second:'',
        date_data_third:'',
        image:''
    }
}
export default {
    paramTables: [],
    selectedParamTable: defaultSelectedParamTable(),
    error: false,
    errorMessage: '',
    action: 'create',
    paramTableEdit: null,

    getParamTableEdit:true,
    params: {
        paginate: true,
        page: 1,
        perPage: 15,
        filter: '',
        sortBy: 'code_table',
        sort: 'ASC'
    },

    parameters: [],
    selectedParameter: defaultSelectedParameter(),
    error: false,
    errorMessage: '',
    actionParameter: 'create',

    getParameterEdit: true,
}

/* export default {
    parameters: [],
    selectedParameter: defaultSelectedParameter(),
    error: false,
    errorMessage: '',
    actionParameter: 'create',

    getParameterEdit: true,

} */

