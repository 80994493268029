import axios from 'axios'
import $http from '../../axios-intance';
import {Notification, MessageBox} from 'element-ui';

//Obtener los terceros
export async function fetchContacts({commit, state}, requestParameters = {params: state.params}) {

	await $http.get('/api/terceros' , requestParameters )
		.then((response) => {

			commit('setContacts', response.data.data)

		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message: error.message,
				type: 'error',
				duration: 1500,
				customClass: 'notification-box',
			});
			commit('contactError', error.message)
		})
}

//Crear tercero
export async function addContact({commit, state}) {

	await $http.post('/api/terceros', state.selectedContact)
		.then((response) => {
			Notification.success({
				title: 'Exito!',
				message: 'El tercero ha sido creado',
				type: 'success',
				duration: 1500,
				customClass: 'notification-box',
			});
			commit('setContact', response.data.data)
			state.error = false
		})
		.catch((error) => {

			let message;

			if (error.response.status == 422) {
				message = "Algunos campos no se registraron correctamente"
			} else {
				message = error.message
			}

			MessageBox.alert(message, 'Error', {
				confirmButtonText: 'Aceptar',
				type: 'error'
			})

			commit('contactError', error.message)
		})
}

//Consultar tercero por id
export async function getContact({commit, state}, id) {

		await $http.get(`/api/terceros/${id}`)
			.then((response) => {
				commit('setContact', response.data.data)
			})
			.catch((error) => {
                console.error(error);
				Notification.error({
					title: 'Error!',
					message: error.message,
					type: 'error',
					duration: 1500,
					customClass: 'notification-box',
				});
				commit('contactError', error.message)
			})

}

//Consultar tercero por identificación
export async function getContactForIdentification({commit, state, rootState}, identification) {

	await $http.get(`/api/terceros/id/${identification}`)
		.then((response) => {
			if (response.data.success) {
				state.contactEdit = response.data.data.id
			}
		})
		.catch((error) => {
			state.contactEdit = null
			commit('contactError', error.message)
		})
}


//Actualizar tercero
export async function updateContact({commit, state}) {

	await $http.put(`/api/terceros/${state.selectedContact.id}`, state.selectedContact)
		.then((response) => {
			Notification.success({
				title: 'Exito!',
				message: 'El tercero ha sido actualizado',
				type: 'success',
				duration: 1500,
				customClass: 'notification-box',
			});
			commit('setContact', response.data.data)
		})
		.catch((error) => {
			let message;

			if (error.response.status == 422) {
				message = "Algunos campos no se registraron correctamente"
			} else {
				message = error.message
			}

			MessageBox.alert(message, 'Error', {
				confirmButtonText: 'Aceptar',
				type: 'error'
			})

			commit('contactError', error.message)
		})
}


//Eliminar tercero
export async function removeContact({commit, dispatch}, contact) {

	await $http.delete(`/api/terceros/${contact.id}`)
		.then((response) => {
			Notification.success({
				title: 'Exito!',
				message: 'El tercero ha sido eliminado',
				type: 'success',
				duration: 1500,
				customClass: 'notification-box',
			});
			dispatch('fetchContacts')
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message: error.message,
				type: 'error',
				duration: 1500,
				customClass: 'notification-box',
			});
			commit('ContactError', e.message)
		})
}


//Función para mapear los parametros y retornarlos en un mismo formato para todos los select
function mapParameters(parameters) {
	return parameters.map(p => {
		return {value: p.id, label: p.name_parameter}
	})
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter) {
	commit('setFilter', filter.target.value)
	dispatch('fetchContacts')
}

export function setPerPagePagination({commit, dispatch}, perPage) {
	commit('setPerPage', parseInt(perPage.target.value))
	dispatch('fetchContacts')
}

export function setPagePagination({commit, dispatch}, page) {
	commit('setPage', parseInt(page))
	dispatch('fetchContacts')
}

export function setSortByPagination({commit, dispatch}, sortBy) {
	commit('setSortBy', sortBy)
	// dispatch('fetchContacts')
}

export function setSortPagination({commit, dispatch}, sort) {
	commit('setSort', sort)
	// dispatch('fetchContacts')
}

//------------------------ PESTAÑA GENERAL------------------------//

// Obtiene los tipos de documento
export async function fetchDocumentTypes({dispatch, state, rootState, commit}) {
	if (!state.identificationTypes.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 1, paginate: false}}, {root: true})
		commit('setIdentificationTypes', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene las líneas
export async function fetchLines({dispatch, state, rootState, commit}) {
	if (!state.lines.length) {
		await dispatch('lines/fetchLines', {params: {idParamTable: 1, paginate: false}}, {root: true})
		commit('setLines', rootState.lines.lines)
	}
}

// function mapLines(lines) {
// 	return lines.map(p => {
// 		return {value: p.id, label: p.line_description}
// 	})
// }


export async function getTooltips({dispatch, state, rootState, commit}, id) {

	let parameter = state.parameters.find(p => p.paramtable_id == id);

	if (parameter.tooltip == '') {
		let getParamTableEdit = rootState.paramTables.getParamTableEdit
		commit('paramTables/setGetParamTableEdit', true, {root: true})
		await dispatch('paramTables/getParamTable', id, {root: true})
		commit('setTooltipParamTable', rootState.paramTables.selectedParamTable);
		commit('paramTables/setGetParamTableEdit', getParamTableEdit, {root: true})
	}
}

// Obtiene los departamentos
export async function fetchDepartments({dispatch, state, rootState, commit}) {
	if (!state.departments.length) {
		await dispatch('departments/fetchDepartments', {}, {root: true})
		commit('setDepartments', rootState.departments.departments)
	}
}

// Obtiene las ciudades
export async function fetchCities({dispatch, state, rootState, commit},payload) {

	let id = state.selectedContact.city.department_id

	if(payload)
	{
		if(payload.idDepart != '')
		{
			id = payload.idDepart
		}
	}

	if (id) {
		await dispatch('cities/fetchCities', {params: {department_id: id}}, {root: true})
		commit('setCities', rootState.cities.cities)
	}
}

// Obtiene los puntos de venta
export async function fetchPointsOfSale({dispatch, state, rootState, commit}) {
	if (!state.pointsOfSale.length) {
		/* await dispatch('', {params: {paginate: false}}, {root: true}) TODO: Determinar el id de los puntos de venta y el modulo
		commit('setPointsOfSale', ) */
		let pointsOfSale = [
			{value: 1, label: 'Punto de venta No. 1'},
			{value: 2, label: 'Punto de venta No. 2'}
		]
		commit('setPointsOfSale', pointsOfSale)
	}
}

// Obtiene las clases de personas
export async function fetchPersonClasses({dispatch, state, rootState, commit}) {
	if (!state.personClasses.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 2, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setPersonClasses', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene los tipos de contribuyentes
export async function fetchTaxpayerTypes({dispatch, state, rootState, commit}) {
	if (!state.taxpayerTypes.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 4, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setTaxpayerTypes', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene los regimenes tributarios
export async function fetchTaxRegimes({dispatch, state, rootState, commit}) {
	if (!state.taxRegimes.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 3, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setTaxRegimes', mapParameters(rootState.parameters.parameters))
	}
}

//------------------------ PESTAÑA CLIENTES------------------------//

// Obtiene las categorías de clientes
export async function fetchCategoriesCustomers({dispatch, state, rootState, commit}) {
	if (!state.categoriesCustomers.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 5, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setCategoriesCustomers', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene los vendedores de clientes
export async function fetchSellersCustomers({dispatch, state, rootState, commit}) {
	if (!state.sellersCustomers.length) {
		/* await dispatch('', {params: {paginate: false}}, {root: true}) TODO: Determinar el id de los vendedores y el modulo
		commit('setSellersCustomers', ) */
		let sellersCustomers = [
			{value: 1, label: 'Vendedor No. 1'},
			{value: 2, label: 'Vendedor No. 2'}
		]
		commit('setSellersCustomers', sellersCustomers)
	}
}

// Obtiene las listas de precios de clientes
export async function fetchPriceListsCustomers({dispatch, state, rootState, commit}) {
	if (!state.priceListsCustomers.length) {
		await dispatch('parameters/fetchListsPrices', { params: { paginate:false } }, { root: true })
		commit('setPriceListsCustomers', rootState.parameters.parameters)
	}
}

export function logoChangeCustomer({commit}, logo) {
	commit('setLogoCustomer', logo)

}

//------------------------ PESTAÑA PROVEEDORES------------------------//

// Obtiene las categorías de proveedores
export async function fetchCategoriesProviders({dispatch, state, rootState, commit}) {
	if (!state.categoriesProviders.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 7, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setCategoriesProviders', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene los vendedores de proveedores
export async function fetchSellersProviders({dispatch, state, rootState, commit}) {
	if (!state.sellersProviders.length) {
		/* await dispatch('', {params: {paginate: false}}, {root: true}) TODO: Determinar el id de los vendedores y el modulo
		commit('setSellersProviders', ) */
		let sellersProviders = [
			{value: 1, label: 'Vendedor No. 1'},
			{value: 2, label: 'Vendedor No. 2'}
		]
		commit('setSellersProviders', sellersProviders)
	}
}

// Obtiene las listas de precios de proveedores
export async function fetchPriceListsProviders({dispatch, state, rootState, commit}) {
	if (!state.priceListsProviders.length) {
		/* await dispatch('', {params: {paginate: false}}, {root: true}) TODO: Determinar el id de los vendedores y el modulo
		commit('setPriceListsProviders', ) */
		let priceListsProviders = [
			{value: 1, label: 'Lista de precios No. 1'},
			{value: 2, label: 'Lista de precios No. 2'}
		]
		commit('setPriceListsProviders', priceListsProviders)
	}
}

// Obtiene los bancos de pago
export async function fetchPaymentBanks({dispatch, state, rootState, commit}) {
	if (!state.paymentBanks.length) {
		/* await dispatch('', {params: {paginate: false}}, {root: true}) TODO: Determinar el id de los bancos y el modulo
		commit('setPaymentBanks', ) */
		let paymentBanks = [
			{value: 1, label: 'Banco de Bogotá'},
			{value: 2, label: 'Bancolombia'}
		]
		commit('setPaymentBanks', paymentBanks)
	}
}

export function logoChangeProvider({commit}, logo) {
	commit('setLogoProvider', logo)
}

//------------------------ PESTAÑA EMPLEADOS------------------------//

// Obtiene las categorías de empleados
export async function fetchCategoriesEmployees({dispatch, state, rootState, commit}) {
	if (!state.categoriesEmployees.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 8, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setCategoriesEmployees', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene los centros de costos
export async function fetchCostCenters({dispatch, state, rootState, commit}) {
	if (!state.costCenters.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 9, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setCostCenters', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene los cargos
export async function fetchPositions({dispatch, state, rootState, commit}) {
	if (!state.positions.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 2, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setPositions', mapParameters(rootState.parameters.parameters))
	}
}

export function photoChangeEmployee({commit}, photo) {
	commit('setPhotoEmployee', photo)
}

// Obtiene los códigos de ICA
export async function fetchIcaCodes({dispatch, state, rootState, commit}) {
	if (!state.icaCodes.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 2, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setIcaCodes', mapParameters(rootState.parameters.parameters))
	}
}


//-----------------lista de contactos-------//
export async function listContact({commit, state}, requestsParameters = {params: state.params}) {
	await $http.get(`/api/terceros`, requestsParameters)
		.then((response) => {
			commit('setContacts', response.data.data)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message:  error.message,
				type: 'error',
				duration: 2000,
				customClass: 'notification-box',
			});
		commit('ContactError', e.message)
	})
}

export async function fetchCustomerTypes({commit, state}) {
    await $http.get('/api/parametros-por-codigo/PCO-C')
        .then((response) => {
            commit('setCustomerTypes', response.data.data);
        })
        .catch((error) => {

        });
}

export async function fetchOperationsCenter({commit, state}) {
    await $http.get('/api/parametros-por-codigo/COS-C')
        .then((response) => {
            commit('setOperationsCenter', response.data.data);
        })
        .catch((error) => {

        });
}

export async function fetchZones({commit, state}) {
	await $http.get('/api/parametros-por-codigo/ZON-C')
		.then((response) => {
			commit('setZones', response.data.data);
		})
		.catch((error) => {

		});
}
