import $http from "../../axios-intance";
import {Notification, MessageBox} from 'element-ui';

//Obtener los categorias
export async function fetchCategories({commit, state}, requestParameters = {params: state.params}) {

    await $http.get('/api/categorias', requestParameters)
        .then((response) => {
            commit('setCategories', response.data.data)
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('categoryError', error.message)
        })
}

//Crear tercero
export async function addCategory({commit, state}) {

    await $http.post('/api/categorias', state.selectedCategory)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'La categoria ha sido creada',
                type: 'success',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('setCategory', response.data.data)
            state.error = false
        })
        .catch((error) => {

            let message;

            if (error.response.status == 422) {
                message = 'El código de la categoria ya existe'
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: 'Aceptar',
                type: 'error',
                customClass:'notification-error'
            })

            commit('categoryError', error.message)
        })
}

//Consultar tercero por id
export async function getCategory({commit, state}, id) {

    if (state.getCategoryEdit) {

        await $http.get(`/api/categorias/${id}`)
            .then((response) => {
                commit('setCategory', response.data.data)
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box',
                });
                commit('categoryError', error.message)
            })

        commit('setGetCategoryEdit', false)

    }
}

//Actualizar tercero
export async function updateCategory({commit, state}) {

    await $http.put(`/api/categorias/${state.selectedCategory.id}`, state.selectedCategory)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'La categoria ha sido actualizada',
                type: 'success',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('setCategory', response.data.data)
        })
        .catch((error) => {
            let message;

            if (error.response.status == 422) {
                message = 'El código de la categoria ya existe'
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('categoryError', error.message)
        })
}


//Eliminar tercero
export async function removeCategory({commit, dispatch}, category) {

    await $http.delete(`/api/categorias/${category.id}`)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'La categoria ha sido eliminada',
                type: 'success',
                duration: 1500,
                customClass: 'notification-box',
            });
            dispatch('fetchCategories')
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('CategoryError', error.message)
        })
}
//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter) {
    commit('setFilter', filter.target.value)
    dispatch('fetchCategories')
}

export function setPerPagePagination({commit, dispatch}, perPage) {
    commit('setPerPage', parseInt(perPage.target.value))
    dispatch('fetchCategories')
}

export function setPagePagination({commit, dispatch}, page) {
    commit('setPage', parseInt(page))
    dispatch('fetchCategories')
}

export function setSortByPagination({commit, dispatch}, sortBy) {
    commit('setSortBy', sortBy)
    // dispatch('fetchCategories')
}

export function setSortPagination({commit, dispatch}, sort) {
    commit('setSort', sort)
    // dispatch('fetchCategories')
}


