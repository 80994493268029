import { defaultSelectedSubscriber } from "./state";

export function setSubscribers (state, subscribers) {
    state.subscribers = subscribers;
}

export function setSubscriber (state, subscriber) {
    state.selectedSubscriber = subscriber
}

export function setAction(state, action){
    state.action = action;
}

export function subscribersError (state, payload) {
    state.error = true
    state.errorMessage = payload
    state.subscribers = []
}

export function setGetSubscriberEdit(state, getSubscriberEdit) {
    state.getSubscriberEdit = getSubscriberEdit;
}


export function resetSelectedSubscriber(state) {
    let id = state.selectedSubscriber.id
    Object.assign(state.selectedSubscriber, defaultSelectedSubscriber())
    state.selectedSubscriber.id = id
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter){
    state.params.page = 1
    state.params.filter = filter
}

export function setPerPage(state, perPage){
    state.params.page = 1
    state.params.perPage = perPage
}

export function setPage(state, page){
    state.params.page = page
}

export function setSortBy(state, sortBy){
    state.params.sortBy = sortBy
}

export function setSort(state, sort){
    state.params.sort = sort
}

//-------------------Suscriptores---------------------//
export function setLicenses(state, licenses) {
    state.licenses = licenses.map(p => {
        return {value: p.id, label: p.license_code}
    })
}
// Establece un valor para identificationTypes
export function setIdentificationTypes(state, identificationTypes) {
    state.identificationTypes = identificationTypes
}

export function setPersonClasses(state, classPersons) {
    state.classPersons = classPersons
}

// Establece un valor para taxpayerTypes
export function setTaxpayerTypes(state, taxpayerTypes) {
    state.taxpayerTypes = taxpayerTypes
}

// Establece un valor para taxRegimes
export function setTaxRegimens(state, taxRegimens) {
    state.taxRegimens = taxRegimens
}

// Establece un valor para Tipo de empresa
export function setCompanyTypes(state, companyTypes) {
    state.companyTypes = companyTypes
}

export function setLogo(state, logo) {
    state.selectedSubscriber.logo = logo
}

export function setFirstLogo(state, first_logo) {
    state.selectedSubscriber.configuration.first_logo = first_logo
}

//----------------------------------Parametros----------------//
// Establece un valor para departments
export function setDepartments(state, departments) {
    state.departments = departments.map(p => {
        return {value: p.id, label: p.department}
    })
}

// Establece un valor para cities
export function setCities(state, cities) {
    state.cities = cities.map(p => {
        return {value: p.id, label: p.city}
    })
}

export function setTooltipParamTable(state, paramTable){
    state.parameters.map((p) => {
        if (p.paramtable_id == paramTable.id){
            p.tooltip = paramTable.code_table + ' - ' + paramTable.name_table
        }
    });

 }

