import $http from '../../axios-intance';
import {Notification} from 'element-ui';

export async function fetchQuotations({commit, state}, requestParameters = {params: state.params}) {
    commit('setPreloader', true);
    await $http.get('/api/cotizaciones', requestParameters).then((response) => {
        commit('setQuotations', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('error', error.message);
    });
    commit('setPreloader', false);
}

export async function quotationToCart({commit, state}, id) {
    await $http.post('/api/cotizacion-a-carrito/' + id).then((response) => {
        return true;
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });

    });
}

//Obtener los lineas
export async function reportGenerate({commit, state, dispatch}, requestParameters = {params: state.params}) {
    await $http.get('/api/reporte-pedidos', requestParameters)
        .then((response) => {
            dispatch('downloadDocument', response.data.data);
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: 'Error generando el informe',
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('lineError', error.message);
        });
}

export async function downloadDocument({}, documentObject) {
    await $http({
        url: '/storage/reports/' + documentObject,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentObject);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 3000,
            customClass: 'notification-box',
        });

    });
}

export function setFilterPagination({commit, dispatch}, filter) {
    commit('setFilter', filter.target.value);
    dispatch('fetchOrders');
}

export function setPerPagePagination({commit, dispatch}, perPage) {
    commit('setPerPage', parseInt(perPage.target.value));
    dispatch('fetchOrders');
}

export function setPagePagination({commit, dispatch}, page) {
    commit('setPage', parseInt(page));
    dispatch('fetchOrders');
}

export function setSortByPagination({commit}, sortBy) {
    commit('setSortBy', sortBy);
}

export function setSortPagination({commit}, sort) {
    commit('setSort', sort);
}

export async function getSellers({commit, state}) {
    commit('setSellerIdParams', null);

    let leadership_id = state.params.leadership_id;

    if (leadership_id === null) {
        await $http.get('/api/sellers').then((response) => {
            commit('setSellers', response.data.data);
        });
    } else {
        await $http.get('/api/sellers/' + leadership_id).then((response) => {
            commit('setSellers', response.data.data);
        });
    }
}

export async function getLeaderships({commit, state}) {
    await $http.get('/api/leaderships').then((response) => {
        commit('setLeaderships', response.data.data);
    });
}
