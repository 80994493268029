import $http from "../../axios-intance";
import { Notification,  MessageBox } from 'element-ui';

//Obtener los parametros de una tabla
export async function fetchParameters({commit, rootState}) {
	await $http.get(`/api/parametros?paramtable_id=${rootState.paramTables.selectedParamTable.id}`)
		.then((response) => {
			commit('setParameters', response.data.data)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message:  error.message,
				type: 'error',
				duration: 2000,
				customClass: 'notification-box',
			});
		commit('parameterError', error.message)
	})
}
//Crear parametro
export async function addParameter({commit, state, rootState, dispatch}) {

	state.selectedParameter.paramtable_id = rootState.paramTables.selectedParamTable.id

	await $http.post('/api/parametros', state.selectedParameter)
		.then((response) => {
			Notification.success({
				title: 'Exito!',
				message: 'Parámetro ha sido creado',
				type: 'success',
				duration: 2000,
				customClass: 'notification-box',
			});
			dispatch("fetchParameters")
			commit('resetSelectedParameter')
			state.error = false
		})
		.catch((error) => {
			let message;

			if (error.response.status == 422) {
				message = error.response.data.errors.code_parameter[0]
			} else {
				message = error.message
			}

			MessageBox.alert(message, 'Error', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: 'Aceptar',
				type: 'error',
				customClass:'notification-error'
			})
			commit('parameterError', error.message)
		})
}
export async function getParameter({commit, state}, id) {
	if (state.selectedParameter.id != id) {
		await $http.get(`/api/parametros/${id}`)
			.then((response) => {
				commit('setParameter', response.data.data)
			})
			.catch((error) => {
				Notification.error({
					title: 'Error!',
					message:  error.message,
					type: 'error',
					duration: 2000,
					customClass: 'notification-box',
				});
				commit('parameterError', error.message)
			})
	}
}

//Actualizar un parametro
export async function updateParameter({commit, state, dispatch, rootState}) {
	// state.selectedParameter.paramtable_id = rootState.paramTables.selectedParamTable.id
	await $http.put(`/api/parametros/${state.selectedParameter.id}`, state.selectedParameter)
		.then((response) => {
			Notification.success({
				title: 'Exito!',
				message: 'Parámetro ha sido actualizado',
				type: 'success',
				duration: 2000,
				customClass: 'notification-box',
			});
			commit('setActionParameters', 'create')
			commit('resetSelectedParameter')
			dispatch('fetchParameters')
			state.error = false
		})
		.catch((error) => {
			let message;
			if (error.response.status == 422) {
				message = error.response.data.errors.code_parameter[0]
			} else {
				message = error.message
			}

			MessageBox.alert(message, 'Error', {
				confirmButtonText: 'Aceptar',
				type: 'error'
			})
			commit('parameterError', error.message)
		})
}

//Eliminar un parametro
export async function removeParameter({commit, dispatch}, parameter) {
	await $http.delete(`/api/parametros/${parameter.id}`)
		.then((response) => {
			Notification.success({
				title: 'Exito!',
				message: 'Parámetro ha sido eliminado',
				type: 'success',
				duration: 2000,
				customClass: 'notification-box',
			});
			dispatch('fetchParameters')
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message:  error.message,
				type: 'error',
				duration: 2000,
				customClass: 'notification-box',
			});
			commit('parameterError', error.message)
		})
}

export async function listParameter({commit, state}, requestsParameters ={params: state.params}) {

	await $http.get(`/api/lista-param`, requestsParameters)
		.then((response) => {
			commit('setParameters', response.data.data)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message:  error.message,
				type: 'error',
				duration: 2000,
				customClass: 'notification-box',
			});
		commit('parameterError', error.message)
	})

}

//------------------------ PAGINACIÓN ------------------------//

/* export function setFilterPagination({commit, dispatch}, filter){
	commit('setFilter', filter.target.value)
	dispatch('fetchParameters')
}

export function setPerPagePagination({commit, dispatch}, perPage){
	commit('setPerPage', parseInt(perPage.target.value))
	dispatch('fetchParameters')
}

export function setPagePagination({commit, dispatch}, page){
	commit('setPage', parseInt(page))
	dispatch('fetchParameters')
}

export function setSortByPagination({commit, dispatch}, sortBy){
	commit('setSortBy', sortBy)
	// dispatch('fetchParameters')
}

export function setSortPagination({commit, dispatch}, sort){
	commit('setSort', sort)
	// dispatch('fetchParameters')
}*/

export function imagenCarga({commit}, image) {
	commit('setImagenCarga', image)
}

/** Obtiene las listas de precios */
export async function fetchListsPrices({ commit }) {
	await $http.get(`/api/parametros?paramtable_id=14`)
		.then((response) => {
			commit('setParameters', response.data.data)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message:  error.message,
				type: 'error',
				duration: 2000,
				customClass: 'notification-box',
			});
		commit('parameterError', error.message)
	})
}