export default {
	promos: [],
	brands: [],
	lines:[],
	products: {
        data:[]
    },
    suggested_price: false,
    loading: false,
	scroll_state: 0,
    scroll_disabled: false,
    disabled_buttons: false,
    products_catalog: [],
    selectedProduct: {
        total: 0,
        quantity_available: '',
        long_description: '',
        code: '',
        cant: null,
        click: 0,
        brand: {},
        category: {},
        line: {},
        products_attachments: [],
        subline: {},
        price: {},
        industry_price: {},
        related: []
    },
	groups: [],
	categories: [],
	error: false,
	errorMessage: '',
	contactsWarehouses:[],
	selectedContactWarehouse: '',
    modal: {
        showAvailabilityModal: false,
    },
	params: {
		filter_brands: [],
		filter_groups: [],
		filter_categories: [],
		lines:[],
		paginate: true,
		page: 1,
		filter: '',
		reference: '',
		warehouse: 0,
		only_promotions: false,
		only_public_price: false,
        availability: false,
		applications_id: []
	},
	category_params: {
		filter_brands: [],
		filter_groups: [],
		lines: ''
	},
    hiddenPrices: {
	    state: false
    },
	applications: []
}
