import {defaultSelectedClerk} from "./state";

export function setClerks(state, clerks) {
    state.clerks = clerks;
}

export function setClerk(state, clerk) {
    state.selectedClerk = JSON.parse(JSON.stringify(clerk));
}

export function setActionClerks(state, action) {
    state.actionClerk = action;
}

export function clerkError(state, error) {
    state.error = true
    state.errorMessage = error
}

// Establece un valor para citiesClerks
export function setCitiesClerks(state, citiesClerks) {
    state.citiesClerks = citiesClerks.map(p => {
        return {value: p.id, label: p.city}
    })
}

//Establece el valor por defecto de selectedClerk
export function resetSelectedClerk(state) {
    let id = state.selectedClerk.id
    Object.assign(state.selectedClerk, defaultSelectedClerk())
    if (id) {
        state.selectedClerk.id = id
    }
}
