
export function setCoordinates(state,payload){
	state.coordinates=payload;
}
// export function getCoordinatesSeller(state,payload){
// 	state.coordinatesSeller=payload;
// }
export function setSellers(state,payload){
	state.sellers=payload;
	// console.log(state.sellers);
}
export function setVisit(state,payload){
	state.coordinatesVisit=payload;
}
export function setLines(state,line){
    state.lines=line
}
export function setMarkers(state,payload){
	state.coordinatesMarker=payload;
}
export function setFilterId(state,id){
    state.params.id=id;
}
export function setFilterDate(state,date){
    state.params.date=date;
}
