import { defaultSelectedCustomer } from './state'

export function setCustomers (state, customers) {
	state.customers = customers
}

export function ecommerceError (state, payload) {
	state.error = true
	state.errorMessage = payload
	state.customers = []
}

export function getCoordinates(state,payload) {
	state.coordinates=payload
}
// export function getCoordinatesSeller(state,payload){
// 	state.coordinatesSeller=payload;
// }
export function getSellers(state,payload) {
	state.sellers=payload
}
export function getVisit(state,payload) {
	state.coordinatesVisit=payload
}
export function getMarkers(state,payload) {
	state.coordinatesMarker=payload
}

export function getTypeIdentification(state,payload) {
	state.typeIdentifications = payload
}

export function setSelectedCustomer(state, customer) {
	state.selectedCustomer = customer
}

export function setListPrices(state, list_prices) {
	state.list_prices = list_prices
}

export function setModalPriceList(state, modal_price_list) {
	state.modal_price_list.show = modal_price_list
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter) {
	state.params.page = 1
	state.params.filter = filter
}

export function setPerPage(state, perPage) {
	state.params.page = 1
	state.params.perPage = perPage
}

export function setPage(state, page) {
	state.params.page = page
}

export function setSortBy(state, sortBy) {
	state.params.sortBy = sortBy
}

export function setSort(state, sort) {
	state.params.sort = sort
}
