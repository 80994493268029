export const defaultSelectedLicense = () => {
    return {
        license_code: '',
        start_date:'',
        end_date:'',
        maximum_users: '',
        trial_license: 1,
        range_date:[]
    }
}
export default {
    licenses: [],
    selectedLicense: defaultSelectedLicense(),
    error: false,
    errorMessage: '',
    action: 'create',
    range_date:[],

    getLicenseEdit: true,

    params: {
        paginate: true,
        page: 1,
        perPage: 15,
        filter: '',
        sortBy: 'license_code',
        sort: 'ASC'
    }
}
