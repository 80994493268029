export const defaultProspective = () => {
    return {
        contact_id: null,
        identification_type: 1,
        identification: null,
        check_digit: null,
        name: null,
        address: null,
        main_telephone: null,
        cell_phone: null,
        observations: null,
        photo: null,
        contact_name: null,
        surname: null,
        surname_2: null,
        seller_id: null,
        gender: null,
        email: null,
        latitud: null,
        longitud: null,
        establishment_photo: null,
        city: {
            department_id: null,
            city_id: null
        },
        warehouse_id: null,
        position: null,
        lines: null,
        address_object: {
            type_of_road: "",
            way_number: "",
            generating_way_number: "",
            plate_number: "",
            complement: ""
        },
    };
};

export default {
    prospective: defaultProspective(),
    departments: [],
    sellers: [],
    cities: [],
    identificationTypes: [],
    lines: [],
    editing: false,
    error: false,
    errorMessage: '',
    validateField: '',
    customers: {
        data: []
    },
    params: {
        id: '',
        employee_type: '',
        paginate: true,
        page: 1,
        filter: '',
        only_prospects: true
    },
};
