import $http from "../../axios-intance";
import {Notification, MessageBox} from 'element-ui';

//Obtener los lineas
export async function fetchLines({commit, state}, requestParameters = {params: state.params}) {
	await $http.get('/api/lineas', requestParameters)
	.then((response) => {
		commit('setLines', response.data.data)
	})
	.catch((error) => {
		Notification.error({
			title: 'Error!',
			message: error.message,
			type: 'error',
			duration: 1500,
			customClass: 'notification-box',
		});
		commit('lineError', error.message)
	})
}

//Crear tercero
export async function addLine({commit, state}) {
	await $http.post('/api/lineas', state.selectedLine)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La linea ha sido creada',
			type: 'success',
			duration: 1500,
			customClass: 'notification-box',
		});
		commit('setLine', response.data.data)
		state.error = false
	})
	.catch((error) => {

		let message;

		if (error.response.status == 422) {
			message = 'El código de la linea ya existe'
		} else {
			message = error.message
		}

		MessageBox.alert(message, 'Error', {
			dangerouslyUseHTMLString: true,
			confirmButtonText: 'Aceptar',
			type: 'error',
			customClass:'notification-error'
		})

		commit('lineError', error.message)
	})
}

//Consultar tercero por id
export async function getLine({commit, state}, id) {
	if (state.getLineEdit) {
		await $http.get(`/api/lineas/${id}`)
		.then((response) => {
			commit('setLine', response.data.data)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message: error.message,
				type: 'error',
				duration: 1500,
				customClass: 'notification-box',
			});
			commit('lineError', error.message)
		})

		commit('setGetLineEdit', false)
	}
}

//Consultar tercero por identificación
export async function getLineForIdentification({commit, state, rootState}, identification) {
	await $http.get(`/api/lineas/id/${identification}`)
	.then((response) => {
		if (response.data.success) {
			state.lineEdit = response.data.data.id
		}
	})
	.catch((error) => {
		state.lineEdit = null
		commit('lineError', error.message)
	})
}


//Actualizar tercero
export async function updateLine({commit, state}) {
	await $http.put(`/api/lineas/${state.selectedLine.id}`, state.selectedLine)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La linea ha sido actualizada',
			type: 'success',
			duration: 1500,
			customClass: 'notification-box',
		});
		commit('setLine', response.data.data)
	})
	.catch((error) => {
		let message;

		if (error.response.status == 422) {
			message = 'El código de la linea ya existe'
		} else {
			message = error.message
		}

		MessageBox.alert(message, 'Error', {
			confirmButtonText: 'Aceptar',
			type: 'error'
		})

		commit('lineError', error.message)
	})
}


//Eliminar tercero
export async function removeLine({commit, dispatch}, line) {

	await $http.delete(`/api/lineas/${line.id}`)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La linea ha sido eliminada',
			type: 'success',
			duration: 1500,
			customClass: 'notification-box',
		});
		dispatch('fetchLines')
	})
	.catch((error) => {
		Notification.error({
			title: 'Error!',
			message: error.message,
			type: 'error',
			duration: 1500,
			customClass: 'notification-box',
		});
		commit('LineError', error.message)
	})
}
//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter) {
	commit('setFilter', filter.target.value)
	dispatch('fetchLines')
}

export function setPerPagePagination({commit, dispatch}, perPage) {
	commit('setPerPage', parseInt(perPage.target.value))
	dispatch('fetchLines')
}

export function setPagePagination({commit, dispatch}, page) {
	commit('setPage', parseInt(page))
	dispatch('fetchLines')
}

export function setSortByPagination({commit, dispatch}, sortBy) {
	commit('setSortBy', sortBy)
	// dispatch('fetchLines')
}

export function setSortPagination({commit, dispatch}, sort) {
	commit('setSort', sort)
	// dispatch('fetchLines')
}


