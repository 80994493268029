import $http from "../../axios-intance";
import {Notification, MessageBox} from 'element-ui';

//Obtener los establecimientos
export async function fetchWarehouses({commit, state, rootState}) {

    // if (!state.warehouses.length) {
        await $http.get(`/api/establecimientos?contact_id=${rootState.contacts.selectedContact.id}`)
            .then((response) => {
                commit('setWarehouses', response.data.data)
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box',
                });
                commit('warehouseError', error.message)
            })
    // }
}

//Crear establecimiento
export async function addWarehouse({commit, state, dispatch, rootState}) {

    state.selectedWarehouse.contact_id = rootState.contacts.selectedContact.id;

    await $http.post('/api/establecimientos', state.selectedWarehouse)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'El establecimiento ha sido creado',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
            commit('resetSelectedWarehouse')
            dispatch('fetchWarehouses')
            state.error = false
        })
        .catch((error) => {

            let message;

            if (error.response.status == 422) {
                message = "Algunos campos no se registraron correctamente"
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('warehouseError', error.message)
        })
}

//Actualizar establecimiento
export async function updateWarehouse({commit, state, dispatch}) {

    await $http.put(`/api/establecimientos/${state.selectedWarehouse.id}`, state.selectedWarehouse)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'El establecimiento ha sido actualizado',
                duration: 3000,
                customClass: 'notification-box',
            });
            commit('setActionWarehouses', 'update')
            commit('resetSelectedWarehouse')
            dispatch('fetchWarehouses')
            state.error = false
        })
        .catch((error) => {
            let message;

            if (error.response.status == 422) {
                message = "Algunos campos no se registraron correctamente"
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('warehouseError', error.message)
        })
}


//Eliminar establecimiento
export async function removeWarehouse({commit, dispatch}, warehouse) {

    await $http.delete(`/api/establecimientos/${warehouse.id}`)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'El establecimiento ha sido eliminado',
                duration: 3000,
                customClass: 'notification-box',
            });
            dispatch('fetchWarehouses')
        })
        .catch((error) => {
            MessageBox.alert(error.message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('warehouseError', e.message)
        })
}
