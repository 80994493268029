export const defaultSelectedCategory = () => {
    return {
        categorie_code:'',
        description:'',
    }
}

export default {

    categories: [],
    selectedCategory: defaultSelectedCategory(),
    error: false,
    errorMessage: '',
    action: 'create',
    actionSubcategory: 'create',
    categoryEdit: null,

    getCategoryEdit: true,

    params: {
        paginate: true,
        page: 1,
        perPage: 15,
        filter: '',
        sortBy: 'categorie_code',
        sort: 'ASC'
    }

}
