import $http from '../../../axios-intance';

export async function getSales({commit}, contact_id) {
  commit('setLoading', true)
  await $http.get('/api/reporte-ventas-productos?contact_id=' + contact_id).then(async (response) => {
    await commit('setSales', response.data.sales);
    await commit('setContact', response.data.contact);
    commit('setLoading', false)
  })
}
