import $http from "../../axios-intance";
import {Notification, MessageBox} from 'element-ui';

//Obtener los lineas
export async function reportGenerate({commit, state, dispatch}, requestParameters = {params: state.params}) {
    await $http.get('/api/ventas-perdidas', requestParameters)
        .then((response) => {
            let url = process.env.VUE_APP_API_URL + '/storage/lost_sales/' + response.data.data

            window.open(url, '_blank');
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: 'Error generando el informe',
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('lineError', error.message)
        })
}

export async function downloadDocument({}, documentObject) {
    await $http({
        url: '/storage/lost_sales/' + documentObject,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentObject);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 3000,
            customClass: 'notification-box',
        });

    })
}

export async function fetchBrand({dispatch, state, rootState, commit}) {

    let user_b2b = rootState.login.user_b2b

    await dispatch('brands/fetchBrands', {
        params: {
            paginate: false,
            lines: state.params.lines
        }
    }, {root: true})
    commit('setBrands', rootState.brands.brands)
}

export async function getSellers({commit}) {
    await $http.get('/api/sellers').then((response) => {
        commit("setSellers", response.data.data)
    })
}

//Obtener los terceros de la base de datos
export async function fetchContacts({commit, state}, filter) {


    let params = {
        params: {
            subscribers: [1],
            filter: filter
        }
    }

    if (state.findForId) {
        params.params.id = state.selectedUser.contact_id
    }

    await $http.get('/api/terceros', params).then((response) => {
        commit('setContacts', response.data.data)
    })
}
