import $http from '../../../axios-intance';
import {Notification} from 'element-ui';

// Obtener productos
export async function fetchProducts({commit, state, dispatch}, clear_page = false) {
	if (state.loading) {
		setTimeout(() => {
			dispatch('fetchProductsRequests', clear_page);
		}, 2000);
	} else {
		dispatch('fetchProductsRequests', clear_page);
	}
}

// Obtener productos
export async function fetchProductsRequests({commit, state, dispatch, rootState}, clear_page = false) {
	if (clear_page) {
		commit('setPage', 1);
		commit('resetProducts');
	}

	if (!state.loading || state.params.page === 1) {
		commit('setLoading', true);

		commit('setScroll', 0);

		let requestParameters = {params: state.params};

		await $http.get('/api/productos', requestParameters)
			.then((response) => {

				commit('setProducts', response.data.data);

				commit('setLoading', false);

				if (!state.scroll_disabled && state.products_catalog.length <= 3) {
					commit('setPage', (state.params.page + 1));
					dispatch('fetchProducts');
				}
			}).catch((error) => {
				Notification.error({
					title: '¡Error!',
					message: error.message,
					type: 'error',
					duration: 1500,
					customClass: 'notification-box'
				});
				commit('setLoading', false);
			});
	}
}


// Obtener todos las sucursales de un tercero
export async function fetchContactsWarehousesForContactId({commit, state, dispatch}, contact_id) {

	if (!state.contactsWarehouses.length) {

		await $http.get('/api/sucursales-tercero/' + contact_id)
			.then((response) => {
				commit('setContactsWarehouses', response.data.data);
			}).catch((error) => {
				Notification.error({
					title: '¡Error!',
					message: error.message,
					type: 'error',
					duration: 1500,
					customClass: 'notification-box'
				});
				commit('productError', error.message);
			});
	}
}

/*export async function getProduct({commit, dispatch, rootState}, product_id) {



}*/

/*export async function getRelated({commit, dispatch, rootState}, product_id) {



}*/

export async function validateAvailability({commit, dispatch, rootState, state}, prod) {

	if (+prod.cant > 0) {
		commit('setDisabledButtons', true);
		await dispatch('productDetail/getProduct', prod.id, {root: true});
		commit('setSelectedProduct', rootState.productDetail.selectedProduct);
		if (state.selectedProduct.quantity_available < prod.cant) {
			// Abrir modal de disponibilidad
			state.selectedProduct.quantity = prod.cant;
			state.selectedProduct.original_quantity = prod.cant;

			await dispatch('productDetail/getrelated', prod.id, {root: true});
			commit('setRelated', rootState.productDetail.related);

			commit('setShowAvailabilityModal', true);

		} else {
			// Agregar producto al carrito
			await dispatch('cart/addToCart', prod, {root: true});
		}

		commit('setDisabledButtons', false);
	}
}

export async function fetchLines({dispatch, state, rootState, commit}) {
	if (!state.lines.length) {
		await dispatch('lines/fetchLines', {params: {paginate: false}}, {root: true});
		commit('setLines', rootState.lines.lines);
	}
}

export async function fetchBrand({dispatch, state, rootState, commit}) {
	let user_b2b = rootState.login.user_b2b;

	await dispatch('brands/fetchBrands', {
		params: {
			paginate: false,
			lines: state.params.lines,
			warehouse_id: user_b2b.id
		}
	}, {root: true});

	commit('setBrands', rootState.brands.brands);
}

export async function fetchSublines({
	                                    dispatch,
	                                    state,
	                                    rootState,
	                                    commit
                                    }, requestParameters = {params: state.category_params}) {
	await dispatch('sublines/listSublines', requestParameters, {root: true});
	commit('setGroups', rootState.sublines.sublines);
}

export async function fetchCategories({
	                                      dispatch,
	                                      state,
	                                      rootState,
	                                      commit
                                      }, requestParameters = {params: state.category_params}) {
	await dispatch('categories/fetchCategories', requestParameters, {root: true});
	commit('setCategories', rootState.categories.categories);

}

// Obtener banners
export async function fetchPricesProducts({commit, state, rootState}) {

	let products = state.products.data;

	await $http.post('/api/calcular-precios/' + rootState.login.user_b2b.id, products)
		.then((response) => {
			commit('setProductsData', response.data.data);
		}).catch((error) => {
		});

}

//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch, state}, filter) {

	let queryFilter = state.params.filter;

	commit('setFilter', queryFilter);
	dispatch('fetchProducts');
}

export function setFilterReference({commit, dispatch}, reference) {
	commit('setReference', reference.target.value);
	dispatch('fetchProducts');
}

export function setPerPagePagination({commit, dispatch}, perPage) {
	commit('setPerPage', parseInt(perPage.target.value));
	dispatch('fetchProducts');
}

export async function setPagePagination({commit, dispatch}, page) {
	commit('setPage', parseInt(page));
	await dispatch('fetchProducts');
}

export function setSortByPagination({commit, dispatch}, sortBy) {
	commit('setSortBy', sortBy);
	// dispatch('fetchProducts')
}

export function setSortPagination({commit, dispatch}, sort) {
	commit('setSort', sort);
	// dispatch('fetchProducts')
}

export function registerLostSale({state}, lostSale) {

	$http.post('/api/ventas-perdidas', lostSale).then(response => {
		console.log(response.data.message);
	});
}

/**
 * Obtener aplicaciones.
 */
export async function fetchApplications({commit, state}) {

	if (state.applications.length) {
		return;
	}

	await $http.get('/api/parametros-por-codigo/P99-P', {params: state.params})
		.then((response) => {

			commit('setApplications', response.data.data);
		})
		.catch((error) => {
			console.error(error);
			Notification.error({
				title: 'Error',
				message: 'Error al obtener las aplicaciones de los productos.',
				type: 'error',
				duration: 3000,
				customClass: 'notification-box',
			});
		});
}