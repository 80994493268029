export function setData(state, data) {
    state.data = data
}

export function setLoading(state, loading) {
  state.loading = loading
}

/**
 * Establecer un valor para la variable detail.
 */
export function setDetail(state, detail) {
    state.detail = detail;
}

/**
 * Establecer un valor para el atributo dates de la variable parameters.
 */
export function setDatesToParameters(state, dates) {
    state.parameters.dates = dates;
}

/**
 * Establecer un valor para la variable leaderships.
 */
export function setLeaderships(state, leaderships) {
    state.leaderships = leaderships;
}

/**
 * Establecer un valor para el atributo leadership_id de la variable parameters.
 */
export function setLeadershipIdToParameters(state, leadership_id) {
    state.parameters.leadership_id = leadership_id;
}

/**
 * Establecer un valor para el atributo seller_id de la variable parameters.
 */
export function setSellerIdToParameters(state, seller_id) {
    state.parameters.seller_id = seller_id;
}

/**
 * Establecer un valor para el atributo number_bearing_activities de la variable parameters.
 */
export function setNumberBearingActivitiesToParameters(state, number_bearing_activities) {
    state.parameters.number_bearing_activities = number_bearing_activities;
}

/**
 * Establecer un valor para el atributo minutes_bearing de la variable parameters.
 */
export function setMinutesBearingToParameters(state, minutes_bearing) {
    state.parameters.minutes_bearing = minutes_bearing;
}
