import {data} from './state';

/**
 * Establecer un valor para la variable preloader.
 */
export function setPreloader(state, preloader) {
    state.preloader = preloader;
}

/**
 * Establecer un valor para el atributo disable_incorporation de la variable data.
 */
export function setDisableIncorporationToData(state, disable_incorporation) {
    state.data.disable_incorporation = disable_incorporation;
}

/**
 * Establecer un valor para el atributo only_validation de la variable data.
 */
export function setOnlyValidationToData(state, only_validation) {
    state.data.only_validation = only_validation;
}

/**
 * Establecer un valor para la variable data.
 */
export function resetData(state) {
    state.data = data();
}
