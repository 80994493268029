import $http from '../../axios-intance';
import {Notification, MessageBox} from 'element-ui';

//Obtener los marcas
export async function fetchBrands({commit, state}, requestParameters = {params: state.params}) {
	await $http.get('/api/marcas', requestParameters)
	.then((response) => {
		commit('setBrands', response.data.data)
	})
	.catch((error) => {
		Notification.error({
			title: 'Error!',
			message: error.message,
			type: 'error',
			duration: 1500,
			customClass: 'notification-box',
		});
		commit('brandError', error.message)
	})
}

//Crear tercero
export async function addBrand({commit, state}) {
	await $http.post('/api/marcas', state.selectedBrand)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La Marca ha sido creada',
			type: 'success',
			duration: 1500,
			customClass: 'notification-box',
		});
		commit('setBrand', response.data.data)
		state.error = false
	})
	.catch((error) => {
		let message;

		if (error.response.status == 422) {
			message = 'El código de la Marca ya existe'
		} else {
			message = error.message
		}

		MessageBox.alert(message, 'Error', {
			dangerouslyUseHTMLString: true,
			confirmButtonText: 'Aceptar',
			type: 'error',
			customClass:'notification-error'
		})

		commit('brandError', error.message)
	})
}

//Consultar tercero por id
export async function getBrand({commit, state}, id) {
	if (state.getBrandEdit) {
		await $http.get(`/api/marcas/${id}`)
		.then((response) => {
			commit('setBrand', response.data.data)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message: error.message,
				type: 'error',
				duration: 1500,
				customClass: 'notification-box',
			});
			commit('brandError', error.message)
		})
		commit('setGetBrandEdit', false)
	}
}

//Actualizar tercero
export async function updateBrand({commit, state}) {
	await $http.put(`/api/marcas/${state.selectedBrand.id}`, state.selectedBrand)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La marca ha sido actualizada',
			type: 'success',
			duration: 1500,
			customClass: 'notification-box',
		});
		commit('setBrand', response.data.data)
	})
	.catch((error) => {
		let message;

		if (error.response.status == 422) {
			message = 'El código de la marca ya existe'
		} else {
			message = error.message
		}

		MessageBox.alert(message, 'Error', {
			confirmButtonText: 'Aceptar',
			type: 'error'
		})

		commit('brandError', error.message)
	})
}


//Eliminar tercero
export async function removeBrand({commit, dispatch}, brand) {
	await $http.delete(`/api/marcas/${brand.id}`)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La marca ha sido eliminada',
			type: 'success',
			duration: 1500,
			customClass: 'notification-box',
		});
		dispatch('fetchBrands')
	})
	.catch((error) => {
		Notification.error({
			title: 'Error!',
			message: error.message,
			type: 'error',
			duration: 1500,
			customClass: 'notification-box',
		});
		commit('BrandError', error.message)
	})
}

function mapProviders(contacts) {
	return contacts.map(p => {
		let surname = p.surname == null ? '' : p.surname
		return {value: p.id, label: p.identification + ' ' + p.name + ' ' + surname}
	})
}
//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter) {
	commit('setFilter', filter.target.value)
	dispatch('fetchBrands')
}

export function setPerPagePagination({commit, dispatch}, perPage) {
	commit('setPerPage', parseInt(perPage.target.value))
	dispatch('fetchBrands')
}

export function setPagePagination({commit, dispatch}, page) {
	commit('setPage', parseInt(page))
	dispatch('fetchBrands')
}

export function setSortByPagination({commit, dispatch}, sortBy) {
	commit('setSortBy', sortBy)
	// dispatch('fetchBrands')
}

export function setSortPagination({commit, dispatch}, sort) {
	commit('setSort', sort)
	// dispatch('fetchBrands')
}

// Carga los logos
export function logoBrand({commit}, image) {
	commit('setLogo', image)
}

export function logoBrandMin({commit}, image) {
	commit('setLogoMin', image)
}

export async function fetchProviders({dispatch, state, rootState, commit}) {
	if (!state.providers.length) {
		await dispatch('contacts/listContact', {params: {type: 'is_provider'}}, {root: true})
		commit('setProviders', mapProviders(rootState.contacts.contacts))
	}
}