import $http from '../../axios-intance';
import { Notification } from 'element-ui';

// Obtiene la lista de suscriptores
export async function fetchSubscribers ({commit, state}, requestParameters = {params: state.params}) {
	await $http.get('/api/suscriptores', requestParameters)
	.then((response) => {
		commit('setSubscribers', response.data.data)
	})
	.catch ((error) => {
		Notification.error({
			title: 'Error!',
			message:  error.message,
			type: 'error',
			duration: 2000,
			customClass: 'notification-box',
		});
		commit('subscribersError', error.message)
	})
}

// Crear un suscriptor
export async function addSubscriber ({commit, state, dispatch}) {
   await $http.post('/api/suscriptores', state.selectedSubscriber)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'El Suscriptor ha sido creado',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box',
		  });
		dispatch("fetchSubscribers")
		commit('resetSelectedSubscriber')
		state.error = false
	})
	.catch ((error) => {
		Notification.error({
			title: 'Error!',
			message:  error.message,
			type: 'error',
			duration: 2000,
			customClass: 'notification-box',
		})
		commit('subscribersError', error.message)
	})
}

//Consultar tabla de parametro por id
export async function getSubscriber({commit, state}, id) {
	if (state.getSubscriberEdit) {
		await $http.get(`/api/suscriptores/${id}`)
			.then((response) => {
				commit('setSubscriber', response.data.data)
			})
			.catch((error) => {
				Notification.error({
					title: 'Error!',
					message:  error.message,
					type: 'error',
					duration: 2000,
					customClass: 'notification-box',
				})
				commit('subscribersError', error.message)
			})
		commit('setGetSubscriberEdit', false)
	}
}

// Actualizar suscriptor
export async function updateSubscriber ({commit, state, dispatch}) {
	await $http.put(`/api/suscriptores/${state.selectedSubscriber.id}`, state.selectedSubscriber)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'El suscriptor ha sido actualizado',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box',
		});
		commit('setSubscriber', response.data.data)
		dispatch('fetchLicenses')
	})
   .catch ((error) => {
		Notification.error({
			title: 'Error!',
			message:  error.message,
			type: 'error',
			duration: 2000,
			customClass: 'notification-box',
		})
		commit('subscribersError', error.message)
  })
}
export async function removeSubscriber ({commit, dispatch}, subscriber) {
	await $http.delete(`/api/suscriptores/${subscriber.id}`).then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'El suscriptor ha sido eliminado',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box',
		});
		dispatch('fetchSubscribers')
	})
	.catch ((error) => {
		Notification.error({
			title: 'Error!',
			message: error.message,
			type: 'error',
			duration: 2000,
			customClass: 'notification-box',
	})
	commit('subscribersError', error.message)
  })
}

var dv;

// Calcular el digito de verificación
export function calcularDv({}, myNit) {
	var vpri, x, y, dv, z;

  // Se limpia el Nit
  myNit = myNit.replace ( /\s/g, "" ) ; // Espacios
  myNit = myNit.replace ( /,/g,  "" ) ; // Comas
  myNit = myNit.replace ( /\./g, "" ) ; // Puntos
  myNit = myNit.replace ( /-/g,  "" ) ; // Guiones

  // Se valida el nit
  if  ( isNaN ( myNit ) )  {
	console.log ("El nit/cédula '" + myNit + "' no es válido(a).") ;
	return "" ;
  };

  // Procedimiento
  vpri = new Array(16) ;
  z = myNit.length ;

  vpri[1]  =  3 ;
  vpri[2]  =  7 ;
  vpri[3]  = 13 ;
  vpri[4]  = 17 ;
  vpri[5]  = 19 ;
  vpri[6]  = 23 ;
  vpri[7]  = 29 ;
  vpri[8]  = 37 ;
  vpri[9]  = 41 ;
  vpri[10] = 43 ;
  vpri[11] = 47 ;
  vpri[12] = 53 ;
  vpri[13] = 59 ;
  vpri[14] = 67 ;
  vpri[15] = 71 ;

  x = 0 ;
  y = 0 ;
  for  ( var i = 0; i < z; i++ )  {
	y = ( myNit.substr (i, 1 ) ) ;
	// console.log ( y + "x" + vpri[z-i] + ":" ) ;

	x += ( y * vpri [z-i] ) ;
	// console.log ( x ) ;
  }

  y = x % 11 ;
  // console.log ( y ) ;

  this.dv = ( y > 1 ) ? 11 - y : y ;
  return this.dv
}

export function verificarDv({state}, digito) {

	if (digito != this.dv){
	  state.check = false
	} else {
	   state.check =  true
	}

}

//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter){
	commit('setFilter', filter.target.value)
	dispatch('fetchSubscribers')
}

export function setPerPagePagination({commit, dispatch}, perPage){
	commit('setPerPage', parseInt(perPage.target.value))
	dispatch('fetchSubscribers')
}

export function setPagePagination({commit, dispatch}, page){
	commit('setPage', parseInt(page))
	dispatch('fetchSubscribers')
}

export function setSortByPagination({commit, dispatch}, sortBy){
	commit('setSortBy', sortBy)
	// dispatch('fetchSubscribers')
}

export function setSortPagination({commit, dispatch}, sort){
	commit('setSort', sort)
	// dispatch('fetchSubscribers')
}

//Función para mapear los parametros y retornarlos en un mismo formato para todos los select
function mapParameters(parameters) {
	return parameters.map(p => {
		return {value: p.id, label: p.name_parameter}
	})
}

//-------Suscriptores-----------//
// Obtiene los tipos de documento
export async function fetchDocumentTypes({dispatch, state, rootState, commit}) {
	if (!state.identificationTypes.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 1, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setIdentificationTypes', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene las clases de personas
export async function fetchPersonClasses({dispatch, state, rootState, commit}) {
	if (!state.classPersons.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 2, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setPersonClasses', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene los tipos de contribuyentes
export async function fetchTaxpayerTypes({dispatch, state, rootState, commit}) {
	if (!state.taxpayerTypes.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 4, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setTaxpayerTypes', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene los regimenes tributarios
export async function fetchTaxRegimens({dispatch, state, rootState, commit}) {
	if (!state.taxRegimens.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 3, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setTaxRegimens', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene los regimenes tributarios
export async function fetchCompanyTypes({dispatch, state, rootState, commit}) {
	if (!state.companyTypes.length) {
		await dispatch('parameters/listParameter', {params: {idParamTable: 11, paginate: false}}, {root: true})//TODO: Determinar el idParamTable
		commit('setCompanyTypes', mapParameters(rootState.parameters.parameters))
	}
}

// Obtiene las Licencias
export async function fetchLicenses({dispatch, state, rootState, commit}) {
	if (!state.licenses.length) {
		await dispatch('licenses/fetchLicenses', {}, {root: true})
		commit('setLicenses', rootState.licenses.licenses)
	}
}

// Carga los logos
export function logoSubscriber({commit}, logo) {
	commit('setLogo', logo)
}

export function logoConfiguration({commit}, logo) {
	commit('setFirstLogo', logo)

}

// Obtiene los departamentos
export async function fetchDepartments({dispatch, state, rootState, commit}) {
	if (!state.departments.length) {
		await dispatch('departments/fetchDepartments', {}, {root: true})
		commit('setDepartments', rootState.departments.departments)
	}
}

// Obtiene las ciudades
export async function fetchCities({dispatch, state, rootState, commit}) {
	let id = state.selectedSubscriber.branch_office.city.department_id
	if (id) {
		await dispatch('cities/fetchCities', {params: {department_id: id}}, {root: true})
		commit('setCities', rootState.cities.cities)
	}
}

export async function getTooltips({dispatch, state, rootState, commit}, id){

	let parameter = state.parameters.find(p => p.paramtable_id == id);

	if (parameter.tooltip == '') {
		let getParamTableEdit = rootState.paramTables.getParamTableEdit
		commit('paramTables/setGetParamTableEdit', true, {root: true})
		await dispatch('paramTables/getParamTable',id, {root: true})
		commit('setTooltipParamTable', rootState.paramTables.selectedParamTable);
		commit('paramTables/setGetParamTableEdit', getParamTableEdit, {root: true})
	}
}
