import { defaultSelectedBranchOffice } from "./state";

export function setBranchOffices(state, branchOffices){
    state.branchOffices = branchOffices;
}

export function setBranchOffice(state, branchOffice){
    state.selectedBranchOffice = branchOffice;
}

export function setAction(state, action){
    state.action = action;
}

export function branchOfficeError(state, payload){
    state.error = true
    state.errorMessage = payload
    state.branchOffice = []
}

export function setGetBranchOfficeEdit(state, getBranchOfficeEdit) {
    state.getBranchOfficeEdit = getBranchOfficeEdit;
}

export function resetSelectedBranchOffice(state) {
    Object.assign(state.selectedBranchOffice, defaultSelectedBranchOffice())

}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter){
    state.params.page = 1
    state.params.filter = filter
}

export function setPerPage(state, perPage){
    state.params.page = 1
    state.params.perPage = perPage
}

export function setPage(state, page){
    state.params.page = page
}

export function setSortBy(state, sortBy){
    state.params.sortBy = sortBy
}

export function setSort(state, sort){
    state.params.sort = sort
}

//----------------------------------Parametros----------------//
// Establece un valor para departments
export function setDepartments(state, departments) {
    state.departments = departments.map(p => {
        return {value: p.id, label: p.department}
    })
}

// Establece un valor para cities
export function setCities(state, cities) {
    state.cities = cities.map(p => {
        return {value: p.id, label: p.city}
    })
}

//------------------------ PESTAÑA BODEGAS------------------------//

export function setWarehouses(state, warehouses)
{
    state.selectedBranchOffice.warehouses = warehouses

}

export function setWarehouse(state, warehouse) {
    state.selectedWarehouse = JSON.parse(JSON.stringify(warehouse))
}


export function setActionWarehouses(state, action) {
    state.actionWarehouse = action;
}
