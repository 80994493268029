/**
 * Suscriptores
 */
const subscribers = [
    {
        subscriber: 'Desarrollo',
        domain: 'http://localhost:8080',
        api_domain: 'http://localhost:8000'
    },
    {
        subscriber: 'Desarrollo',
        domain: 'http://127.0.0.1:8080',
        api_domain: 'http://127.0.0.1:8005'
    },
    {
        subscriber: 'HA',
        domain: 'https://ha.starcommerce.co',
        api_domain: 'https://apiha.starcommerce.co'
    },
    {
        subscriber: 'HA Pruebas',
        domain: 'https://hapruebas.starcommerce.co',
        api_domain: 'https://apihapruebas.starcommerce.co'
    },
    {
        subscriber: 'HA Vagrant',
        domain: 'http://ha.starcommerce.test:8080',
        api_domain: 'http://apiha.starcommerce.test'
    }
];

/**
 * Obtener el suscriptor
 * @returns Object Contiene la información del suscriptor
 * @author Jhon García
 */
export function getSubscriber() {
    const domain = document.location.origin;

    return subscribers.find(subscriber => subscriber.domain === domain);
}

/**
 * Obtener el dominio actual
 * @returns {*} Contiene el dominio actual
 * @author Jhon García
 */
export function getDomain() {
    return document.location.origin;
}

/**
 * Determinar el dominio del api, de acuerdo al dominio del front
 * @returns {string|null} Contiene el dominio del api o null si no existe
 * @author Jhon García
 */
export function getApiDomain() {
    let subscriber = getSubscriber();

    if (subscriber) {
        return subscriber.api_domain;
    }

    return null;
}

/**
 * Determinar el dominio del api, de acuerdo al dominio del front
 * @returns {string|null} Contiene el dominio del api o null si no existe
 * @author Jhon García
 */
export function getLogoSubscriber() {
    let subscriber = getSubscriber();

    if (subscriber) {
        return subscriber.logo;
    }

    return null;
}
