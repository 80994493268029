import $http from '../../axios-intance';
import {Notification} from 'element-ui';

/**
 * Obtener actividades.
 */
export async function fetchActivities({commit, state}) {
    await $http.get('/api/actividades')
        .then((response) => {
            commit('setActivities', response.data.data);
        })
        .catch((error) => {
            Notification.error({
                title: 'Error',
                message: 'Error al obtener las actividades',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

/**
 * Crear actividad.
 */
export async function createActivity({commit, state, dispatch}) {

    let activity = state.activity

    activity.code = activity.code.trim().toUpperCase();

    await $http.post('/api/actividades', activity)
        .then(async (response) => {
            commit('setModalShow', false);
            await dispatch('fetchActivities');
            Notification.success({
                title: 'Éxito!',
                message: 'Actividad creada exitosamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            if (error.response.data.errors) {
                for (let key in error.response.data.errors) {
                    Notification.error({
                        title: 'Error',
                        message: error.response.data.errors[key][0],
                        type: 'success',
                        duration: 5000,
                        customClass: 'notification-box',
                    });
                }
            } else {
                Notification.error({
                    title: 'Error',
                    message: 'Error al guardar la actividad',
                    type: 'success',
                    duration: 5000,
                    customClass: 'notification-box',
                });
            }
        });
}

/**
 * Crear actividad.
 */
export async function updateActivity({commit, state, dispatch}) {

    let activity = state.activity

    activity.code = activity.code.trim().toUpperCase();

    await $http.put('/api/actividades/' + activity.id, activity)
        .then(async (response) => {
            commit('setModalShow', false);
            await dispatch('fetchActivities');
            Notification.success({
                title: 'Éxito!',
                message: 'Actividad actualizada exitosamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            if (error.response.data.errors) {
                for (let key in error.response.data.errors) {
                    Notification.error({
                        title: 'Error',
                        message: error.response.data.errors[key][0],
                        type: 'success',
                        duration: 5000,
                        customClass: 'notification-box',
                    });
                }
            } else {
                Notification.error({
                    title: 'Error',
                    message: 'Error al guardar la actividad',
                    type: 'success',
                    duration: 5000,
                    customClass: 'notification-box',
                });
            }
        });
}

/**
 * Cambiar estado de actividad.
 */
export async function changeStateActivity({state, dispatch}, activity) {
    await $http.delete('/api/actividades/' + activity.id, activity)
        .then(async (response) => {
            await dispatch('fetchActivities');
            Notification.success({
                title: 'Éxito!',
                message: 'El estado de la actividad ha cambiado exitosamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
        })
        .catch((error) => {
            console.log(error);
            Notification.error({
                title: 'Error',
                message: 'Error al cambiar el estado de la actividad',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}
