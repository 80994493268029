export const defaultSelectedLine = () => {
    return {
        subscriber_id:1,
        line_code:'',
        line_description:'',
        short_description:'',
        lock_buy:0,
        block_sale:0,
        margin_cost:'',
        calculates_sale_price:0,
        inventories_account:'',
        account_cost:'',
        sales_account:'',
        barcode:2,
        brand:9,
        sub_brand:1,
        line:9,
        subline:1,
        category:1,
        subcategory:2,
        lot:2,
        serial:2,
        type:2,
        Photos:1,
        owner:2,
        maker:2,
        registration_invima:2,
        exp_date_invima:2,
        generic:2,
        year:2,
        conformity_certificate:2,
        exp_date_certified:2,
        weight:2,
        volume:2,
        height:2,
        color:2,
        old_product_code:2,
        new_product_code:2,
        commission_portion:2,
        mg_of_utility:2,
        contact_income:2,
        reduction_management:2,
        high_price:2,
        high_risk:2,
        refrigerated:2,
        type_rotation:2,
        handling_formula:2,
        width:2,
        outline:2,
        rin:2,
        utqg:2,
        load_index:2,
        speed_index:2,
        cum_code:2,
        sgsss_code:2,
        application_unit:2,
        number_applications:2,
        pharmacy_code:2,
        concentration:2,
        controlled_sale:2,
        belongs_to_pos:2,
        purchase_present_packaging:1,
        purchase_quantity_present:'',
        local_purchase:1,
        observation_buy:1,
        present_sale_packaging:1,
        sale_quantity_packaging:'',
        observation_of_sale:1,
        channel_point_sale:9,
        channel_amazon:9,
        channel_b2c:9,
        channel_b2b:9,
        tipe:'',
        data_sheet:2,
        data_sheet_link:2,
        video_link:2,
        prod_desc_config:'',
        act_prod_conf:0,

    }
}

export default {

    lines: [],
    selectedLine: defaultSelectedLine(),
    error: false,
    errorMessage: '',
    action: 'create',
    actionSubline: 'create',
    lineEdit: null,

    getLineEdit: true,

    params: {
        paginate: true,
        page: 1,
        perPage: 15,
        filter: '',
        sortBy: 'line_code',
        sort: 'ASC'
    }

}
