import _ from 'lodash';

export function setManagements (state, managements) {
	state.managements = managements
}

export function sortManagements (state, orderBy) {

	let managements = state.managements

	managements.data =	_.sortBy(data, orderBy)

	state.managements = managements
}

export function setFilterDate(state, date) {
    state.params.filter_date = date
}

export function setSortParams (state, orderBy) {
	let params = state.params

	if (params.sortBy == orderBy){
		if (params.sort == 'ASC'){
			params.sort = 'DESC'
		}else{
			params.sort = 'ASC'
		}
	}else{
		params.sortBy = orderBy
		params.sort = 'DESC'
	}

	params.page = 1

	state.params = params
}

export function setSellers(state,payload){
	state.sellers=payload;
}

export function setPerPage (state, perPage) {
	state.params.page = 1
	state.params.perPage = perPage
}

export function setPage (state, page) {
	state.params.page = page
}

export function setCities(state, cities) {
    state.cities = cities.map(p => {
        return {value: p.id, label: p.city, key: p.id + p.city_code}
    })
}

export function setPreloader (state, preloader) {
    state.preloader = preloader
}

export function setLeaderships (state, leaderships) {
	state.leaderships = leaderships
}

export function setLeadershipIdParams(state, user_id) {

    let leadership = state.leaderships.find(l => l.user_id === user_id);

    if (leadership) {
        state.params.leadership_id = [leadership.id];
    }
}

export function setSellerIdParams(state, seller_id) {
    state.params.seller_id = seller_id;
}

