import $http from '../../axios-intance';
import {Notification} from 'element-ui';


/**
 * Descargar plantilla
 */
export async function downloadTemplate({}) {
    await $http({
        url: '/api/descargar-plantilla-actualizacion-vendedores',
        method: 'GET',
        responseType: 'blob'
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Plantilla actualización de vendedores.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 3000,
                customClass: 'notification-box'
            });
        });
}

/**
 * Incorporar operaciones de inventario.
 */
export async function updateSellers({commit, state}) {
    commit('setPreloader', true);

    let form_data = new FormData();

    for (let key in state.data) {
        form_data.append(key, state.data[key]);
    }

    commit('setDisableIncorporationToData', 1);

    await $http.post('/api/actualizar-vendedores', form_data)
        .then((response) => {
            commit('setPreloader', false);

            if (!!state.data.only_validation) {

                let errors = response.data.data.errors

                if (!errors.length) {

                    commit('setDisableIncorporationToData', 0);

                    Notification.success({
                        title: 'Éxito',
                        message: 'Validación del archivo finalizada éxitosamente',
                        type: 'success',
                        duration: 5000,
                        customClass: 'notification-box',
                    });
                } else {
                    Notification.error({
                        title: 'Error',
                        message: 'Existen inconsistencias en el archivo cargado',
                        type: 'error',
                        duration: 5000,
                        customClass: 'notification-box',
                    });

                    errors.unshift({errores:'errores'})
                    let csv = '';
                    // Loop the array of objects
                    for (let row = 0; row < errors.length; row++) {
                        let keysAmount = Object.keys(errors[row]).length;
                        let keysCounter = 0;

                        if (row === 0) {

                            for (let key in errors[row]) {

                                csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
                                keysCounter++;
                            }
                        } else {
                            for (let key in errors[row]) {
                                csv += errors[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
                                keysCounter++;
                            }
                        }

                        keysCounter = 0;
                    }

                    let now = new Date();
                    let year = now.getFullYear();
                    let month = String(now.getMonth() + 1).padStart(2, '0');
                    let day = String(now.getDate()).padStart(2, '0');
                    let hours = String(now.getHours()).padStart(2, '0');
                    let minutes = String(now.getMinutes()).padStart(2, '0');
                    let seconds = String(now.getSeconds()).padStart(2, '0');
                    let date = `${year}${month}${day}${hours}${minutes}${seconds}`

                    let link = document.createElement('a');
                    link.id = 'download-csv'+date;
                    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
                    link.setAttribute('download', 'errores'+date+'.csv');
                    document.body.appendChild(link);
                    document.querySelector('#download-csv'+date).click();
                }
            } else {
                Notification.success({
                    title: 'Éxito',
                    message: response.data.message,
                    type: 'success',
                    duration: 5000,
                    customClass: 'notification-box',
                });
                commit('resetData');
            }
        })
        .catch((error) => {
            commit('setPreloader', false);
            console.error(error);

            Notification.error({
                title: 'Error',
                message: 'Error al procesar el archivo',
                type: 'error',
                duration: 5000,
                customClass: 'notification-box',
            });
        });
}
