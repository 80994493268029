import $http from '../../axios-intance'
import {Notification, MessageBox} from 'element-ui';
import branchOfficeWarehouses from "../branchoffices-warehouses";

export async function fetchUsers({commit, state}, requestParameters = {params: state.params}) {

    if (requestParameters.params && requestParameters.params.sortBy && requestParameters.params.sortBy !== '') {

        await $http.get('/api/usuarios', requestParameters)
            .then((response) => {
                commit('setUsers', response.data.data)
                commit('setError', false)
            })

            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box',
                });
                commit('UserError', error.message)
                commit('setError', true)
            })
    }
}

export async function downloadReportUsers({state}) {
    await $http({
        url: '/api/usuarios/reporte_excel',
        method: 'GET',
        responseType: 'blob', // important
        params: state.params
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Reporte de usuarios.xlsx');
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 3000,
            customClass: 'notification-box',
        });
    })
}

export async function addUser({commit, state}) {
	await $http.post('/api/usuarios', state.selectedUser)
		.then((response) => {
			Notification.success({
				title: 'Exito!',
				message: 'El Usuario ha sido creado',
				type: 'success',
				duration: 2000,
				customClass: 'notification-box',
			});
			commit('resetSelectedUser')
			commit('setError', false)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message: error.message,
				type: 'error',
				duration: 2000,
				customClass: 'notification-box',
			})
			commit('UserError', error.message)
			commit('setError', true)
		})
}

export async function updateUser({commit, state}) {
	await $http.put('/api/usuarios/'+ state.selectedUser.id, state.selectedUser)
		.then((response) => {
			Notification.success({
				title: 'Exito!',
				message: 'El Usuario ha sido actualizado',
				type: 'success',
				duration: 2000,
				customClass: 'notification-box',
			});
			commit('setError', false)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message: error.message,
				type: 'error',
				duration: 2000,
				customClass: 'notification-box',
			})
			commit('UserError', error.message)
			commit('setError', true)
		})
}

export async function getUser({commit, state}, id) {
	if (state.getUserEdit) {
		await $http.get(`/api/usuarios/${id}`)
			.then((response) => {
				let contact = response.data.data
				contact.deleted_at = contact.deleted_at == null || contact.deleted_at == '' ? 0 : 1
				commit('setUser', contact)
			})
			.catch((error) => {
				Notification.error({
					title: 'Error!',
					message: error.message,
					type: 'error',
					duration: 1500,
					customClass: 'notification-box',
				});
				commit('UserError', error.message)
			})
		commit('setGetUser', false)
	}
}

// Obtiene todos los suscriptores
export async function fetchSubscribers({dispatch, state, rootState, commit}) {
	if (!state.subscribers.length) {
		await dispatch('subscribers/fetchSubscribers', {params: {paginate: false}}, {root: true})
		commit('setSubscribers', rootState.subscribers.subscribers)
	}
}

// Obtiene todos los suscriptores seleccionados
export async function fetchSubscribersFiltered({dispatch, state, rootState, commit}) {

	let subscribers_filtered = []

	if (state.selectedUser.subscribers.length) {

		let selected_subscribers = JSON.parse(JSON.stringify(state.selectedUser.subscribers))

		let return_subscriber = false

		subscribers_filtered = state.subscribers.filter((subscriber) => {
			selected_subscribers.map((ss) => {
				if (ss == subscriber.id) {
					return_subscriber = true
				}
			})
			if (return_subscriber) {
				return_subscriber = false
				return subscriber
			}

		})

	}


	commit('setSubscribersFiltered', subscribers_filtered)

}

// Obtiene todas las sedes
export async function fetchBranchOffices({dispatch, state, rootState, commit}) {
	await dispatch('branchOffices/fetchBranchOffices', {
		params: {
			subscriber_id: state.selectedUser.subscriber_id,
			paginate: false
		}
	}, {root: true})
	commit('setBranchOffices', rootState.branchOffices.branchOffices)
}

// Obtiene todas las bedegas de la sede seleccionada
export async function fetchBranchOfficeWarehouses({dispatch, state, rootState, commit}) {
	await dispatch('branchOfficeWarehouses/fetchBranchOfficeWarehouses', {
		params: {
			branchoffice_id: state.selectedUser.branchoffice_id,
			paginate: false
		}
	}, {root: true})
	commit('setBranchOfficeWarehouses', rootState.branchOfficeWarehouses.branchoffice_warehouses)
}

export async function fetchModules({commit, state}) {
	if (!state.modules.length) {
		await $http.get('/api/modulos')
			.then((response) => {
				commit('setModules', response.data.data)
			})
			.catch((error) => {
				Notification.error({
					title: 'Error!',
					message: error.message,
					type: 'error',
					duration: 1500,
					customClass: 'notification-box',
				});
			})

	}
}

// Obtiene todos los modulos seleccionados
export async function fetchModulesFiltered({dispatch, state, rootState, commit}) {

	let modules_filtered = []

	if (state.selectedUser.modules.length) {

		let selected_modules = JSON.parse(JSON.stringify(state.selectedUser.modules))

		let return_module = false

		modules_filtered = state.modules.filter((module) => {
			selected_modules.map((m) => {
				if (m == module.id) {
					return_module = true
				}
			})
			if (return_module) {
				return_module = false
				return module
			}
		})
	}

	commit('setModulesFiltered', modules_filtered)

}

export async function fetchRoles({commit, state}) {

	await $http.get('/api/roles')
		.then((response) => {
			commit('setRoles', response.data.data)
		})
		.catch((error) => {
		   console.log(error.message)
		})
}

//Obtener los terceros de la base de datos
export async function fetchContacts({commit, state}, query) {

	let typeuser

	switch (state.selectedUser.type) {
		case 'e':
			typeuser = 'is_employee';
			break;
		case 'c':
			typeuser = 'is_customer';
			break;
		case 'p':
			typeuser = 'is_provider';
			break;
	}

	let params = {
		params: {
			subscribers: state.selectedUser.subscribers,
			typeuser: typeuser,
			query: query
		}
	}

	if (state.findForId) {
		params.params.id = state.selectedUser.contact_id
	}

	await $http.get('/api/terceros', params).then((response) => {
		commit('setContacts', response.data.data)
	})
}


//Agrupar los terceros
export async function fetchGroupedContacts({commit, state, dispatch}, query, id) {

	await dispatch('fetchContacts', query, id);

	/*let grouped_contacts = [
		{
			label: 'Clientes',
			options: []
		},
		{
			label: 'Proveedores',
			options: []
		},
		{
			label: 'Empleados',
			options: []
		}
	];

	let contacts = state.contacts

	contacts.map((c) => {
		if (c.is_customer) {
			let cc = JSON.parse(JSON.stringify(c))
			cc.id = 'c' + cc.id
			grouped_contacts[0].options.push(cc)
		}
		if (c.is_provider) {
			let cp = JSON.parse(JSON.stringify(c))
			cp.id = 'p' + cp.id
			grouped_contacts[1].options.push(cp)
		}
		if (c.is_employee) {
			let ce = JSON.parse(JSON.stringify(c))
			ce.id = 'e' + ce.id
			grouped_contacts[2].options.push(ce)
		}
	})

	commit('setGroupedContacts', grouped_contacts)*/
}


export function dataUserClerk({commit, state}, id) {

	let contact = state.contact

	let clerk = contact.clerks.find((clerk) => clerk.id == id)

	let data = {
		name: '',
		surname: '',
		email: '',
		photo: state.selectedUser.photo,
		contact_id: state.selectedUser.contact_id,
		clerk_id: id,
		type: state.selectedUser.type
	}


	if (clerk && clerk.email != null && contact.email != '') {
		data.name = clerk.name
		data.surname = clerk.surname
		data.email = clerk.email
		data.photo = clerk.photo
	}

	else {
		MessageBox.alert("El funcionario no tiene registrado un email", "Error", {
			confirmButtonText: "Aceptar",
			type: "error",
			customClass: 'notification-error'
		}).then(verifica => {
			console.log(true)
		});
	}

	commit('setDataUser', data)

}

export async function dataUserContact({commit, state}) {

	await $http.get('/api/terceros/' + state.selectedUser.contact_id)
	.then((response) => {

		commit('setContact', response.data.data)

		let data = {
			name: state.selectedUser.name,
			surname: state.selectedUser.surname,
			email: state.selectedUser.email,
			photo: '',
			clerk_id: state.selectedUser.clerk_id,
			type: state.selectedUser.type,
			contact_warehouse_id: state.selectedUser.contact_warehouse_id
		}

		let disabledClerk = true
		if (state.selectedUser.type == 'e') {
			if (state.contact.email != null && state.contact.email != '') {
				data = {
					name: state.contact.name,
					surname: state.contact.surname,
					email: state.contact.email,
					photo: state.contact.employee.photo,
					contact_id: state.contact.id,
					type: 'e'
				}
			} else {
				MessageBox.alert("El empleado no tiene registrado un email", "Error", {
					confirmButtonText: "Aceptar",
					type: "error",
					customClass: 'notification-error'
				}).then(verifica => {
					console.log(true)
				});
			}
		} else if (state.selectedUser.type == 'p') {
			let clerks = state.contact.clerks
			commit('setClerks', clerks)
			commit('setContactsWarehouses', state.contact.warehouses)
			// data.photo = state.contact.provider.logo
			data.contact_id = state.contact.id
			data.type = 'p'
			disabledClerk = false
		} else if (state.selectedUser.type == 'c') {
			let clerks = state.contact.clerks
			commit('setClerks', clerks)
			commit('setContactsWarehouses', state.contact.warehouses)
			// data.photo = state.contact.customer.logo
			data.contact_id = state.contact.id
			data.type = 'c'
			disabledClerk = false
		}

		commit('setDisabledClerk', disabledClerk)
		commit('setDataUser', data)

	})
	.catch((error) => {
		console.log(error)
	})



}

export function setClerks({commit, state}) {

	let disabledClerk = true

	if (state.selectedUser.type == 'p' || state.selectedUser.type == 'c') {
		let clerks = state.contacts[0].clerks
		commit('setClerks', clerks)
		disabledClerk = false
	}

	commit('setDisabledClerk', disabledClerk)

}

export function setContactsWarehouses({commit, state}) {

	let disabledClerk = true

	if (state.selectedUser.type == 'p' || state.selectedUser.type == 'c') {
		let contacts_warehouses = state.contacts[0].warehouses
		commit('setContactsWarehouses', contacts_warehouses)
		disabledClerk = false
	}

	commit('setDisabledClerk', disabledClerk)

}


//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter) {
	commit('setFilter', filter.target.value)
	dispatch('fetchUsers')
}

export function setPerPagePagination({commit, dispatch}, perPage) {
	commit('setPerPage', parseInt(perPage.target.value))
	dispatch('fetchUsers')
}

export function setPagePagination({commit, dispatch}, page) {
	commit('setPage', parseInt(page))
	dispatch('fetchUsers')
}

export function setSortByPagination({commit, dispatch}, sortBy) {
	commit('setSortBy', sortBy)
}

export function setSortPagination({commit, dispatch}, sort) {
	commit('setSort', sort)
}
