/**
 * Establecer un valor para la variable price_lists.
 */
import {config} from './state';

export function setPriceLists(state, price_lists) {
    state.price_lists = price_lists;
}

/**
 * Establecer un valor para la variable editing.
 */
export function setEditing(state, editing) {
    state.editing = editing;
}

/**
 * Establecer un valor para el atributo show de la variable modal.
 */
export function setModalShow(state, show) {
    state.modal.show = show;
}

/**
 * Establecer un valor para la variable reason.
 */
export function setPriceList(state, price_list) {
    state.price_list = JSON.parse(JSON.stringify(price_list));
}

/**
 * Establecer un valor para la variable config.
 */
export function setConfig(state, config) {
    state.config = JSON.parse(JSON.stringify(config));
}

/**
 * Establecer el valor por defecto para la variable config.
 */
export function resetConfig(state) {
    state.config = config();
}

/**
 * Establecer un valor para la variable price_lists_filtered.
 */
export function setPriceListsFiltered(state, price_lists_filtered) {
    state.price_lists_filtered = price_lists_filtered;
}

/**
 * Establecer un valor para la variable .
 */
export function updateConfigInList(state, config) {
    state.price_lists.data.map(pl => {
        if (pl.id === config.price_list_id) {
            pl.price_list_config.map(c => {
                if (c.line_id === config.line_id) {
                    c.min_value_customer_list = config.min_value_customer_list
                    c.min_value_for_list_change = config.min_value_for_list_change
                    c.min_value_for_no_freight_charge = config.min_value_for_no_freight_charge
                    c.freight_value = config.freight_value
                }
            });
        }
    });
}
