import {deepeningConfiguration} from './state';

export function setDeepeningConfigurations(state, deepening_configurations) {
    state.deepening_configurations = deepening_configurations
}

export function setDeepeningConfiguration(state, deepening_configuration) {
    state.deepening_configuration = deepening_configuration
}

export function resetDeepeningConfiguration(state) {
    state.deepening_configuration = deepeningConfiguration()
}

export function setPreloader(state, preloader) {
    state.preloader = preloader
}

export function parseQuantityDeepeningConfiguration(state) {
    state.deepening_configuration.quantity = Number.parseInt(state.deepening_configuration.quantity)
}

