export const defaultDates = () => {

    const end = new Date();
    const start = new Date();

    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);

    return [formatDate(start), formatDate(end)]

}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const defaultSelectedVisit = () => {
    return {
        seller_id: '',
        contact_id: '',
        contact_warehouse_id: '',
        visit_type: '',
        longitude_checkin: '',
        latitude_checkin: '',
        latitude_checkout: '',
        longitude_checkout: '',
        motive: [],
        test: '',
        distance: '',
        checkin_date: '',
        checkout_date: '',
        observation: '',
        photo: '',
        checkout_ok: '',
        range_date: []
    }
}

export default {
    cities: [],
    sellers_select: [],
    visits: [],
    sellers: {
        data: [],
        header: {}
    },
    sellers_contacts: [],
    selectedVisit: defaultSelectedVisit(),
    error: false,
    errorMessage: '',
    sellersWareHouses: {
        data: [],
        headers: {}
    },
    sellersWareHousesNoVisit: {
        data: [],
        headers: {}
    },
    params: {
        paginate: true,
        page: 1,
        perPage: 15,
        filter: '',
        sortBy: 'seller_id',
        sort: 'ASC',
        filter_date: defaultDates(),
        seller_id: [],
        city_id: null,
        load_details: true,
        load_details_not_visit: false,
        load_details_details_visit: false,
        id_contact: "",
        sortTrue: true,
        campo1: "",
        campo2: "",
        button: "Expandir Todo",
        campoorder: "checkin_date",
        typeorder: "DESC",
        campoorderdet: "description",
        typeorderdet: "ASC",
        leadership_id: [],
        only_prospects: false
    },
    view_details: false,
    set_all_view_details: false,
    preloader: false,
    leaderships: [],
    activity: {},
    visit: {
        id: null,
        latitude_checkin: '',
        longitude_checkin: '',
        latitude_checkout: '',
        longitude_checkout: '',
        release: ''
    },
    modal: {
        show: false
    }
}
