/**
 * Establecer un valor para la variable reasons.
 */
import {reason} from './state';

export function setReasons(state, reasons) {

    reasons.map(r => {
        if (r.code_parameter.includes('AGRS')) {
            r.reason_type_id = 1;
            r.reason_type = state.reason_types[0];
        }else {
            r.reason_type_id = 2;
            r.reason_type = state.reason_types[1];
        }
    });

    state.reasons = reasons;
}

/**
 * Establecer un valor para la variable editing.
 */
export function setEditing(state, editing) {
    state.editing = editing;
}

/**
 * Establecer un valor para el atributo show de la variable modal.
 */
export function setModalShow(state, show) {
    state.modal.show = show;
}

/**
 * Establecer un valor para la variable reason.
 */
export function setReason(state, reason) {
    state.reason = JSON.parse(JSON.stringify(reason));
}

/**
 * Establecer el valor por defecto para la variable reason.
 */
export function resetReason(state, ) {
    state.reason = reason();
}
