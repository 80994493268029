import {defaultSelectedLicense} from "./state";

export function setLicenses (state, licenses) {
	state.licenses = licenses;
}

export function setLicense (state, license) {
	state.selectedLicense = license
}

export function setAction(state, action){
	state.action = action;
}

export function licensesError (state, payload) {
	state.error = true
	state.error = payload
	state.licenses = []
}

export function setGetLicense(state, getLicenseEdit) {
	state.getLicenseEdit = getLicenseEdit;
}

export function resetSelectedLicense(state) {
	Object.assign(state.selectedLicense, defaultSelectedLicense())
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter){
	state.params.page = 1
	state.params.filter = filter
}

export function setPerPage(state, perPage){
	state.params.page = 1
	state.params.perPage = perPage
}

export function setPage(state, page){
	state.params.page = page
}

export function setSortBy(state, sortBy){
	state.params.sortBy = sortBy
}

export function setSort(state, sort){
	state.params.sort = sort
}
