import $http from '../../axios-intance';
import {Notification} from 'element-ui';

export async function fetchOrders({commit, state}, requestParameters = {params: state.params}) {

    await $http.get('/api/pedidos-vendedor', requestParameters)
        .then((response) => {
            commit('setOrders', response.data.data);
        }).catch((error) => {
            Notification.error({
                title: '¡Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box'
            });
        });

}

export async function fetchDetail({commit, state}, order) {

    commit('setSelectedOrder', order);

    await $http.get('/api/detalle-documento/' + order.id).then((response) => {
        commit('setDetailToOrder', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('error', error.message);
    });
}

export async function fetchInvoices({commit, state}, order) {

    commit('setSelectedOrder', order);

    await $http.get('/api/facturas-pedido/' + order.id).then((response) => {
        commit('setInvoicesToOrder', response.data.data);
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('error', error.message);
    });
}

//Obtener los lineas
export async function reportGenerate({commit, state, dispatch}, requestParameters = {params: state.params}) {
    await $http.get('/api/reporte-pedidos', requestParameters)
        .then((response) => {
            let url = process.env.VUE_APP_API_URL + '/storage/reports/' + response.data.data

            window.open(url, '_blank');
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: 'Error generando el informe',
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('lineError', error.message);
        });
}

export async function downloadDocument({}, documentObject) {
    await $http({
        url: '/storage/reports/' + documentObject,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentObject);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 3000,
            customClass: 'notification-box',
        });

    });
}

export function setFilterPagination({commit, dispatch}, filter) {
    commit('setFilter', filter.target.value);
    dispatch('fetchOrders');
}

export function setPerPagePagination({commit, dispatch}, perPage) {
    commit('setPerPage', parseInt(perPage.target.value));
    dispatch('fetchOrders');
}

export function setPagePagination({commit, dispatch}, page) {
    commit('setPage', parseInt(page));
    dispatch('fetchOrders');
}

export function setSortByPagination({commit}, sortBy) {
    commit('setSortBy', sortBy);
}

export function setSortPagination({commit}, sort) {
    commit('setSort', sort);
}

export async function getSellers({commit, state}) {
    let id_ledership = state.params.leadership_id;
    if (id_ledership === null) {
        await $http.get('/api/sellers').then((response) => {
            commit('setSellers', response.data.data);
        });

    } else {

        await $http.get('/api/sellers/' + id_ledership).then((response) => {
            commit('setSellers', response.data.data);
        });
    }
}

export async function getLeaderships({commit, state}) {
    await $http.get('/api/leaderships').then((response) => {
        commit('setLeaderships', response.data.data);
    });
}
