import $http from "../../axios-intance";
import { Notification } from 'element-ui'


export async function fetchsellers({commit, state}, text) {
    let params = { filter:text}
    await $http.get('/api/sellers', {params} )
        .then((response) => {
            commit('setSellers', response.data.data)
        })
        .catch((error) => {
            Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box',
        });
    })
}

export async function fetchDates({commit, state}) {
    await $http.get(`/api/seller/${state.params.seller}`)
        .then((response) => {
            commit('setdates', response.data.data)

        })
        .catch((error) => {
            Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box',
        });
    })
}

export async function fetchCordinate({commit, state}) {
    await $http.get(`/api/seller/${state.params.seller}/${state.params.date}`)
        .then((response) => {
            commit('setCoordinates', response.data.data)
        })
        .catch((error) => {
            console.log(error);
            Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box',
        });
    })
}

export async function currentLocation({commit, state}) {
    await $http.get(`/api/ultima-ubicacion/${state.params.seller}`)
        .then((response) => {
            commit('setCoordinates', response.data.data)
            commit('setshow_current_location', true)

        })
        .catch((error) => {
            Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box',
        });
    })
}
export async function fetchVisits({commit, state}) {
    await $http.get(`/api/seller-visits/${state.params.seller}/${state.params.date}`)
        .then((response) => {
            commit('setVisits', response.data.data)

        })
        .catch((error) => {
            Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box',
        });
    })
}

export async function getContactWarehouseHomeSeller({commit, state}) {
    await $http.get(`/api/casa-vendedor/${state.params.seller}`)
        .then((response) => {
            commit('setHome', response.data.data)
        })
        .catch((error) => {
            console.error(error);
        })
}
