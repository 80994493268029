import $http from '../../axios-intance';
import {Notification} from 'element-ui';

export async function saveProspectiveCustomer({commit, state}) {
    state.prospective.department_id = state.prospective.city.department_id;
    state.prospective.city_id = state.prospective.city.city_id;

    return new Promise(async (resolve, reject) => {
        await $http.post('/api/cliente-prospecto', state.prospective).then((response) => {
            Notification.success({
                title: 'Muy bien!',
                message: 'Cliente prospecto guardado exitosamente',
                type: 'success',
                duration: 5000,
                customClass: 'notification-box',
            });
            commit('resetProspectiveForm');
            commit('getProspectiveWarehouseId', response.data.data);
            resolve();
        }).catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
            reject();
        });

    });
}

export async function fetchDepartments({dispatch, state, rootState, commit}) {
    if (!state.departments.length) {
        await dispatch('departments/fetchDepartments', {}, {root: true});
        commit('setDepartments', rootState.departments.departments);
    }
}

export async function fetchCities({dispatch, state, rootState, commit}) {
    let id = state.prospective.city.department_id;
    if (id) {
        await dispatch('cities/fetchCities', {params: {department_id: id}}, {root: true});
        commit('setCities', rootState.cities.cities);
    }
}

export function photoFile({commit}, photo) {
    commit('setPhotoFile', photo);
}

export async function fetchIdentificationTypes({dispatch, state, rootState, commit}) {
    if (!state.identificationTypes.length) {
        await dispatch('parameters/listParameter', {params: {idParamTable: 1, paginate: false}}, {root: true});
        commit('setIdentificationTypes', rootState.parameters.parameters);
    }
}

export async function fetchLines({dispatch, state, rootState, commit}) {
    if (!state.lines.length) {
        await dispatch('lines/fetchLines', {params: {idParamTable: 1, paginate: false}}, {root: true});
        commit('setLines', rootState.lines.lines);
    }
}

export async function getContact({commit, state}, warehouse) {
    await $http.get(`/api/establecimientos-vendedor?ide=${warehouse}`).then((response) => {
        commit('ecommerce/setSelectedCustomer', response.data.data, {root: true});
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('error', error.message);
    });
}

export async function fetchCustomers({commit, state}, requestParameters = {params: state.params}) {
    await $http.get('/api/establecimientos-vendedor', requestParameters)
        .then((response) => {
            if (!!requestParameters.params.ide) {
                commit('setProspective', response.data.data);
            } else {
                commit('setCustomers', response.data.data);
            }
        }).catch((error) => {
            console.error(error);
        });
}

export async function fetchSellers({commit, state}, leadership_id = null) {
    await $http.get('/api/sellers/' + leadership_id).then((response) => {
        commit('setSellers', response.data.data);
    });
}
