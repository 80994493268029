import $http from '../../axios-intance'
import {Notification, MessageBox} from 'element-ui';

//Obtener los sublineas
export async function fetchSublines({commit, state, rootState}) {

    // if (!state.sublines.length) {
        await $http.get(`/api/sublineas?line_id=${rootState.lines.selectedLine.id}`)
            .then((response) => {
                commit('setSublines', response.data.data)
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box',
                });
                commit('sublineError', error.message)
            })
    // }
}

//Crear funcionario
export async function addSubline({commit, state, dispatch, rootState}) {
    state.selectedSubline.line_id = rootState.lines.selectedLine.id;

    await $http.post('/api/sublineas', state.selectedSubline)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'La Sublinea ha sido creada',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
            commit('resetSelectedSubline')
            dispatch('fetchSublines')
            state.error = false
        })
        .catch((error) => {

            let message;

            if (error.response.status == 422) {
                message = error.response.data.errors.subline_code[0]
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('sublineError', error.message)
        })
}

//Consultar funcionario por id
export async function getSubline({commit, state}, id) {

    if (state.selectedSubline.id != id) {

        await $http.get(`/api/sublineas/${id}`)
            .then((response) => {
                commit('setSubline', response.data.data)
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                commit('sublineError', error.message)
            })
    }

}

//Actualizar funcionario
export async function updateSubline({commit, state, dispatch}) {

    await $http.put(`/api/sublineas/${state.selectedSubline.id}`, state.selectedSubline)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'La Sublinea ha sido actualizada',
                duration: 3000,
                customClass: 'notification-box',
            });
            commit('setActionSublines', 'create')
            commit('resetSelectedSubline')
            dispatch('fetchSublines')
            state.error = false
        })
        .catch((error) => {
            let message;

            if (error.response.status == 422) {
                message =  error.response.data.errors.subline_code[0]
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('sublineError', error.message)
        })
}


//Eliminar funcionario
export async function removeSubline({commit, dispatch}, subline) {

    await $http.delete(`/api/sublineas/${subline.id}`)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'La Sublinea ha sido eliminada',
                duration: 3000,
                customClass: 'notification-box',
            });
            dispatch('fetchSublines')
        })
        .catch((error) => {
            MessageBox.alert(error.message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('sublineError', e.message)
        })
}

export async function listSublines({commit, state}, requestParameters = {params: state.params}) {

    await $http.get('/api/lista-sublineas', requestParameters)
        .then((response) => {
            commit('setSublines', response.data.data)
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('sublineError', error.message)
        })
}

