export const defaultSelectedSubbrand = () => {
    return {
        subbrand_code:'',
        description:'',
        image: '',
        lock_buy: 0,
        block_sale: 0,
        data_sheet: '',
        margin_cost_percentage: '',
        action: 'n',
    }
}

export default {

    subbrands: [],
    selectedSubbrand: defaultSelectedSubbrand(),
    error: false,
    errorMessage: '',
    actionSubbrand: 'create',
}
