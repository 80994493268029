export const defaultSelectedParameter = () => {
    return{
        code_parameter:'',
        name_parameter:'',
        alphanum_data_first:'',
        alphanum_data_second:'',
        alphanum_data_third:'',
        numeric_data_first:'',
        numeric_data_second:'',
        numeric_data_third:'',
        date_data_first:'',
        date_data_second:'',
        date_data_third:'',
        image:''
    }
}
export default {
    parameters: [],
    selectedParameter: defaultSelectedParameter(),
    error: false,
    errorMessage: '',
    actionParameter: 'create',

    getParameterEdit: true,

}
