import $http from '../../axios-intance';
import {Notification} from 'element-ui';

//Obtener el menu de un suscriptor
export async function getMenuSubscriber({commit, state, dispatch}) {

    let requestParameters = {params: {subscriber_id: state.selected_menu_subscriber.subscriber_id}};

    await $http.get('/api/menu-suscriptores', requestParameters)
        .then((response) => {
            commit('setMenuSubscriber', response.data.data)
            dispatch('fetchModules')
            dispatch('fetchMenus')
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('menuSubscriberError', error.message)
        })
}

//Actualizar menu de suscriptor
export async function updateMenuSubscriber({commit, state}) {

    await $http.put(`/api/menu-suscriptores/${state.selected_menu_subscriber.subscriber_id}`, state.menu_subscriber)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: ' El menu ha sido actualizado',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('setMenuSubscriber', response.data.data)
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            })
            commit('menuSubscriberError', error.message)
        })
}

export async function fetchParentsMenus({state, commit, dispatch}) {


    let module_id = state.selected_menu_subscriber.module_id
    if (module_id) {

        let module = state.menu_subscriber.find(m => m.id == module_id)

        let parents_menus = [
            {
                label: 'Primer nivel',
                options: []
            },
            {
                label: 'Segundo nivel',
                options: []
            }
        ];

        module.first_level.map((fl) => {
            parents_menus[0].options.push(fl)
            if (fl.second_level) {
                fl.second_level.map((sl) => {
                    parents_menus[1].options.push(sl)
                })
            }
        })

        commit('setParentsMenus', parents_menus)

    }

}

export async function fetchModules({commit, state}) {

    let menu_subscriber = JSON.parse(JSON.stringify(state.menu_subscriber))

    let modules = []

    menu_subscriber.map((module) => {
        modules.push(module)
    })

    commit('setModules', modules)

}

export async function fetchMenus({commit, state}) {

    if (!state.menus.length) {
        await $http.get('/api/menus')
            .then((response) => {
                commit('setMenus', response.data.data)
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 2000,
                    customClass: 'notification-box',
                });
                commit('menuSubscriberError', error.message)
            })
    }
}

export async function fetchSubscribers({commit, state, rootState, dispatch}) {

    if (!state.subscribers.length) {
        await dispatch('subscribers/fetchSubscribers', {params: {paginate: false}}, {root: true})
        commit('setSubscribers', rootState.subscribers.subscribers)
    }

}

export function copyMenuItem({commit, state}, menu_copy) {

    let menu_subscriber = JSON.parse(JSON.stringify(state.menu_subscriber))

    menu_copy = JSON.parse(JSON.stringify(menu_copy))
    menu_copy.id = (Math.floor(Math.random() * (10000 - 1000)) + 1000) * -1

    let copied = false
    let menu_id = menu_copy.menu_subscriber_id

    menu_subscriber.map((module) => {
        if (menu_copy.module_id == module.id) {
            if (menu_id == null || menu_id == '') {
                if (module.id == menu_copy.module_id) {
                    if (!module.first_level) {
                        module.first_level = []
                    }
                    module.first_level.push(menu_copy)
                    copied = true
                }
            }
            if (module.first_level && !copied) {
                module.first_level.map((fl) => {

                    if (fl.id == menu_id) {
                        if (!fl.second_level) {
                            fl.second_level = []
                        }
                        fl.second_level.push(menu_copy)
                        copied = true
                    }
                    if (fl.second_level && !copied) {
                        fl.second_level.map((sl) => {

                            if (sl.id == menu_id) {
                                if (!sl.third_level) {
                                    sl.third_level = []
                                }
                                sl.third_level.push(menu_copy)
                                copied = true
                            }
                        })
                    }

                })
            }
        }
    })

    commit('setMenuSubscriber', menu_subscriber)
    commit('refactorMenu')

}

export function deleteMenuItem({commit, state}, menu_delete) {

    let menu_subscriber = JSON.parse(JSON.stringify(state.menu_subscriber))

    menu_delete = JSON.parse(JSON.stringify(menu_delete))

    let menu_id = menu_delete.id

    let deleted = false

    menu_subscriber.map((module) => {
        if (module.id == menu_delete.module_id) {
            if (module.first_level && !deleted) {
                module.first_level.map((fl, ifl) => {
                    if (fl.id == menu_id) {
                        module.first_level.splice(ifl, 1)
                        deleted = true
                    }
                    if (fl.second_level && !deleted) {
                        fl.second_level.map((sl, isl) => {
                            if (sl.id == menu_id) {
                                fl.second_level.splice(isl, 1)
                                deleted = true
                            }
                            if (sl.third_level && !deleted) {
                                sl.third_level.map((tl, itl) => {
                                    if (tl.id == menu_id) {
                                        sl.third_level.splice(itl, 1)
                                        deleted = true
                                    }
                                })
                            }
                        })
                    }

                })
            }
        }
    })

    commit('setMenuSubscriber', menu_subscriber)
    commit('refactorMenu')

}

export function addMenuItem({commit, state}) {

    let menu_subscriber = JSON.parse(JSON.stringify(state.menu_subscriber))

    let selected_menu_subscriber = JSON.parse(JSON.stringify(state.selected_menu_subscriber))
    selected_menu_subscriber.id = (Math.floor(Math.random() * (10000 - 1000)) + 1000) * -1

    let menu = state.menus.find(m => m.id == selected_menu_subscriber.menu_id)
    let added = false

    selected_menu_subscriber.menu = menu

    menu_subscriber.map((module) => {

        if (module.id == selected_menu_subscriber.module_id) {
            if ((selected_menu_subscriber.menu_subscriber_id == null || selected_menu_subscriber.menu_subscriber_id == '') && module.id == selected_menu_subscriber.module_id && !added) {
                if (!selected_menu_subscriber.second_level) {
                    selected_menu_subscriber.second_level = []
                }
                module.first_level.push(selected_menu_subscriber)
                added = true
            }
            if (module.first_level) {
                module.first_level.map((fl) => {
                    if (fl.id == selected_menu_subscriber.menu_subscriber_id && !added) {
                        if (!fl.second_level) {
                            fl.second_level = []
                        }
                        if (!selected_menu_subscriber.third_level) {
                            selected_menu_subscriber.third_level = []
                        }
                        fl.second_level.push(selected_menu_subscriber)
                        added = true
                    }
                    if (fl.second_level) {
                        fl.second_level.map((sl) => {
                            if (sl.id == selected_menu_subscriber.menu_subscriber_id && !added) {
                                if (!sl.third_level) {
                                    sl.third_level = []
                                }
                                sl.third_level.push(selected_menu_subscriber)
                                added = true
                            }
                        })
                    }
                })
            }
        }
    })

    commit('setMenuSubscriber', menu_subscriber)
    commit('resetSelectedMenuSubscriber')

}

export function updateMenuItem({commit, state}) {

    let selected_menu_subscriber = JSON.parse(JSON.stringify(state.selected_menu_subscriber))

    let menu_subscriber = JSON.parse(JSON.stringify(state.menu_subscriber))

    let menu_id = selected_menu_subscriber.id

    let updated = false

    menu_subscriber.map((module) => {
        if (module.id == selected_menu_subscriber.module_id) {
            if (module.first_level && !updated) {
                module.first_level.map((fl, ifl) => {
                    if (fl.id == menu_id) {
                        module.first_level.splice(ifl, 1, selected_menu_subscriber)
                        updated = true
                    }
                    if (fl.second_level && !updated) {
                        fl.second_level.map((sl, isl) => {
                            if (sl.id == menu_id) {
                                fl.second_level.splice(isl, 1, selected_menu_subscriber)
                                updated = true
                            }
                            if (sl.third_level && !updated) {
                                sl.third_level.map((tl, itl) => {
                                    if (tl.id == menu_id) {
                                        sl.third_level.splice(itl, 1, selected_menu_subscriber)
                                        updated = true
                                    }
                                })
                            }
                        })
                    }

                })
            }
        }
    })

    commit('setMenuSubscriber', menu_subscriber)
    commit('resetSelectedMenuSubscriber')

}

export function filterMenus({state, commit}) {

    let menus = state.menus

    let menus_filtered = []

    let menu_subscriber = JSON.parse(JSON.stringify(state.menu_subscriber))

    if (state.selected_menu_subscriber.menu_subscriber_id == null || state.selected_menu_subscriber.menu_subscriber_id == '') {
        menus_filtered = menus.filter((m) => {
            if (m.level == 1) {
                return m
            }
        })
    } else {

        let menu = null

        menu_subscriber.map((module) => {
            if (module.id == state.selected_menu_subscriber.module_id) {
                if (module.first_level) {
                    module.first_level.map((fl) => {
                        if (fl.id == state.selected_menu_subscriber.menu_subscriber_id) {
                            menu = fl
                        }
                        if (fl.second_level) {
                            fl.second_level.map((sl) => {
                                if (sl.id == state.selected_menu_subscriber.menu_subscriber_id) {
                                    menu = sl
                                }
                            })
                        }
                    })
                }
            }
        })

        if (menu) {
            if (menu.menu.level == 1) {
                menus_filtered = menus.filter((m) => {
                    if (m.level == 2) {
                        return m
                    }
                })
            } else if (menu.menu.level == 2) {
                menus_filtered = menus.filter((m) => {
                    if (m.level == 3) {
                        return m
                    }
                })
            }
        }


    }

    commit('setMenusFiltered', menus_filtered)

}

export function changeVisible({commit, state}, menu_change) {

    let menu_subscriber = JSON.parse(JSON.stringify(state.menu_subscriber))

    let menu_id = menu_change.id

    menu_subscriber.map((module) => {
        if (module.id == menu_change.module_id) {
            if (module.first_level) {
                module.first_level.map((fl, ifl) => {
                    if (fl.id == menu_id) {
                        fl.visible = !fl.visible
                    }
                    if (fl.second_level) {
                        fl.second_level.map((sl, isl) => {
                            if (sl.id == menu_id) {
                                sl.visible = !sl.visible
                            }
                        })
                    }

                })
            }
        }
    })

    commit('setMenuSubscriber', menu_subscriber)

}
