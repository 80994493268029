export default {
    sellers: [],
    dates: [],
    coordinates: [],
    coordinate: [],
    polyline: [],
    polyline_displacement: [],
    home: null,
    visits: [],
    coordinates_seller: null,
    event: null,
    play: false,
    show_current_location: false,
    locations: [],
    cordinate: [],
    index: 0,
    interval: null,
    vlocations: [],
    preloader: false,
    live_mode: false,
    show_avatar: true,
    params: {
        seller: null,
        date: null,
        duration: 2000,
        velocity: 50,
        center: false,
    }
};
