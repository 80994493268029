import $http from '../../axios-intance';
import {Notification, MessageBox} from 'element-ui';

export async function fetchUsers({commit, state}) {
    if (!state.users.length) {
        await $http.get('/api/usuarios-por-rol/Jefe comercial')
            .then((response) => {
                commit('setUsers', response.data.data);
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box',
                });
            });
    }
}

export async function fetchLeaderships({commit, state}, requestParameters = {params: state.params}) {
    await $http.get('/api/jefaturas', requestParameters)
        .then((response) => {
            commit('setLeaderships', response.data.data);
            commit('setError', false);
        })

        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
            commit('LeadershipError', error.message);
            commit('setError', true);
        });
}

export async function getSellers({commit, state}) {
    await $http.get('/api/sellers').then((response) => {
        commit('setSellersSelect', response.data.data);
    });
}

export async function addLeadership({commit, state}) {
    await $http.post('/api/jefaturas', state.selectedLeadership)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'La Jefatura ha sido creado',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('resetSelectedLeadership');
            commit('setError', false);
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('LeadershipError', error.message);
            commit('setError', true);
        });
}

export async function updateLeadership({commit, state}) {
    await $http.put('/api/jefaturas/' + state.selectedLeadership.id, state.selectedLeadership)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'El Usuario ha sido actualizado',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('setError', false);
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('LeadershipError', error.message);
            commit('setError', true);
        });
}

export async function getLeadership({commit, state}, id) {
    if (state.getLeadershipEdit) {
        await $http.get(`/api/jefaturas/${id}`)
            .then((response) => {
                let leadership = response.data.data;
                commit('setLeadership', leadership);
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box',
                });
                commit('LeadershipError', error.message);
            });
        commit('setGetLeadership', false);
    }
}


export async function deleteLeadership({commit, state, dispatch}, leadership_id) {
    await $http.delete('/api/jefaturas/' +  leadership_id)
        .then((response) => {
            Notification.success({
                title: 'Muy bien!',
                message: 'La Jefatura ha sido eliminada exitosamente',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            dispatch('fetchLeaderships');
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
        });
}
