export const defaultSelectedWarehouse = () => {
	return {
		code: '',
		description: '',
		address: '',
		latitude: '',
		length: '',
		telephone: '',
		email: '',
		observation: '',
		customer_type_id: null,
		price_list_id: null,
		customer_displacement: 0,
		operation_center_id: null,
		state: 1,
		disable_dynamic_price_list: 0,
		zone: {},
		city: {
			department: {}
		}
	};
};


export default {

	warehouses: [],
	selectedWarehouse: defaultSelectedWarehouse(),
	error: false,
	errorMessage: '',
	actionWarehouse: 'update',
	citiesWarehouses: [],

};
