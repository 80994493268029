import $http from '../../axios-intance'

//Obtener los departamentos
export async function fetchDepartments({commit}) {
    await $http.get('/api/departamentos')
        .then((response) => {
            commit('setDepartments', response.data.data)
        })
        .catch((error) => {
            swal({
                title: "error",
                text: error.message,
                type: "error"
            });
            commit('DepartmentError', error.message)
        })
}
