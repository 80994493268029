import $http from '../../axios-intance';
import {MessageBox, Notification} from 'element-ui';
import moment from 'moment';

export async function fetchContactsWarehouses({commit, state, rootState}, seller_id) {
    if (seller_id) {
        let parameters = {
            params: {
                paginate: false,
                seller_id: seller_id
            }
        };

        commit('setLoader', true);

        await $http.get('/api/establecimientos-vendedor', parameters).then((response) => {
            commit('setLoader', false);
            commit('setContactsWarehouses', response.data.data);
            commit('setColorToContactWarehouses', rootState.deepening.deepening_configurations);
        }).catch((error) => {
            commit('setLoader', false);
            console.log(error);
        });
    }
}

export async function fetchContactsWarehousesByFilter({commit, state, rootState}, filter) {
    if (filter.length >= 3) {
        let parameters = {
            params: {
                paginate: false,
                filter: filter
            }
        };

        await $http.get('/api/establecimientos-vendedor', parameters).then((response) => {
            commit('setContactsWarehouses', response.data.data);

            commit('setColorToContactWarehouses', rootState.deepening.deepening_configurations);


        }).catch((error) => {
            console.log(error);
        });
    }
}

export async function fetchContacts({commit, state}, filter = '') {

    if (!!state.parameters.leadership_id || !!state.parameters.seller_id || filter.length >= 3) {

        let parameters = {
            params: {
                paginate: false,
                type: 'is_customer',
                seller_id: state.parameters.seller_id,
                leadership_id: state.parameters.leadership_id,
                filter: filter
            }
        };

        commit('setLoader', true);

        await $http.get('/api/terceros', parameters)
            .then((response) => {
                commit('setLoader', false);
                commit('setContacts', response.data.data);
            })
            .catch((error) => {
                commit('setLoader', false);
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box',
                });
            });
    }
}

export async function fetchSellers({commit, state}, leadership_id = null) {
    await $http.get('/api/sellers/' + leadership_id).then((response) => {
        commit('setSellers', response.data.data);
    });
}

export async function fetchLeaderships({commit, state}) {

    if (!state.leaderships.length) {
        await $http.get('/api/leaderships').then((response) => {
            commit('setLeaderships', response.data.data);
        });
    }
}

export async function fetchEvents({commit, state}) {

    let parameters = state.parameters;

    parameters.start_date = moment(parameters.start_date).format('YYYY-MM-DD');
    parameters.end_date = moment(parameters.end_date).format('YYYY-MM-DD');

    let params = {
        params: parameters
    };

    commit('setLoader', true);

    await $http.get('/api/eventos', params)
        .then((response) => {
            commit('setEvents', response.data.data);
            commit('setLoader', false);
        }).catch((error) => {
            console.log(error);
            commit('setLoader', false);
        });
}

export async function fetchHolidays({commit, state}) {

    if (!state.holidays.length) {
        await $http.get('/api/eventos/festivos')
            .then((response) => {
                commit('setHolidays', response.data.data);
            }).catch((error) => {
                console.log(error);
            });
    }
}

export async function fetchEvent({commit, state}, event_id) {
    await $http.get('/api/eventos/' + event_id)
        .then((response) => {
            commit('setEvent', response.data.data);
        }).catch((error) => {
            console.log(error);
        });
}

export async function fetchLastEvent({commit, state}) {

    const parameters = {
        params: {
            start_date: state.event.start_date,
            start_time: state.event.start_time,
            seller_id: state.event.seller_id
        }
    };

    await $http.get('/api/eventos/ultimo', parameters)
        .then((response) => {
            commit('updateDistance', response.data.data);
        }).catch((error) => {
            console.log(error);
        });
}


export async function fetchEventsDay({commit, state}, contact_id) {

    let parameters = {
        params: {
            start_date: moment().format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
            seller_id: [contact_id]
        }
    };

    await $http.get('/api/eventos', parameters)
        .then((response) => {
            commit('setEventsDay', response.data.data);
        }).catch((error) => {
            console.log(error);
        });
}

export async function storeEvent({commit, state}, files) {
    return new Promise(async (resolve, reject) => {
        let form_data = new FormData();

        for (const property in state.event) {
            form_data.append(property, state.event[property]);
        }

        for (let i = 0; i < files.length; i++) {
            form_data.append('attachments_loaded[' + i + ']', files[i]);
        }

        await $http.post('/api/eventos', form_data).then((response) => {
            commit('setModalShow', false);
            Notification.success({
                title: 'Muy bien!',
                message: 'Evento agendado correctamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
            resolve();
        }).catch((error) => {
            Notification.error({
                title: 'Error!',
                message: 'Error al agendar el evento',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
            reject();
        });
    });
}

export async function cancelEvent({commit, state}) {
    return new Promise(async (resolve, reject) => {

        await $http.post('/api/eventos/cancelar/' + state.event.id, state.event).then((response) => {
            commit('setModalShow', false);
            Notification.success({
                title: 'Muy bien!',
                message: 'Evento cancelado correctamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
            resolve();
        }).catch((error) => {
            Notification.error({
                title: 'Error!',
                message: 'Error al cancelar el evento',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
            reject();
        });
    });
}

export async function deleteEvent({commit, state}) {
    return new Promise(async (resolve, reject) => {

        const parameters = {
            params: {
                edit_mode: state.edit_mode
            }
        };

        await $http.delete('/api/eventos/' + state.event.id, parameters)
            .then((response) => {
                commit('setModalShow', false);
                Notification.success({
                    title: 'Muy bien!',
                    message: 'Evento eliminado correctamente',
                    type: 'success',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                resolve();
            }).catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: 'Error al eliminar el evento',
                    type: 'error',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                reject();
            });
    });
}

export async function closeEvent({commit, state}) {
    return new Promise(async (resolve, reject) => {
        await $http.post('/api/eventos/atendido/' + state.event.id)
            .then((response) => {
                commit('setModalShow', false);
                Notification.success({
                    title: 'Muy bien!',
                    message: 'Evento marcado como atendido correctamente',
                    type: 'success',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                resolve();
            }).catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: 'Error al marcar el evento como atendido',
                    type: 'error',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                reject();
            });
    });
}

export async function updateEvent({commit, state}, files) {
    return new Promise(async (resolve, reject) => {

        let form_data = new FormData();

        for (const property in state.event) {
            if (property !== 'attachments_deleted' && property !== 'attachments_loaded') {
                form_data.append(property, state.event[property]);
            }
        }

        for (let i = 0; i < files.length; i++) {
            form_data.append('attachments_loaded[' + i + ']', files[i]);
        }

        for (let i = 0; i < state.event.attachments_deleted.length; i++) {
            form_data.append('attachments_deleted[' + i + ']', state.event.attachments_deleted[i].id);
        }

        form_data.append('edit_mode', state.edit_mode);

        commit('setEventEditMode', state.edit_mode);

        await $http.post('/api/eventos/' + state.event.id, form_data)
            .then((response) => {
                commit('setModalShow', false);
                Notification.success({
                    title: 'Muy bien!',
                    message: 'Evento actualizado correctamente',
                    type: 'success',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                resolve();
            }).catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: 'Error al actualizar el evento',
                    type: 'error',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                reject();
            });
    });
}

export async function deleteAttachment({commit, state}, attachment) {
    await MessageBox.confirm('¿Está seguro de eliminar el adjunto?', 'Eliminar adjunto', {
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        customClass: 'notification-danger'
    }).then(async (result) => {
        commit('addAttachmentDeleteEvent', attachment);
        commit('deleteAttachmentEvent', attachment);
    }).catch((e) => {
        console.error(e);
    });
}

export async function downloadAttachment({}, attachment) {
    await $http({
        url: '/storage/events/' + attachment.name_file,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment.original_name);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 3000,
            customClass: 'notification-box',
        });

    });
}

export async function getCoordinates({commit}) {
    navigator.geolocation.getCurrentPosition(
        position => {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;

            commit('setCoordinatesUser', latitude + ',' + longitude);
            commit('setCoordinates', latitude + ',' + longitude);
        },
        error => {
            console.log(error.message);
        },
    );
}

export async function getEventTypes({commit, dispatch, state}) {
    if (!state.event_types.length) {
        await dispatch('getParametersByCodeTable', 'EVET')
            .then(data => commit('setEventTypes', data))
            .catch(e => console.error(e));
    }
}

export async function getParametersByCodeTable({commit, state, dispatch}, code) {
    return new Promise(async (resolve, reject) => {

        await $http.get('/api/parametros-por-codigo/' + code)
            .then((response) => {
                resolve(response.data.data);
            }).catch((error) => {
                Notification.error({
                    title: 'Error',
                    message: 'Error al obtener los parametros',
                    type: 'error',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                reject();
            });
    });
}

/**
 * Obtener actividades.
 */
export async function fetchTypes({commit}) {
    await $http.get('/api/actividades')
        .then((response) => {
            commit('setTypes', response.data.data);
        })
        .catch((error) => {
            Notification.error({
                title: 'Error',
                message: 'Error al obtener las actividades',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}
