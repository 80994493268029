/**
 * Establecer un valor para la variable activities.
 */
import {activity} from './state';

export function setActivities(state, activities) {
    state.activities = activities;
}

/**
 * Establecer un valor para la variable editing.
 */
export function setEditing(state, editing) {
    state.editing = editing;
}

/**
 * Establecer un valor para el atributo show de la variable modal.
 */
export function setModalShow(state, show) {
    state.modal.show = show;
}

/**
 * Establecer un valor para la variable reason.
 */
export function setActivity(state, activity) {
    state.activity = JSON.parse(JSON.stringify(activity));
}

/**
 * Establecer el valor por defecto para la variable activity.
 */
export function resetActivity(state, ) {
    state.activity = activity();
}
