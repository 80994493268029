export const defaultUserLogued = () => {
    return {
        branchoffice_id: null,
        branchoffice_warehouse_id: null,
        contact_id: null,
        contact_warehouse_id: null,
        clerk_id: null,
        email: '',
        id: '',
        module_id: null,
        name: '',
        surname: null,
        password_changed: true,
        photo: null,
        subscriber_id: null,
        type: null,
        session_id: null,
        updated_at: null,
        token: '',
        role: null,
        roles: [],
        branch_office_warehouse: null,
        modules: [],
        subscribers: [],
        contact: {
            employee: {}
        },
        clerk: {}
    }

}

export const defaultUserB2b = () => {
    return {
        id: null,
        description: null,
        code: null,
        credit_quota: 0,
        price_list_id: null,
        contact_id: null,
        seller_id: null,
        pay_condition: null,
        send_point: null,
        telephone: null,
        address: null,
        minimum_order_value: null,
        contact: {},
        seller: {
            employee: {}
        },
        line: {
            line: {}
        }
    }

}

export const defaultAuth = () => {
    return {
        email: "",
        password: ""
    };
}

export default {
    auth: defaultAuth(),
    user_logued: defaultUserLogued(),
    subscriber: {
        id: null,
        maintenance_mode: false,
        number_bearing_activities: 300,
        minutes_bearing: 240,
        work_start_time: '08:00',
        work_end_time: '17:30'
    },
    user_b2b: defaultUserB2b(),
    show_alert_contact: false,
    potential_client_prospect: false,
    status: false,
    logued_type: '',
    forgot: {
        email: "",
        password: "",
        password_confirmation: "",
        token: ""
    },
    change: {
        email: "",
        password: "",
        password_confirmation: "",
    },
    permissions: []
}
