import { defaultSelectedCart } from './state'

export function setCart(state, cart) {
	if (!cart.documents_products){
		cart.documents_products = []
	}

    if (!cart.price_lists){
        cart.price_lists = []
    }

	state.cart = cart
	state.cartInitial = cart
}

export function setCartItems(state, items) {
	state.cart.items = items
}

export function setInformation(state, information) {
	state.cart.information = information
}

export function cartError(state, errorMessage) {
	state.errorMessage = errorMessage
}

export function setTotal(state, total){
	state.total = total
}

export function setPdf(state, pdf){
	state.pdf = pdf
}

export function addItemCart(state, item){

	let documents_products = [];

	if(state.cart.documents_products){
		documents_products = JSON.parse(JSON.stringify(state.cart.documents_products))
	}

	let index = documents_products.findIndex(dp => dp.id == item.id)

	if(index >= 0){
		documents_products.splice(index, 1, item);
	}else{
		documents_products.push(item)
	}

	state.cart.documents_products = documents_products
}

export function deleteItemCart(state, item){

	let documents_products = [];

	documents_products = JSON.parse(JSON.stringify(state.cart.documents_products))

	let index = documents_products.findIndex(dp => dp.id == item.id)

	if(index >= 0){
		documents_products.splice(index, 1);
	}

	state.cart.documents_products = documents_products
}

export function setShowPdf(state, show_pdf){

	state.show_pdf = show_pdf

}

export function setLastCart(state, last_cart){

	state.last_cart = last_cart

}

export function setFreight(state, freight){

    state.freight = freight
    state.freight.cant = 1
    // state.freight.price.percentage = 0

}

export function setContactPropartes(state, contactPropartes){

    if (contactPropartes[0]) {
        state.contactPropartes = contactPropartes[0]
    }

}

/**
 * Establecer un valor para la variable price_list_config.
 */
export function setPriceListConfig(state, price_list_config) {
    state.price_list_config = price_list_config;
}




