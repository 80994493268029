export const defaultSelectedDocument = () => {
    return {
        document_type_id:'',
        action: 'n',
        name: '',
        observation: '',
        path: ''
    }
}

export default {

    documents: [],
    selectedDocument: defaultSelectedDocument(),
    error: false,
    errorMessage: '',
    actionDocument: 'create',
    documentsTypes: [],

}
