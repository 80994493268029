import { defaultSelectedParameter } from "./state";

export function setParameters(state, parameters){
    state.parameters = parameters;
}

export function setParameter(state, parameter){
    state.selectedParameter = JSON.parse(JSON.stringify(parameter));
}
export function setActionParameters(state, action){
    state.actionParameter = action;
}

export function parameterError(state, payload){
    state.error = true
    state.errorMessage = payload
    state.parameter = []
}

export function resetSelectedParameter(state) {
    let id = state.selectedParameter.id
    Object.assign(state.selectedParameter, defaultSelectedParameter())
    if(id){
        state.selectedParameter.id = id
    }
}

//------------------------ PAGINACIÓN ------------------------//

/* export function setFilter(state, filter){
    state.params.page = 1
    state.params.filter = filter
}

export function setPerPage(state, perPage){
    state.params.page = 1
    state.params.perPage = perPage
}

export function setPage(state, page){
    state.params.page = page
}

export function setSortBy(state, sortBy){
    state.params.sortBy = sortBy
}

export function setSort(state, sort){
    state.params.sort = sort
} */

export function setImagenCarga(state, image) {
    state.selectedParameter.image = image
}



