export default {
	products: [],
    brands: [],
    lines:[],
    groups: [],
    categories: [],
	error: false,
	errorMessage: '',
    loading: false,
    products_assistant: [],
    scroll_disabled: false,
    scroll_state: 0,
	params: {
		paginate: true,
		page: 1,
		warehouse: 0,
		only_promotions: false,
		availability: false,
        deepening: false,
		filter_date: '2019-01-01',
        line_id: null,
        filter_brands: [],
        filter_groups: [],
        filter_categories: [],
        filter: '',
        lines:[],
		applications_id: []
	},
    category_params: {
        filter_brands: [],
        filter_groups: [],
        lines: ''
    },
}
