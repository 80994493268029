//Crear tab
export function addVisitedViews(state, route) {

    let exists = true
    for (let i = 0, len = state.tabs.length; i < len; i++){
        if (state.tabs[i].meta.title == route.meta.title){
            state.tabs.splice(i, 1, route)
            exists = false
            break
        }
    }

    if (exists){
        state.tabs = state.tabs.slice(-9)
        state.tabs.push(route)
    }

}

//Eliminar un tab
export function removeVisitedViews(state, route) {
    for (var i = 0, len = state.tabs.length; i < len; i++) {
        if (state.tabs[i].meta.title == route.meta.title) {
            state.tabs.splice(i, 1)
            break
        }
    }
}
