import defaultSelectedVisit from './state';

export function setSellers(state, sellers) {

    sellers.data.map(seller => {
        seller.view_details = false;
        seller.view_details_not = false;
        seller.visitsnoduply = {
            data: [],
            headers: {}
        };
        seller.not_visits = {
            data: [],
            headers: {}
        };
    });

    state.sellers = sellers;
}

export function setSellersWareHouse(state, sellersWareHouses) {
    sellersWareHouses.data.map(sellerWareHouse => {
        sellerWareHouse.view_details = false;
        sellerWareHouse.view_details_not = false;
    });

    state.sellersWareHouses = sellersWareHouses;
}

export function setSellersWareHouseNotVisits(state, sellersWareHousesNoVisits) {

    sellersWareHousesNoVisits.data.map(sellersWareHousesNoVisit => {
        sellersWareHousesNoVisit.view_details = false;
        sellersWareHousesNoVisit.view_details_not = false;
    });

    state.sellersWareHousesNoVisits = sellersWareHousesNoVisits;
}

export function setSellersContact(state, sellers_contacts) {

    let sellers = JSON.parse(JSON.stringify(state.sellers));

    sellers.data.map(seller => {
        if (seller.visitsnoduply) {
            seller.visitsnoduply.data.map(customer_contact => {
                if (customer_contact.id === sellers_contacts.customer_id && sellers_contacts.sellers_contacts[0]) {
                    customer_contact.visitas = sellers_contacts.sellers_contacts;
                }
            });
        }
    });

    state.sellers = sellers;

}

export function sellerError(state, payload) {
    state.error = true;
    state.errorMessage = payload;
    state.sellers = [];
}

export function visitError(state, payload) {
    state.error = true;
    state.errorMessage = payload;
    state.visits = [];
}

export function setFilterDate(state, date) {
    state.params.filter_date = date;
}

export function setSellersSelect(state, payload) {
    state.sellers_select = payload;
}

export function setRute(state, rute) {
    state.rute_export = rute;
}


export function setCities(state, cities) {
    state.cities = cities.map(p => {
        return {value: p.id, label: p.city, key: p.id + p.city_code};
    });
}

export function changeViewDetailCustomer(state, customer_id) {

    state.sellers.data.map(seller => {
        if (seller.visitsnoduply) {
            seller.visitsnoduply.data.map(customer => {
                if (customer.id == customer_id) {
                    customer.view_details = !customer.view_details;
                }
            });
        }

    });
}

export function changeViewDetailCustomerOrder(state, customer_id) {
    state.sellers.data.map(seller => {
        if (seller.visitsnoduply) {
            seller.visitsnoduply.data.map(customer => {
                if (customer.id == customer_id) {
                    customer.view_detail = customer.view_detail;
                }
            });
        }
    });
}

export function changeViewDetail(state, seller_id) {
    state.sellers.data.map(async seller => {
        if (seller.id === seller_id) {
            if (state.set_all_view_details) {
                seller.view_details = state.view_details;
                seller.view_details_not = false;
            } else {
                seller.view_details = !seller.view_details;
                if (seller.view_details) {
                    seller.view_details_not = false;
                }
            }
            seller.visitsnoduply = await state.sellersWareHouses;
        }
    });
}

export function changeViewDetailOrder(state, seller_id) {
    state.sellers.data.map(async seller => {
        if (seller.id === seller_id) {
            seller.view_details = false;
            seller.visitsnoduply = await state.sellersWareHouses;
            seller.view_details = true;
        }
    });

}

export function changeViewDetailNotVisits(state, seller_id) {
    state.sellers.data.map(seller => {
        if (seller.id === seller_id) {
            seller.view_details_not = !seller.view_details_not;
            if (seller.view_details_not) {
                seller.view_details = false;
            }
            seller.not_visits = state.sellersWareHousesNoVisits;
        }
    });

}

export function changeViewDetailNotVisitsOrder(state, seller_id) {
    state.sellers.data.map(async seller => {
        if (seller.id == seller_id) {
            seller.view_details_not = false;
            seller.view_details = false;
            seller.not_visits = await state.sellersWareHousesNoVisits;
            seller.view_details_not = true;
        }
    });

}

export function setViewDetails(state, view_details) {
    state.view_details = view_details;
}

export function setSetAllViewDetails(state, set_all_view_details) {
    state.set_all_view_details = set_all_view_details;
}

export function setPreloader(state, preloader) {
    state.preloader = preloader;
}

export function setLeaderships(state, leaderships) {
    state.leaderships = leaderships;
}

export function setLeadershipIdParams(state, user_id) {
    let leadership = state.leaderships.find(l => l.user_id === user_id);

    if (leadership) {
        state.params.leadership_id = [leadership.id];
    }
}

export function setSellerIdParams(state, seller_id) {
    state.params.seller_id = seller_id;
}

/**
 * Establecer un valor para la variable activity.
 */
export function setActivity(state, activity) {
    state.activity = activity;
}

/**
 * Establecer un valor para el atributo show de la variable modal.
 */
export function setShowToModal(state, show) {
    state.modal.show = show;
}

/**
 * Establecer un valor para la variable visit.
 */
export function setVisit(state, visit) {
    state.visit = visit;
}
