import $http from "../../axios-intance";
import {Notification, MessageBox} from 'element-ui';

//Obtener los funcionarios
export async function fetchClerks({commit, state, rootState}, requestParams = {params:{contact_id:rootState.contacts.selectedContact.id}}) {

    // if (!state.clerks.length) {
        await $http.get('/api/funcionarios', requestParams)
            .then((response) => {
                commit('setClerks', response.data.data)
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 1500,
                    customClass: 'notification-box',
                });
                commit('clerkError', error.message)
            })
    // }
}

//Crear funcionario
export async function addClerk({commit, state, dispatch, rootState}) {

    state.selectedClerk.contact_id = rootState.contacts.selectedContact.id;

    await $http.post('/api/funcionarios', state.selectedClerk)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'El funcionario ha sido creado',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
            commit('resetSelectedClerk')
            dispatch('fetchClerks')
            state.error = false
        })
        .catch((error) => {

            let message;

            if (error.response.status == 422) {
                message = "Algunos campos no se registraron correctamente"
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('clerkError', error.message)
        })
}

//Consultar funcionario por id
export async function getClerk({commit, state}, id) {

    if (state.selectedClerk.id != id) {

        await $http.get(`/api/funcionarios/${id}`)
            .then((response) => {
                commit('setClerk', response.data.data)
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message: error.message,
                    type: 'error',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                commit('clerkError', error.message)
            })
    }

}

//Actualizar funcionario
export async function updateClerk({commit, state, dispatch}) {

    await $http.put(`/api/funcionarios/${state.selectedClerk.id}`, state.selectedClerk)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'El funcionario ha sido actualizado',
                duration: 3000,
                customClass: 'notification-box',
            });
            commit('setActionClerks', 'create')
            commit('resetSelectedClerk')
            dispatch('fetchClerks')
            state.error = false
        })
        .catch((error) => {
            let message;

            if (error.response.status == 422) {
                message = "Algunos campos no se registraron correctamente"
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('clerkError', error.message)
        })
}


//Eliminar funcionario
export async function removeClerk({commit, dispatch}, clerk) {

    await $http.delete(`/api/funcionarios/${clerk.id}`)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'El funcionario ha sido eliminado',
                duration: 3000,
                customClass: 'notification-box',
            });
            dispatch('fetchClerks')
        })
        .catch((error) => {
            MessageBox.alert(error.message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })

            commit('clerkError', e.message)
        })
}


// Obtiene las ciudades para los funcionarios
export async function fetchCitiesClerks({dispatch, state, rootState, commit}) {
    let id = state.selectedClerk.city.department_id
    if (id) {
        await dispatch('cities/fetchCities', {params: {department_id: id}}, {root: true})
        commit('setCitiesClerks', rootState.cities.cities)
    }
}

//Función para mapear los parametros y retornarlos en un mismo formato para todos los select
function mapParameters(parameters) {
    return parameters.map(p => {
        return {value: p.id, label: p.name_parameter}
    })
}