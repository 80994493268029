import $http from '../../axios-intance';
import { Notification,  MessageBox } from 'element-ui';
//Obtener las tablas de parametros
export async function fetchParamTables({commit, state}, requestParameters = {params: state.params}) {
   await $http.get('/api/tablas-parametros', requestParameters)
        .then((response) => {
            commit('setParamTables', response.data.data)
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message:  error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('paramTableError', error.message)
        })
}

//Crear tabla de parametro
export async function addParamTable({commit, state}) {
    await $http.post('/api/tablas-parametros', state.selectedParamTable)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'Tabla de parámetro ha sido creado',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('setParamTable', response.data.data)
            state.error = false
        })
        .catch((error) => {
            let message;

            if (error.response.status == 422) {
                message = error.response.data.errors.code_table[0]
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                confirmButtonText: 'Aceptar',
                type: 'error'
            })
            commit('paramTableError', error.message)
        })
}

//Consultar tabla de parametro por id
export async function getParamTable({commit, state}, id) {
    if (state.getParamTableEdit) {
        await $http.get(`/api/tablas-parametros/${id}`)
            .then((response) => {
                commit('setParamTable', response.data.data)
            })
            .catch((error) => {
                Notification.error({
                    title: 'Error!',
                    message:  error.message,
                    type: 'error',
                    duration: 2000,
                    customClass: 'notification-box',
                });
                commit('paramTableError', error.message)
            })
        commit('setGetParamTableEdit', false)
    }
}

//Actualizar tabla de parametro
export async function updateParamTable({commit, state}) {
    await $http.put(`/api/tablas-parametros/${state.selectedParamTable.id}`, state.selectedParamTable)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'Tabla de parámetro ha sido actualizada',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('setParamTable', response.data.data)
        })
        .catch((error) => {
            let message;

            if (error.response.status == 422) {
                message = error.response.data.errors.code_table[0]
            } else {
                message = error.message
            }

            MessageBox.alert(message, 'Error', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: 'Aceptar',
                type: 'error',
                customClass:'notification-error'
            })
            commit('paramTableError', error.message)
        })
}
//Eliminar tabla de parametro
export async function removeParamTable({commit, dispatch}, paramTable) {

    await $http.delete(`/api/tablas-parametros/${paramTable.id}`)
        .then((response) => {
            Notification.success({
                title: 'Exito!',
                message: 'Tabla de parámetro ha sido eliminada',
                type: 'success',
                duration: 2000,
                customClass: 'notification-box',
            });
            dispatch('fetchParamTables')
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message:  error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
            commit('paramTableError', error.message)
        })
}

export async function fetchParameters({commit }, id) {
    await $http.get(`/api/parametros?paramtable_id=${id}`)
        .then((response) => {
            commit('setParameters', response.data.data)
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message:  error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
        commit('parameterError', error.message)
    })
}


//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter){
    commit('setFilter', filter.target.value)
    dispatch('fetchParamTables')
}

export function setPerPagePagination({commit, dispatch}, perPage){
    commit('setPerPage', parseInt(perPage.target.value))
    dispatch('fetchParamTables')
}

export function setPagePagination({commit, dispatch}, page){
    commit('setPage', parseInt(page))
    dispatch('fetchParamTables')
}

export function setSortByPagination({commit, dispatch}, sortBy){
    commit('setSortBy', sortBy)
    // dispatch('fetchParamTables')
}

export function setSortPagination({commit, dispatch}, sort){
    commit('setSort', sort)
    // dispatch('fetchParamTables')
}
