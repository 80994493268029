import $http from '../../../axios-intance';

export async function getData({state, commit}) {
    commit('setLoading', true);
    await $http.get('/api/data_commercial_management', {params: state.parameters})
        .then(async (response) => {
            await commit('setData', response.data.data);
            commit('setLoading', false);
        });
}

export async function getDetail({state, commit}) {
    commit('setLoading', true);
    await $http.get('/api/detail_commercial_management', {params: state.parameters})
        .then(async (response) => {
            await commit('setDetail', response.data.data);
            commit('setLoading', false);
        });
}

export async function getLeaderships({commit, state}) {
    await $http.get('/api/leaderships').then((response) => {
        commit('setLeaderships', response.data.data);
    });
}

