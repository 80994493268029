export const defaultSelectedContact = () => {
    return {
        subscriber_id: 1,
        identification_type: '',
        identification: '',
        check_digit: '',
        name: '',
        surname: '',
        birthdate: '',
        gender: '',
        city_id: '',
        address: '',
        stratum: '',
        code_ciiu: '',
        main_telephone: '',
        secondary_telephone: '',
        fax: '',
        cell_phone: '',
        email: '',
        email_electronic_invoice: '',
        web_page: '',
        latitude: '',
        length: '',
        is_customer: 0,
        is_provider: 0,
        is_employee: 0,
        class_person: '',
        taxpayer_type: '',
        tax_regime: '',
        declarant: 0,
        exclude_visits: 0,
        customer_displacement: 0,
        self_retainer: 0,
        observations: '',
        customer: {
            contact_id: 0,
            category_id: '',
            seller_id_1: '',
            seller_id_2: '',
            callcenter_advisor_id: '',
            point_of_sale_id: '',
            electronic_invoice_shipping: 0,
            b2b_portal_access: 0,
            priority: '',
            price_list_id: '',
            zone_id: '',
            percentage_discount: '',
            credit_quota: '',
            expiration_date_credit_quota: '',
            days_soon_payment_1: '',
            percentage_soon_payment_1: '',
            days_soon_payment_2: '',
            percentage_soon_payment_2: '',
            days_soon_payment_3: '',
            percentage_soon_payment_3: '',
            last_purchase: '',
            blocked: 0,
            observation: '',
            logo: '',
            retention_source: 0,
            retention_iva: 0,
            retention_ica: 0,
            date_financial_statement: '',
            assets: '',
            liabilities: '',
            heritage: '',
            code_ica_id: '',
        },
        provider: {
            contact_id: 0,
            category_id: '',
            seller_id: '',
            point_of_sale_id: '',
            percentage_commision: '',
            contract_number: '',
            price_list_id: '',
            priority: '',
            percentage_discount: '',
            credit_quota: '',
            expiration_date_credit_quota: '',
            days_soon_payment_1: '',
            percentage_soon_payment_1: '',
            days_soon_payment_2: '',
            percentage_soon_payment_2: '',
            days_soon_payment_3: '',
            percentage_soon_payment_3: '',
            replacement_days: '',
            electronic_order: 0,
            update_shopping_list: 0,
            calculate_sale_prices: 0,
            last_purchase: '',
            observation: '',
            blocked: 0,
            logo: '',
            retention_source: 0,
            percentage_retention_source: '',
            retention_iva: 0,
            percentage_retention_iva: '',
            retention_ica: 0,
            percentage_retention_ica: '',
            code_ica_id: '',
            payment_bank_id: '',
            account_type: '',
            account_number: ''
        },
        employee: {
            contact_id: 0,
            category_id: '',
            cost_center_id: '',
            contract_number: '',
            percentage_commision: '',
            position_id: '',
            observation: '',
            photo: '',
            order_minimum_ammount: ''
        },
        city: {
            department_id: ''
        },
        lines: ''
    };
};

export const defaultContacts = () => {
    return {
        data: [],
        total: 1
    };
};

export default {

    contacts: [],
    selectedContact: defaultSelectedContact(),
    error: false,
    errorMessage: '',
    action: 'create',
    actionWarehouse: 'create',
    actionClerk: 'create',
    actionDocument: 'create',
    contactEdit: null,

    identificationTypes: [], //Tipos de identificación
    departments: [],
    cities: [],
    pointsOfSale: [], // Puntos de venta
    personClasses: [], // Clases de persona
    taxpayerTypes: [], // Tipos de contribuyente
    taxRegimes: [], // Regimenes contributivos

    categoriesCustomers: [], // Categorías de clientes
    sellersCustomers: [], // Vendedores de clientes
    priceListsCustomers: [], // Listas de precios de clientes
    zones: [], // Zonas

    categoriesProviders: [], // Categorías de proveedores
    sellersProviders: [], // Vendedores de proveedores
    priceListsProviders: [], // Listas de precios de proveedores
    paymentBanks: [], // Bancos de pago

    categoriesEmployees: [], // Categorías de empleados
    costCenters: [], // Centros de costos de empleados
    positions: [], // Cargos de empleados

    icaCodes: [], // Códigos de ICA para clientes y proveedores
    customer_types: [], // Códigos de ICA para clientes y proveedores
    operations_center: [], // Códigos de ICA para clientes y proveedores

    is_customer: false,
    is_provider: false,
    is_employee: false,
    customerCategories: [],
    providerCategories: [],
    employeeCategories: [],

    getContactEdit: true,

    lines: [],

    parameters: [
        {paramtable_id: 1, tooltip: ''},
        {paramtable_id: 2, tooltip: ''},
        {paramtable_id: 4, tooltip: ''},
        {paramtable_id: 3, tooltip: ''},
        {paramtable_id: 5, tooltip: ''},
        {paramtable_id: 6, tooltip: ''},
        {paramtable_id: 7, tooltip: ''},
        {paramtable_id: 8, tooltip: ''},
        {paramtable_id: 9, tooltip: ''},
        {paramtable_id: 10, tooltip: ''},
    ],

    params: {
        is_customer: false,
        is_provider: false,
        is_employee: false,
        filter_customer: [],
        filter_provider: [],
        filter_employee: [],
        paginate: true,
        page: 1,
        perPage: 50,
        query: '',
        sortBy: 'id',
        sort: 'ASC'
    }
};
