import {defaultSelectedMenuSubscriber} from "./state";

export function setMenuSubscriber(state, menu_subscriber) {

   menu_subscriber.map((module) => {
        if (module.first_level) {
            module.first_level.map((fl) => {
                if (fl.visible == undefined){
                    fl.visible = true
                }
                if (fl.second_level) {
                    fl.second_level.map((sl) => {
                        if (sl.visible == undefined){
                            sl.visible = true
                        }
                    })
                }
            })
        }
    })

    state.menu_subscriber = menu_subscriber
}

export function setSelectedMenuSubscriber(state, selected_menu_subscriber) {
    state.selected_menu_subscriber = JSON.parse(JSON.stringify(selected_menu_subscriber))
}

export function setSubscribers(state, subscribers) {
    state.subscribers = subscribers
}

export function setModules(state, modules) {
    state.modules = modules
}

export function setMenus(state, menus) {
    state.menus = menus
}

export function setMenusFiltered(state, menus_filtered) {
    state.menus_filtered = menus_filtered
}

export function setParentsMenus(state, parents_menus) {
    state.parents_menus = parents_menus
}

export function cleanMenuId(state) {
    state.selected_menu_subscriber.menu_id = ''
}

export function cleanMenuSubscriberId(state) {
    state.selected_menu_subscriber.menu_subscriber_id = ''
}

export function setAction(state, action) {
    state.action = action;
}

export function menuSubscriberError(state, payload) {
    state.error = true
    state.errorMessage = payload
}

export function setGetMenusubscriber(state, get_menu_subscriber) {
    state.get_menu_subscriber = get_menu_subscriber
}

export function resetSelectedMenuSubscriber(state) {

    let selected_menu_subscriber = JSON.parse(JSON.stringify(state.selected_menu_subscriber))
    Object.assign(state.selected_menu_subscriber, defaultSelectedMenuSubscriber())
    state.selected_menu_subscriber.subscriber_id = selected_menu_subscriber.subscriber_id
}

export function refactorMenu(state) {

    state.menu_subscriber.map((module) => {
        if (module.first_level) {
            module.first_level.map((fl) => {
                fl.module_id = module.id
                if (fl.second_level) {
                    fl.second_level.map((sl) => {
                        sl.module_id = module.id
                        sl.menu_subscriber_id = fl.id
                        if (sl.third_level) {
                            sl.third_level.map((tl, itl) => {
                                tl.menu_subscriber_id = sl.id
                                tl.module_id = module.id
                            })
                        }
                    })
                }
            })
        }
    })
}

