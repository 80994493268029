import $http from '../../axios-intance'
import {Notification} from "element-ui";

//Obtener los lineas
export async function reportGenerate({commit, state, dispatch}, requestParameters = {params: state.params}) {
    await $http.get('/api/reporte-participacion-clientes', requestParameters)
        .then((response) => {
            let url = process.env.VUE_APP_API_URL + '/storage/reports/' + response.data.data

            window.open(url, '_blank');
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.response.data.message,
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
            commit('lineError', error.message)
        })
}

export async function downloadDocument({}, documentObject) {
    await $http({
        url: '/storage/reports/' + documentObject,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentObject);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 3000,
            customClass: 'notification-box',
        });

    })
}
