import {defaultSelectedClerk, defaultSelectedLine, defaultSelectedDocument, defaultSelectedWarehouse} from "./state";

export function setLines(state, lines) {
    state.lines = lines;
}

export function setLine(state, line) {
    state.selectedLine = line;
}

export function setAction(state, action) {
    state.action = action;
}

export function lineError(state, payload) {
    state.error = true
    state.errorMessage = payload
    state.lines = []
}

export function setGetLineEdit(state, getLineEdit) {
    state.getLineEdit = getLineEdit;
}

//Establece el valor por defecto de selectedLine
export function resetSelectedLine(state) {
    let id = state.selectedLine.id
    Object.assign(state.selectedLine, defaultSelectedLine())
    state.selectedLine.id = id
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter){
    state.params.page = 1
    state.params.filter = filter
}

export function setPerPage(state, perPage){
    state.params.page = 1
    state.params.perPage = perPage
}

export function setPage(state, page){
    state.params.page = page
}

export function setSortBy(state, sortBy){
    state.params.sortBy = sortBy
}

export function setSort(state, sort){
    state.params.sort = sort
}
