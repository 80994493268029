import {request_guarantee} from './state';

export function setContactWarehouseIdRequestGuarantee(state, contact_warehouse_id) {
    state.request_guarantee.contact_warehouse_id = contact_warehouse_id;
}

export function setContactWarehouseIdRequestGuaranteeFilters(state, contact_warehouse_id) {
    state.filters.contact_warehouse_id = contact_warehouse_id;
}

export function setModalShow(state, modal_show) {
    state.modal.show = modal_show;
}

export function setStep(state, step) {
    state.step = step;
}

export function setStepAvailable(state, step_available) {
    state.step_available = step_available;
}

export function setRequestsGuarantees(state, requests_guarantees) {

    requests_guarantees.data.map(rg => {
        let step = 1;
        let step_code = '';

        if (rg.request_guarantee_state && rg.request_guarantee_state.state) {
            step_code = rg.request_guarantee_state.state.code_parameter;

            let step_obj = state.steps.find(s => s.code === step_code);

            if (step_obj) {
                step = step_obj.step;
            }
        }

        rg.only_view = step > 1 || step_code === 'PGS9' || step_code === 'PGS5';
    });


    state.requests_guarantees = requests_guarantees;
}

export function setRequestGuarantee(state, request_guarantee) {

    const state_creation = request_guarantee.request_guarantee_states.find(s => s.state.code_parameter === 'PGS1');

    if (state_creation) {
        request_guarantee.observation = state_creation.observation;
    }

    state.request_guarantee = request_guarantee;
    state.request_guarantee.attachments_deleted = [];
}

export function resetRequestGuarantee(state) {
    state.request_guarantee = request_guarantee();
}

export function setAction(state, action) {
    state.action = action;
}

export function addAttachmentDeleteRequestGuarantee(state, attachment) {
    state.request_guarantee.attachments_deleted.push(attachment);
}

export function deleteAttachmentRequestGuarantee(state, attachment) {
    let keys = [
        'images_product',
        'other_images',
        'images_guide'
    ];

    let request_guarantee = JSON.parse(JSON.stringify(state.request_guarantee));

    keys.map(key => {
        let index_attachment = request_guarantee[key].findIndex(a => a.id === attachment.id);

        if (index_attachment >= 0) {
            request_guarantee[key].splice(index_attachment, 1);
        }
    });

    state.request_guarantee = request_guarantee;
}

export function resetPageFilters(state) {
    state.filters.page = 1;
}

export function setSending(state, sending) {
    state.sending = sending;
}


export function setPreloader(state, preloader) {
    state.preloader = preloader;
}




