export function setOrders(state, orders) {
	if (state.params.filter_status.length) {
        state.orders = orders.filter((of) => {
            switch (of.parameter.name_parameter) {
                case 'CUMPLIDO':
                    let pending = 0
                    of.documents_products.forEach(dp => {
                        if (dp.missing_quantity) {
                            pending += dp.missing_quantity
                        }
                    })
                    of.parameter.name_parameter = pending ? 'FACTURADO PARCIAL' : 'FACTURADO'
                    break;
                case 'APROBADO':
                    of.parameter.name_parameter = of.document.length ? 'FACTURADO PARCIAL' : 'APROBADO'
                    break;
            }

            if (state.params.filter_status.indexOf(of.parameter.name_parameter) >= 0) {
                return of
            }
        })
	} else {
		state.orders = orders
	}
}

export function setDocumentStatus(state, status) {
	state.status = status
}

export function productError(state, payload) {
	state.error = true
	state.errorMessage = payload
	state.products = []
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilterDate(state, date) {
	state.params.filter_date = date
}
export function setFilterStatus(state, status) {
	state.params.filter_status = status
}
export function setReference(state, reference) {
	state.params.page = 1
	state.params.reference = reference
}

export function setPerPage(state, perPage) {
	state.params.page = 1
	state.params.perPage = perPage
}

export function setPage(state, page) {
	state.params.page = page
}

export function setSortBy(state, sortBy) {
	state.params.sortBy = sortBy
}

export function setSort(state, sort) {
	state.params.sort = sort
}
