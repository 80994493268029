import $http from "../../axios-intance";
import {
    Notification,
    MessageBox
} from 'element-ui';

//Cargar imagenes al servidor 1 a 1
export async function loadProductImage({
    commit,
    state
}, data) {

    var images = state.loadImages.images;

    // Seteamos los errores
    state.loadImages.errors.has = false;
    state.loadImages.errors.message = [];

    // Recorremos las imagenes y enviamos una a una
    for (var i = 0; i < images.length; i++) {
        var formData = new FormData();
        formData.append("image", images[i]);

        await $http.post('/api/cargar-imagenes-producto', formData)
            .then((response) => {
                state.loadImages.loadedQuantity++;

            })
            .catch((error) => {
                state.loadImages.errors.has = true;
                state.loadImages.errors.message.push(error.response.data.message);
            })
        state.loadImages.processedQuantity++;
    }

    // Lanzamos una notificacion
    Notification.info({
        title: 'Info!',
        message: 'Proceso del cargue finalizado',
        type: 'info',
        duration: 3000,
        customClass: 'notification-box',
    });


}


export async function reportGenerate({commit, dispatch}) {
    commit('setPreloader', true)
    await $http.get('/api/reporte-productos-sin-imagen')
        .then((response) => {
            let url = process.env.VUE_APP_API_URL + '/storage/reports/' + response.data.data

            window.open(url, '_blank');
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.response.data.message,
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
            commit('lineError', error.message)
        })
    commit('setPreloader', false)
}

export async function downloadDocument({}, documentObject) {
    await $http({
        url: '/storage/reports/' + documentObject,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentObject);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 3000,
            customClass: 'notification-box',
        });

    })
}
