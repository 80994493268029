export const defaultSelectedSubcategory = () => {
    return {
        subcategorie_code:'',
        description:'',
    }
}

export default {

    subcategories: [],
    selectedSubcategory: defaultSelectedSubcategory(),
    error: false,
    errorMessage: '',
    actionSubcategory: 'create',
    getContactEdit: true,

}
