import $http from '../../axios-intance'
import {Notification, MessageBox} from 'element-ui';

//Obtener los subcategorias
export async function fetchSubcategories({commit, state, rootState}) {

	// if (!state.subcategories.length) {
		await $http.get(`/api/subcategorias?categorie_id=${rootState.categories.selectedCategory.id}`)
			.then((response) => {
				commit('setSubcategories', response.data.data)
			})
			.catch((error) => {
				Notification.error({
					title: 'Error!',
					message: error.message,
					type: 'error',
					duration: 1500,
					customClass: 'notification-box',
				});
				commit('subcategoryError', error.message)
			})
	// }
}

//Crear funcionario
export async function addSubcategory({commit, state, dispatch, rootState}) {
	state.selectedSubcategory.categorie_id = rootState.categories.selectedCategory.id;

	await $http.post('/api/subcategorias', state.selectedSubcategory)
	.then((response) => {
		Notification.success({
			title: '¡Exito!',
			message: 'La Subcategoria ha sido creada',
			type: 'success',
			duration: 3000,
			customClass: 'notification-box',
		});
		commit('resetSelectedSubcategory')
		dispatch('fetchSubcategories')
		state.error = false
	})
	.catch((error) => {

		let message;

		if (error.response.status == 422) {
			message = error.response.data.errors.subcategory_code[0]
		} else {
			message = error.message
		}

		MessageBox.alert(message, 'Error', {
			confirmButtonText: 'Aceptar',
			type: 'error'
		})

		commit('subcategoryError', error.message)
	})
}

//Consultar funcionario por id
export async function getSubcategory({commit, state}, id) {
	if (state.selectedSubcategory.id != id) {
		await $http.get(`/api/subcategorias/${id}`)
		.then((response) => {
			commit('setSubcategory', response.data.data)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message: error.message,
				type: 'error',
				duration: 3000,
				customClass: 'notification-box',
			});
			commit('subcategoryError', error.message)
		})
	}
}

//Actualizar funcionario
export async function updateSubcategory({commit, state, dispatch}) {
	await $http.put(`/api/subcategorias/${state.selectedSubcategory.id}`, state.selectedSubcategory)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La Subcategoria ha sido actualizada',
			duration: 3000,
			customClass: 'notification-box',
		});
		commit('setActionSubcategories', 'create')
		commit('resetSelectedSubcategory')
		dispatch('fetchSubcategories')
		state.error = false
	})
	.catch((error) => {
		let message;

		if (error.response.status == 422) {
			message =  error.response.data.errors.subcategory_code[0]
		} else {
			message = error.message
		}

		MessageBox.alert(message, 'Error', {
			confirmButtonText: 'Aceptar',
			type: 'error'
		})

		commit('subcategoryError', error.message)
	})
}


//Eliminar funcionario
export async function removeSubcategory({commit, dispatch}, subcategory) {
	await $http.delete(`/api/subcategorias/${subcategory.id}`)
	.then((response) => {
		Notification.success({
			title: '¡Exito!',
			message: 'La Subcategoria ha sido eliminada',
			duration: 3000,
			customClass: 'notification-box',
		});
		dispatch('fetchSubcategories')
	})
	.catch((error) => {
		MessageBox.alert(error.message, 'Error', {
			confirmButtonText: 'Aceptar',
			type: 'error'
		})

		commit('subcategoryError', e.message)
	})
}
