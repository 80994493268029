export function setProductsForm(state, productsForm) {
	state.productsForm = productsForm
}

export function setRelatedCart(state, related) {
	related.forEach(p => {
		p.cant = null, p.total = p.price.price, p.add = false
	});
	state.related = related
}

export function setCartItems(state, items) {
	state.cart.items = items
}

export function cartError(state, payload) {
	state.error = true
	state.errorMessage = payload
	state.cart = []
}

export function setCounting(state, counting) {
	state.counting = counting
}

export function setTime(state, time) {
	state.time = time
}