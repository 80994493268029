export const defaultSelectedRestriction = () => {
    return {
        contact_warehouse_id: null,
        line_id: null,
        brand_id: null
    }
}

export default {
    contacts_warehouses: [],
    selected_contact_warehouse: {
        description: ''
    },
    restrictions: [],
    lines: null,
    brands: null,
    selected_restriction: defaultSelectedRestriction(),
    params: {
        perPage: 15,
        page: 1,
        filter: '',
    }
}
