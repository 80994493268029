export function getParams (state){
    return state.params
}

export function getSelectedContactWarehouse (state){
    return state.selectedContactWarehouse
}

export function getShowAvailabilityModal (state){
    return state.modal.showAvailabilityModal
}


