import L from 'leaflet';
import moment from 'moment';

export function setSellers(state, sellers) {
    state.sellers = sellers;
}

export function setdates(state, dates) {
    state.dates = dates;
}

export function setshow_current_location(state, show_current_location) {
    state.show_current_location = show_current_location;
}

export function setCoordinates(state, coordinates) {

    let locations = [];
    let coordinates_tmp = [];
    let lat1 = 0;
    let lon1 = 0;

    coordinates.map(coo => {
        coo.latitude = parseFloat(coo.latitude);
        coo.longitude = parseFloat(coo.longitude);

        const rad = function (x) {
            return (x * Math.PI) / 180;
        };
        const R = 6378.137; //Radio de la tierra en km
        const dLat = rad(coo.latitude - lat1);
        const dLong = rad(coo.longitude - lon1);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(lat1)) *
            Math.cos(rad(parseFloat(coo.latitude))) *
            Math.sin(dLong / 2) *
            Math.sin(dLong / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;

        let distance = d.toFixed(2);

        if (distance > 0.03) {
            coordinates_tmp.push(coo);

            lat1 = coo.latitude;
            lon1 = coo.longitude;
        }
    });

    state.coordinates = coordinates_tmp;

    state.cordinate = state.coordinates.map((el, i) => ({
        latitude: parseFloat(el.latitude),
        longitude: parseFloat(el.longitude),
    }));

    // Agregar como primeras coordenadas, las coordenadas de la casa del asesor
    // if (!!state.home && !!state.home.latitude && !!state.home.length) {
    //     locations.push({
    //         is_event: false,
    //         datetime: moment(state.params.date).set({hour: 7, minute: 0}).format('YYYY-MM-DD hh:mm'),
    //         time: moment(state.params.date).set({hour: 7, minute: 0}).toDate().getTime(),
    //         latlng: L.latLng(
    //             state.home.latitude,
    //             state.home.length
    //         )
    //     });
    // }

    // Agregar las coordenadas
    state.coordinates.map(el => {
        locations.push({
            is_event: false,
            datetime: el.datetime,
            time: new Date(el.datetime).getTime(),
            latlng: L.latLng(
                el.latitude,
                el.longitude
            )
        });
    });

    // Agregar las coordenadas de las visitas
    state.visits.map(v => {
        let is_displacement = false;

        v.checkin_time = moment(v.checkin_date).format('hh:mm');

        if (!!v.checkout_date && !!v.latitude_checkout && !!v.longitude_checkout) {
            v.checkout_time = moment(v.checkout_date).format('hh:mm');
        } else {
            v.checkout_time = '';
        }

        if (!!v.schedule_event) {
            v.schedule_event.start_time = moment(v.schedule_event.start_date + ' ' + v.schedule_event.start_time).format('hh:mm');
            v.schedule_event.end_time = moment(v.schedule_event.end_date + ' ' + v.schedule_event.end_time).format('hh:mm');
            is_displacement = v.schedule_event.type_id === 2;
        }

        locations.push({
            is_event: true,
            event: v,
            is_displacement,
            datetime: v.checkin_date,
            time: new Date(v.checkin_date).getTime(),
            latlng: L.latLng(
                v.latitude_checkin,
                v.longitude_checkin
            )
        });

        if (!!v.checkout_date && !!v.latitude_checkout && !!v.longitude_checkout) {
            locations.push({
                is_event: false,
                is_displacement,
                datetime: v.checkout_date,
                time: new Date(v.checkout_date).getTime(),
                latlng: L.latLng(
                    v.latitude_checkout,
                    v.longitude_checkout
                )
            });
        }
    });

    state.locations = locations.sort((a, b) => a.time - b.time);

    const polyline = [];
    const polyline_displacement = [];

    state.locations.map(el => {

        if (!el.is_displacement) {
            polyline.push([
                el.latlng.lat,
                el.latlng.lng
            ]);
        } else {
            polyline_displacement.push([
                el.latlng.lat,
                el.latlng.lng
            ]);
        }
    });

    state.polyline = polyline;
    state.polyline_displacement = polyline_displacement;
}

export function resetInterval(state) {
    clearInterval(state.interval);
}

export function setInterval(state, interval) {
    state.interval = interval;
}

export function setVisits(state, visits) {
    state.visits = visits;

    state.vlocations = state.visits.map(el => ({
        marker: L.latLng(el.latitude_checkin, el.longitude_checkin),
        contact: el.contact.identification + '-' + el.contact.name + ' ' + el.contact.surname,
        checkin_date: el.checkin_date.substr(10, 9),
        checkout_date: el.checkout_date.substr(10, 9),
        duration: el.duration,
        observation: el.observation,
        distance: el.distance,
        reason: !!el.reasons ? el.reasons.join(' - ') : '',
        total_documents: el.total_documents,
        total_quantity_documents: el.total_quantity_documents,
        total_collections_visit: el.total_collections_visit,
        collections_visit: el.collections_visit
    }));
}

export function setHome(state, home) {
    state.home = home;
}

export function setPreloader(state, preloader) {
    state.preloader = preloader;
}

export function setDurationParams(state, velocity) {
    const val = 100 - velocity;

    state.params.duration = val === 0 ? 20 : 20 * val;
}

export function setPlay(state, play) {
    state.play = play;
}

export function setIndex(state, index) {
    state.index = index;
}

export function setEvent(state, event) {
    if (!!event) {
        event.see_more = false;
    }
    state.event = event;
}

export function setCoordinatesSeller(state, coordinates_seller) {
    state.coordinates_seller = coordinates_seller;
}

/**
 * Establecer un valor para la variable show_avatar.
 */
export function setShowAvatar(state, show_avatar) {
    state.show_avatar = show_avatar;
}

/**
 * Establecer un valor para la variable live_mode.
 */
export function setLiveMode(state, live_mode) {
    state.live_mode = live_mode;
}

/**
 * Establecer un valor para el atributo date de la variable params.
 */
export function setDateParams(state, date) {
    state.params.date = date;
}

export function addCoordinates(state, coordinates) {

    let locations = [];
    let coordinates_tmp = JSON.parse(JSON.stringify(state.coordinates));

    coordinates.latitude = parseFloat(coordinates.latitude);
    coordinates.longitude = parseFloat(coordinates.longitude);

    coordinates_tmp.push(coordinates);

    state.coordinates = coordinates_tmp;

    state.cordinate = state.coordinates.map((el, i) => ({
        latitude: parseFloat(el.latitude),
        longitude: parseFloat(el.longitude),
    }));

    // Agregar las coordenadas
    state.coordinates.map(el => {
        locations.push({
            is_event: false,
            datetime: el.datetime,
            time: new Date(el.datetime).getTime(),
            latlng: L.latLng(
                el.latitude,
                el.longitude
            )
        });
    });

    state.locations = locations.sort((a, b) => a.time - b.time);

    const polyline = [];
    const polyline_displacement = [];

    state.locations.map(el => {

        if (!el.is_displacement) {
            polyline.push([
                el.latlng.lat,
                el.latlng.lng
            ]);
        } else {
            polyline_displacement.push([
                el.latlng.lat,
                el.latlng.lng
            ]);
        }
    });

    state.polyline = polyline;
    state.polyline_displacement = polyline_displacement;
}

/**
 * Establecer un valor para el atributo see_more de la variable event.
 */
export function setSeeMoreEvent(state) {
    state.event.see_more = true;
}



