import {defaultSelectedBrand} from "./state";

export function setBrands(state, brands) {
    state.brands = brands;
}

export function setBrand(state, brand) {
    state.selectedBrand = brand;
}

export function setAction(state, action) {
    state.action = action;
}

export function brandError(state, payload) {
    state.error = true
    state.errorMessage = payload
    state.brands = []
}

export function setGetBrandEdit(state, getBrandEdit) {
    state.getBrandEdit = getBrandEdit;
}

//Establece el valor por defecto de selectedBrand
export function resetSelectedBrand(state) {
    let id = state.selectedBrand.id
    Object.assign(state.selectedBrand, defaultSelectedBrand())
    state.selectedBrand.id = id
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter){
    state.params.page = 1
    state.params.filter = filter
}

export function setPerPage(state, perPage){
    state.params.page = 1
    state.params.perPage = perPage
}

export function setPage(state, page){
    state.params.page = page
}

export function setSortBy(state, sortBy){
    state.params.sortBy = sortBy
}

export function setSort(state, sort){
    state.params.sort = sort
}

export function setLogo(state, image) {
    state.selectedBrand.image = image
}

export function setLogoMin(state, image) {
    state.selectedBrand.image_min = image
}

export function setProviders(state, providers) {
    state.providers = providers
}
