import {defaultSelectedUser} from "./state";

export function setUsers(state, users) {
	state.users = users;
}

export function setUser(state, license) {
	state.selectedUser = license
}

export function setAction(state, action) {
	state.action = action;
}

export function setError(state, error){
	state.error = error
}
export function UserError (state, payload) {
	state.errorMessage = payload
	state.users = []
}

export function departmentError(state, payload) {
	state.error = true
	state.error = state.errorMessage = payload
}

export function setGetUser(state, getUserEdit) {
	state.getUserEdit = getUserEdit;
}

export function resetSelectedUser(state) {
	Object.assign(state.selectedUser, defaultSelectedUser())
}

export function setSubscribers(state, subscribers) {
	state.subscribers = subscribers;
}

export function setSubscribersFiltered(state, subscribers_filtered) {
	state.subscribers_filtered = subscribers_filtered;
}

export function setBranchOffices(state, branchoffices) {
	state.branchoffices = branchoffices;
}

export function setBranchOfficeWarehouses(state, branchoffice_warehouses) {
	state.branchoffice_warehouses = branchoffice_warehouses;
}

export function setModules(state, modules) {
	state.modules = modules
}

export function setRoles(state, roles) {
	state.roles = roles
}

export function setModulesFiltered(state, modules_filtered) {
	state.modules_filtered = modules_filtered
}

export function photoChange(state, photo) {
	state.selectedUser.photo = photo
}

export function setContacts(state, contacts) {
	state.contacts = contacts
}

export function setContact(state, contact) {
	/*if(state.clearDataUser){
		state.selectedUser.name = ''
		state.selectedUser.surname = ''
		state.selectedUser.email = ''
		state.selectedUser.photo = ''
	}*/
	state.contact = contact
}

export function setGroupedContacts(state, grouped_contacts) {
	state.grouped_contacts = grouped_contacts
}

export function setClerks(state, clerks) {
	state.clerks = clerks
}

export function setFindForId(state, findForId) {
	state.findForId = findForId
}

export function setClearDataUser(state, clearDataUser) {
	state.clearDataUser = clearDataUser
}

export function setDataUser(state, data) {
	state.selectedUser.name = data.name
	state.selectedUser.surname = data.surname
	state.selectedUser.email = data.email
	state.selectedUser.clerk_id = data.clerk_id
	if (data.photo) {
		state.selectedUser.photo = data.photo
	}
	state.selectedUser.contact_id = data.contact_id
	state.selectedUser.type = data.type
}

export function setContactId(state, id) {
	state.selectedUser.contact_id = id
}

export function setClerkId(state, id) {
	state.selectedUser.clerk_id = id
}

export function setDisabledClerk(state, disabledClerk){
	state.disabledClerk = disabledClerk
}

export function setContactsWarehouses(state, contacts_warehouses) {
	state.contacts_warehouses = contacts_warehouses
}

export function clearContactId(state) {
	if(state.clearDataUser){
		state.selectedUser.contact_id = ''
		state.selectedUser.name = ''
		state.selectedUser.surname = ''
		state.selectedUser.email = ''
		state.selectedUser.photo = ''
	}
}

export function setMailList(state, mail_list) {
    state.selectedUser.mail_list = mail_list
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter) {
	state.params.page = 1
	state.params.filter = filter
}

export function setPerPage(state, perPage) {
	state.params.page = 1
	state.params.perPage = perPage
}

export function setPage(state, page) {
	state.params.page = page
}

export function setSortBy(state, sortBy) {
	state.params.sortBy = sortBy
}

export function setSort(state, sort) {
	state.params.sort = sort
}


