import $http from '../../axios-intance'

//Obtener las ciudades
export async function fetchCities({commit}, requestParameters) {
    await $http.get('/api/ciudades', requestParameters)
        .then((response) => {
            commit('setCities', response.data.data)
        })
        .catch((error) => {
            swal({
                title: "error",
                text: error.message,
                type: "error"
            });
            commit('CityError', error.message)
        })
}
