import moment from 'moment';

export default {
	orders: [],
	transporter: [],
	products: [],
	status:[],
	error: false,
	errorMessage: '',
	params: {
		filter_date: [
            moment().add(-1, 'months').add(1, 'days').toDate(),
            moment().toDate()
        ],
        billing_status: [],
		filter_status: [],
		warehouse: 0,
		consecutive: ''
	},
}
