import $http from '../../axios-intance'
import {Notification} from 'element-ui'


export function setPerPagePagination({commit, dispatch}, perPage) {
    commit('setPerPage', parseInt(perPage.target.value))
    dispatch('getContactsWarehouses')
}

export function setPagePagination({commit, dispatch}, page) {
    commit('setPage', parseInt(page))
    dispatch('getContactsWarehouses')
}


export async function getContactsWarehouses({state, commit}) {
    await $http.get('/api/sucursales', {params: state.params})
        .then((response) => {
            commit('setContactsWarehouses', response.data.data)
        })
}

export async function getContactWarehouseById({commit}, id) {
    await $http.get(`/api/establecimientos/${id}`).then((response) => {
        commit('setSelectedContactWarehouse', response.data.data)
    })
}

export async function getLines({commit}) {
    await $http.get('/api/lineas').then((response) => {
        commit('setLines', response.data.data)
    }).catch((error) => {
        console.log(error)
    })
}

export async function geBrands({state, commit}) {
    let line = state.lines.find((i) => i.id === state.selected_restriction.line_id);
    await $http.get('/api/marcas', {params: {paginate: false, lines: line.line_code}}).then((response) => {
        commit('setBrands', response.data.data)
    }).catch((error) => {
        console.log(error)
    })
}

export async function updateOrCreateCustomerRestriction({state, commit, dispatch}) {

    let data = state.selected_restriction

    data.contact_warehouse_id = state.selected_contact_warehouse.id

    await $http.post('/api/restriccion-marcas', data).then((response) => {
        Notification.success({
            title: 'success!',
            message: 'Restricción guardada exitosamente',
            type: 'success',
            duration: 1500,
            customClass: 'notification-box',
        });

        commit('resetSelectedRestriction')
        dispatch('getCustomerRestrictions', state.selected_contact_warehouse.id)
    })
}

export async function getCustomerRestrictions({commit}, id) {
    await $http.get(`/api/restriccion-marcas/${id}`).then((response) => {
        commit('setRestrictions', response.data.restrictions)
    })
}

export async function deleteRestriction({state, dispatch}, id) {

    await $http.delete('/api/restriccion-marcas/' + id).then((response) => {
        Notification.success({
            title: 'success!',
            message: 'Restricción eliminada exitosamente',
            type: 'success',
            duration: 1500,
            customClass: 'notification-box',
        });
        dispatch('getCustomerRestrictions', state.selected_contact_warehouse.id)
    })
}
