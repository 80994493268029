export const defaultSelectedLeadership = () => {
	return {
		leadership: null,
		seller_list: [],
        user_id: null
	}
}

export default {
	leaderships: [],
    users: [],
	sellers_select: [],
    selectedLeadership: defaultSelectedLeadership(),
    roles:[],
	action: 'create',
	error: false,
	errorMessage: '',
	getLeadershipEdit: true,
	branchoffices: [],
	subscribers: [],
	subscribers_filtered: [],
	branchoffice_warehouses: [],
	modules: [],
	modules_filtered: [],
	contacts: [],
	grouped_contacts: [],
	clerks: [],
	disabledClerk: true,
	contact : null,
	contacts_warehouses: [],
	findForId: false,
	clearDataLeadership: true,
	params: {
		paginate: true,
		page: 1,
		perPage: 15,
		filter: '',
		sortBy: 'leadership',
		sort: 'ASC'
	}
}
