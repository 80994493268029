import $http from '../../axios-intance';
import {Notification} from 'element-ui';

//Obtener las bodegas
export async function fetchBranchOfficeWarehouses({commit, state}, requestParameters = {params: state.params}) {
    await $http.get('/api/bodegas-sedes', requestParameters)
        .then((response) => {
            commit('setBranchofficeWarehouses', response.data.data)
        })
        .catch((error) => {
            Notification.error({
                title: 'Error!',
                message: error.message,
                type: 'error',
                duration: 2000,
                customClass: 'notification-box',
            });
        })
}
