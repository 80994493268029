export function setProducts(state, products) {
    if (state.params.page === 1) {
        state.products_assistant = [];
    }

    products.data.forEach(p => {

        let cant = p.documents_products && p.documents_products[0] ? p.documents_products[0].quantity : 1;

        p.cant = cant;

        if (p.price.percentage > 0) {
            p.total = (p.price.price - p.price.price * p.price.percentage / 100) * cant;
            p.total_suggested_price = (p.suggested_price.price - p.suggested_price.price * p.price.percentage / 100) * cant;
        } else {
            p.total = p.price.price * cant;
            p.total_suggested_price = p.suggested_price.price * cant;
        }

        if (!state.params.only_promotions || p.price.percentage > 0) {
            state.products_assistant.push(p)
        }
    });

    state.products = products;

    state.scroll_disabled = !state.products.data.length;
}

export function productError(state, payload) {
    state.error = true;
    state.errorMessage = payload;
    state.products = [];
}

export function setProductsData(state, products) {
    state.products.data = products;
}

export function setFilterLine(state, line = []) {
    state.params.filter_lines = line;
}

export function setFilterBrand(state, brand = []) {
    state.params.filter_brands = brand;
    state.category_params.filter_brands = brand;
}

export function setFilterGroup(state, sublines = []) {
    state.params.filter_groups = sublines;
    state.category_params.filter_groups = sublines;
}

export function setFilterCategories(state, categories = []) {
    state.category_params.lines = state.params.lines;
    state.params.filter_categories = categories;
}

export function setBrands(state, brands) {
    state.brands = brands.map(p => {
        return {value:p.id, label: p.description, imagen:p.thumbnails}
    })
}

export function setCategories(state, categories) {
    state.categories = categories.map(p => {
        return {value:p.id, label: p.description}
    })
}

export function setGroups(state, groups) {
    state.groups = groups.map(p => {
        return {value:p.id, label: p.subline_description}
    })
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter) {
    state.params.page = 1;
    state.params.filter = filter;
}

export function setReference(state, reference) {
    state.params.page = 1;
    state.params.reference = reference;
}

export function setPerPage(state, perPage) {
    state.params.page = 1;
    state.params.perPage = perPage;
}

export function setPage(state, page) {
    state.params.page = page;
}

export function setSortBy(state, sortBy) {
    state.params.sortBy = sortBy;
}

export function setSort(state, sort) {
    state.params.sort = sort;
}

export function setLoading(state, loading) {
    state.loading = loading;
}

export function setScrollDisabled(state, scroll_disabled) {
    state.scroll_disabled = scroll_disabled;
}


export function setScroll(state, scroll_state) {
    state.scroll_state = scroll_state;
}

export function resetProducts(state) {
    state.products = {
        data: {}
    };

    state.products_assistant = [];
}


