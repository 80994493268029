import $http from '../../axios-intance';
import { Notification } from 'element-ui';

export async function fetchLicenses ({commit, state}, requestParameters = {params: state.params}) {
   await $http.get('/api/licencias', requestParameters)
		.then((response) => {
			commit('setLicenses', response.data.data)
		})
	.catch ((error) => {
		Notification.error({
			title: 'Error!',
			message:  error.message,
			type: 'error',
			duration: 2000,
			customClass: 'notification-box',
		});
		commit('licensesError', error.message)
	})
}

export async function addLicense ({commit, dispatch, state}) {
	await $http.post('/api/licencias', state.selectedLicense)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La Licencia ha sido creada',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box',
		  });
		dispatch('fetchLicenses')
		commit('setLicense', response.data.data)
		state.error = false
	})
	.catch ((error) => {
		let message;

			if (error.response.status == 422) {
				message = error.response.data.errors.license_code[0]
				state.error = true
			} else {
				message = error.message
			}

			MessageBox.alert(message, 'Error', {
				confirmButtonText: 'Aceptar',
				type: 'error'
		})
		commit('licensesError', error.message)
	})
}

export async function getLicense({commit, state}, id) {
	if (state.getLicenseEdit) {
		await $http.get(`/api/licencias/${id}`)
			.then((response) => {
				commit('setLicense', response.data.data)
			})
			.catch((error) => {
				Notification.error({
					title: 'Error!',
					message: error.message,
					type: 'error',
					duration: 1500,
					customClass: 'notification-box',
				});
				commit('licensesError', error.message)
			})

		commit('setGetLicense', false)
	}
}

export async function updateLicense ({commit, state, dispatch}) {
	$http.put(`/api/licencias/${state.selectedLicense.id}`, state.selectedLicense)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La Licencia ha sido actualizada',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box',
		});
		commit('setLicense', response.data.data)
	})
	.catch ((error) => {
		let message;

			if (error.response.status == 422) {
				message = error.response.data.errors.license_code[0]
			} else {
				message = error.message
			}

			MessageBox.alert(message, 'Error', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: 'Aceptar',
				type: 'error',
				customClass:'notification-error'
		})
		commit('licensesError', error.message)
	})
}

export async function removeLicense ({commit, dispatch}, license) {
	$http.delete(`/api/licencias/${license.id}`).then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La Licencia ha sido eliminada',
			type: 'success',
			duration: 2000,
			customClass: 'notification-box',
		});
		dispatch('fetchLicenses')
	})
  .catch ((error) => {
	Notification.error({
		title: 'Error!',
		message: error.message,
		type: 'error',
		duration: 2000,
		customClass: 'notification-box',
	})
	commit('licensesError', error.message)
  })
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({commit, dispatch}, filter){
	commit('setFilter', filter.target.value)
	dispatch('fetchLicenses')
}

export function setPerPagePagination({commit, dispatch}, perPage){
	commit('setPerPage', parseInt(perPage.target.value))
	dispatch('fetchLicenses')
}

export function setPagePagination({commit, dispatch}, page){
	commit('setPage', parseInt(page))
	dispatch('fetchLicenses')
}

export function setSortByPagination({commit, dispatch}, sortBy){
	commit('setSortBy', sortBy)
	// dispatch('fetchLicenses')
}

export function setSortPagination({commit, dispatch}, sort){
	commit('setSort', sort)
	// dispatch('fetchLicenses')
}
