import { defaultSelectedBanner } from './state'

export function setBanners (state, banners) {
	state.banners = banners
}

export function setBanner (state, banner) {
	state.selectedBanner = banner
}

export function bannerError (state, payload) {
	state.error = true
	state.errorMessage = payload
	state.banners = []
}

export function setAction(state, action) {
	state.action = action;
}

export function resetSelectedBanner (state) {
	Object.assign(state.selectedBanner, defaultSelectedBanner())
}

export function setGetBannerEdit (state, getBannerEdit) {
	state.getBannerEdit = getBannerEdit
}

export function setUrlBigBanner (state, url_big_banner) {
    state.selectedBanner.url_big_banner = url_big_banner
}

export function setUrlSmallBanner (state, url_small_banner) {
    state.selectedBanner.url_small_banner = url_small_banner
}

export function resetBigBanner (state) {
    state.selectedBanner.url_big_banner = null
    state.selectedBanner.big_banner = null
}

export function resetSmallBanner (state) {
    state.selectedBanner.url_small_banner = null
    state.selectedBanner.small_banner = null
}

export function setSinceDate (state, since) {
    state.selectedBanner.since = since
}

export function setUntilDate (state, until) {
    state.selectedBanner.until = until
}

/* Paginación */
export function setFilter (state, filter) {
	state.params.page = 1
	state.params.filter = filter
}

export function setPerPage (state, perPage) {
	state.params.page = 1
	state.params.perPage = perPage
}

export function setPage (state, page) {
	state.params.page = page
}

export function setSortBy (state, sortBy) {
	state.params.sortBy = sortBy
}

export function setSort (state, sort) {
	state.params.sort = sort
}


export function setLines(state, lines) {
	state.lines = lines.map(p => {
		return {value: p.id, label: p.line_description}
	})
}

export function setParameters(state, parameters) {
	state.parameters = parameters
}

export function setBigBannerImage(state, big_banner) {
	state.selectedBanner.big_banner = big_banner
}

export function setSmallBannerImage(state, small_banner) {
	state.selectedBanner.small_banner = small_banner
}
