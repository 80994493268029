import $http from '../../axios-intance';
import {Notification} from 'element-ui';

export async function getDeepeningConfigurations({commit}) {

    commit('setPreloader', true);

    await $http.get('/api/profundizacion/')
        .then((response) => {
            commit('setDeepeningConfigurations', response.data.data);
            commit('setPreloader', false);
        })
        .catch(() => {
            commit('setPreloader', false);
            Notification.error({
                title: 'Error',
                message: 'Error al obtener las configuraciones de profundización.',
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
        });
}

export async function updateDeepeningConfigurations({state, dispatch, commit}, id) {

    commit('setPreloader', true);
    commit('parseQuantityDeepeningConfiguration');

    await $http.put('/api/profundizacion/' + id, state.deepening_configuration)
        .then(async (response) => {
            await dispatch('getDeepeningConfigurations');
            commit('setPreloader', false);
            Notification.success({
                title: 'Muy bien',
                message: 'La configuración de profundización se actualizó correctamente.',
                type: 'success',
                duration: 1500,
                customClass: 'notification-box',
            });
        })
        .catch(() => {
            commit('setPreloader', false);
            Notification.error({
                title: 'Error',
                message: 'Error al guardar las configuración de profundización.',
                type: 'error',
                duration: 1500,
                customClass: 'notification-box',
            });
        });
}
