import {defaultSelectedSubline} from "./state";

export function setSublines(state, sublines) {
    state.sublines = sublines;
}

export function setSubline(state, subline) {
    state.selectedSubline = JSON.parse(JSON.stringify(subline));
}

export function setActionSublines(state, action) {
    state.actionSubline = action;
}

export function sublineError(state, error) {
    state.error = true
    state.errorMessage = error
}

//Establece el valor por defecto de selectedSubline
export function resetSelectedSubline(state) {
    let id = state.selectedSubline.id
    Object.assign(state.selectedSubline, defaultSelectedSubline())
    if (id) {
        state.selectedSubline.id = id
    }
}
