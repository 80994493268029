import {commercial_direction} from './state';

/**
 * Establecer un valor para la variable commercial_directions.
 */
export function setCommercialDirections(state, commercial_directions) {
	state.commercial_directions = commercial_directions;
}

/**
 * Establecer un valor para la variable commercial_direction.
 */
export function setCommercialDirection(state, commercial_direction) {
	let leaderships = [];

	if (commercial_direction.commercial_direction_leaderships) {
		commercial_direction.commercial_direction_leaderships.map(ls => {
			leaderships.push(ls.leadership_id);
		});
	}

	commercial_direction.commercial_direction_leaderships_ids = leaderships;

	state.commercial_direction = commercial_direction;
}

/**
 * Establecer un valor para la variable action.
 */
export function setAction(state, action) {
	state.action = action;
}

/**
 * Establecer un valor para la variable leaderships.
 */
export function setLeaderships(state, leaderships) {
	state.leaderships = leaderships;
}

/**
 * Establecer el valor por defecto de la variable commercial_direction.
 */
export function resetCommercialDirection(state) {
    state.commercial_direction = commercial_direction();
}

/**
 * Establecer un valor para la variable users.
 */
export function setUsers(state, users) {
    state.users = users;
}