import {defaultSelectedCategory} from "./state";

export function setCategories(state, categories) {
    state.categories = categories;
}

export function setCategory(state, category) {
    state.selectedCategory = category;
}

export function setAction(state, action) {
    state.action = action;
}

export function categoryError(state, payload) {
    state.error = true
    state.errorMessage = payload
    state.categories = []
}

export function setGetCategoryEdit(state, getCategoryEdit) {
    state.getCategoryEdit = getCategoryEdit;
}

//Establece el valor por defecto de selectedCategory
export function resetSelectedCategory(state) {
    let id = state.selectedCategory.id
    Object.assign(state.selectedCategory, defaultSelectedCategory())
    state.selectedCategory.id = id
}

//------------------------ PAGINACIÓN ------------------------//

export function setFilter(state, filter){
    state.params.page = 1
    state.params.filter = filter
}

export function setPerPage(state, perPage){
    state.params.page = 1
    state.params.perPage = perPage
}

export function setPage(state, page){
    state.params.page = page
}

export function setSortBy(state, sortBy){
    state.params.sortBy = sortBy
}

export function setSort(state, sort){
    state.params.sort = sort
}
