import moment from 'moment';

export default {
    data: [],
    detail: [],
    leaderships: [],
    parameters: {
        seller_id: null,
        number_bearing_activities: 300,
        minutes_bearing: 240,
        dates: [
            moment().startOf('month').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
        ]
    },
    loading: false
};
