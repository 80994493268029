import moment from 'moment';


export const event = () => {
    return {
        id: null,
        event_id: null,
        type_id: null,
        description: '',
        leadership_id: null,
        commercial_direction_id: null,
        seller_id: null,
        contact_warehouse_id: null,
        address: '',
        telephone: '',
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        repeat_id: null,
        repeat_every: 1,
        repeat_on: [],
        end_id: 1,
        repeat_until: moment().add(1, 'month').format('YYYY-MM-DD'),
        repeat_count: null,
        observations: '',
        comments: '',
        attachments: [],
        attachments_loaded: [],
        edit_mode: 'event',
        startTime: null,
        endTime: null,
        user_creator_id: null,
        user_last_modified_id: null,
        user_creator: {},
        user_last_modified: {},
        visit_customer_id: null,
        visit_customer: null,
        attended: false,
        attended_date: null,
        attended_time: null,
        reason_cancellation_id: null
    };
};

export const timePickerOptionsStart = () => {

    const subscriber_ls = JSON.parse(localStorage.getItem('subscriber'))

    if (!subscriber_ls){
        return {
            start: '07:00',
            step: '00:30',
            end: '17:30'
        };
    }

    return {
        start: subscriber_ls.work_start_time,
        step: '00:30',
        end: subscriber_ls.work_end_time,
    };
};

export const timePickerOptionsEnd = () => {

    const subscriber_ls = JSON.parse(localStorage.getItem('subscriber'))

    if (!subscriber_ls) {
        return {
            start: '07:30',
            step: '00:30',
            end: '18:00'
        };
    }

    return {
        start: subscriber_ls.work_start_time,
        step: '00:30',
        end: subscriber_ls.work_end_time,
    };
};
export default {
    contacts_warehouses: [],
    contacts: [],
    selected_date: moment().toDate(),
    current_view: 'WorkWeek',
    show_agenda: false,
    contacts_warehouses_filtered: [],
    leaderships: [],
    sellers: [],
    deepenings: [],
    coordinates: {
        value: null,
        user: null,
    },
    modal: {
        show: false
    },
    would_you_just_run: {
        value: false
    },
    modal_advanced: {
        show: false
    },
    params: {
        paginate: false,
        seller_id: null
    },
    event_types: [],
    event_type_selected: {
        value: false
    },
    events: [],
    holidays: [],
    events_day: [],
    event: event(),
    mode_weekend: false,
    action: 'Crear',
    disabled_observation: false,
    loading_editor: false,
    time_picker_options_start: timePickerOptionsStart(),
    time_picker_options_end: timePickerOptionsEnd(),
    parameters: {
        start_date: moment().startOf('month'),
        end_date: moment().endOf('month'),
        leadership_id: null,
        commercial_direction_id: null,
        seller_id: null,
        contact_id: null,
        type_id: [],
        deepening_id: null,
        would_you_just_run: false,
        executed: false
    },
    edit_mode: 'all',
    allow_close: false,
    title_distance: 'Dist.',
    types: [],
    loader: true,
    subscriber: {
        work_start_time: '08:00',
        work_end_time: '18:00'
    },
    modal_settings: {
        show: false
    }
};
