export function getDaysDebt(state, payload) {
    state.customDay = payload;
}

export function setReceivables(state, receivables) {
    state.receivables = receivables;
}

export function receivableError(state, receivables) {
    state.error = true;
    state.errorMessage = receivables;
    state.receivables = [];
}

export function setFilterDay(state, date) {
    state.params.page = 1;
    state.params.filter_date = date;
}

export function setFilterWarehouse(state, name) {
    state.params.page = 1;
    state.params.filter_name = name;
}

export function setFilter(state, filter) {
    state.params.page = 1;
    state.params.filter = filter;
}

export function setPerPage(state, perPage) {
    state.params.page = 1;
    state.params.perPage = perPage;
}

export function setPage(state, page) {
    state.params.page = page;
}

export function setSellers(state, payload) {
    state.sellers = payload;
}

export function setPreloader(state, preloader) {
    state.preloader = preloader;
}

export function setLeaderships(state, leaderships) {
    state.leaderships = leaderships;
}

export function setLeadershipIdParams(state, user_id) {

    let leadership = state.leaderships.find(l => l.user_id === user_id);

    if (leadership) {
        state.params.leadership_id = [leadership.id];
    }
}

export function setSellerIdParams(state, seller_id) {
    state.params.seller_id = seller_id;
}
