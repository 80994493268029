import $http from '../../../axios-intance'
import { Notification } from 'element-ui'

// Obtener banners
export async function fetchReceivables({ commit, state }, requestParameters = { params: state.params }) {
    await $http.get('/api/cartera', requestParameters)
        .then((response) => {
            commit('setReceivables', response.data.data)
        }).catch((error) => {
            Notification.error({
                title: '¡Error!',
                message: error.message,
                type: 'error',
                duration: 1500,
                customClass: 'notification-box'
            })
            commit('receivableError', error.message)
        })
}

/* export async function fetchBrand({ dispatch, state, rootState, commit }) {
    if (!state.brands.length) {
        await dispatch('brands/fetchBrands', { params: { paginate: false } }, { root: true })
        commit('setBrands', rootState.brands.brands)
    }
}
 */


//------------------------ PAGINACIÓN ------------------------//

export function setFilterPagination({ commit, dispatch }, filter) {
    commit('setFilter', filter.target.value)
    dispatch('fetchReceivables')
}

export function setFilterReference({ commit, dispatch }, reference) {
    commit('setReference', reference.target.value)
    dispatch('fetchReceivables')
}

export function setPerPagePagination({ commit, dispatch }, perPage) {
    commit('setPerPage', parseInt(perPage.target.value))
    dispatch('fetchReceivables')
}

export function setPagePagination({ commit, dispatch }, page) {
    commit('setPage', parseInt(page))
    dispatch('fetchReceivables')
}

export function setSortByPagination({ commit, dispatch }, sortBy) {
    commit('setSortBy', sortBy)
}

export function setSortPagination({ commit, dispatch }, sort) {
    commit('setSort', sort)
}
