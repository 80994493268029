import $http from "../../axios-intance";
import {Notification, MessageBox} from 'element-ui';

//Obtener los submarcas
export async function fetchSubbrands({commit, state, rootState}) {
	await $http.get(`/api/submarcas?brand_id=${rootState.brands.selectedBrand.id}`)
	.then((response) => {
		commit('setSubbrands', response.data.data)
	})
	.catch((error) => {
		Notification.error({
			title: 'Error!',
			message: error.message,
			type: 'error',
			duration: 1500,
			customClass: 'notification-box',
		});
		commit('subbrandError', error.message)
	})
}

//Crear funcionario
export async function addSubbrand({commit, state, dispatch, rootState}) {
	state.selectedSubbrand.brand_id = rootState.brands.selectedBrand.id;

	await $http.post('/api/submarcas', state.selectedSubbrand)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La Submarca ha sido creada',
			type: 'success',
			duration: 3000,
			customClass: 'notification-box',
		});
		commit('resetSelectedSubbrand')
		dispatch('fetchSubbrands')
		state.error = false
	})
	.catch((error) => {

		let message;

		if (error.response.status == 422) {
			message = error.response.data.errors.subbrand_code[0]
		} else {
			message = error.message
		}

		MessageBox.alert(message, 'Error', {
			confirmButtonText: 'Aceptar',
			type: 'error'
		})

		commit('subbrandError', error.message)
	})
}

//Consultar funcionario por id
export async function getSubbrand({commit, state}, id) {
	if (state.selectedSubbrand.id != id) {
		await $http.get(`/api/submarcas/${id}`)
		.then((response) => {
			commit('setSubbrand', response.data.data)
		})
		.catch((error) => {
			Notification.error({
				title: 'Error!',
				message: error.message,
				type: 'error',
				duration: 3000,
				customClass: 'notification-box',
			});
			commit('subbrandError', error.message)
		})
	}
}

//Actualizar funcionario
export async function updateSubbrand({commit, state, dispatch}) {
	await $http.put(`/api/submarcas/${state.selectedSubbrand.id}`, state.selectedSubbrand)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La Submarca ha sido actualizada',
			duration: 3000,
			customClass: 'notification-box',
		});
		commit('setActionSubbrands', 'create')
		commit('resetSelectedSubbrand')
		dispatch('fetchSubbrands')
		state.error = false
	})
	.catch((error) => {
		let message;

		if (error.response.status == 422) {
			message =  error.response.data.errors.subbrand_code[0]
		} else {
			message = error.message
		}

		MessageBox.alert(message, 'Error', {
			confirmButtonText: 'Aceptar',
			type: 'error'
		})

		commit('subbrandError', error.message)
	})
}


//Eliminar funcionario
export async function removeSubbrand({commit, dispatch}, subbrand) {
	await $http.delete(`/api/submarcas/${subbrand.id}`)
	.then((response) => {
		Notification.success({
			title: 'Exito!',
			message: 'La Submarca ha sido eliminada',
			duration: 3000,
			customClass: 'notification-box',
		});
		dispatch('fetchSubbrands')
	})
	.catch((error) => {
		MessageBox.alert(error.message, 'Error', {
			confirmButtonText: 'Aceptar',
			type: 'error'
		})

		commit('subbrandError', e.message)
	})
}

export function cargaSubmarca({commit}, image) {
	commit('setImagenCarga', image)
}

export function documentChangePath({commit}, path) {
	commit('setDocumentPath', path)
}

//Descargar documento
export async function downloadDocument({state}) {
	let extension = state.selectedSubbrand.data_sheet.split('.').pop()
	await $http({
		url: state.selectedSubbrand.data_sheet,
		method: 'GET',
		responseType: 'blob', // important
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', state.selectedSubbrand.description + '.' + extension);
		document.body.appendChild(link);
		link.click();
	}).catch((error) => {
		Notification.error({
			title: 'Error!',
			message: error.message,
			type: 'error',
			duration: 3000,
			customClass: 'notification-box',
		});

	})
}

