export function setSales(state, sales) {
    state.sales = sales
}

export function setContact(state, contact) {
  state.contact = contact
}

export function setLoading(state, loading) {
  state.loading = loading
}

