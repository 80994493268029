export const defaultSelectedProduct = () => {
	return {
		total: 0,
		quantity_available: '',
		long_description: '',
		code: '',
		cant: null,
		click: 0,
		brand: {},
		category: {},
		line: {},
		products_attachments: [],
		subline: {},
		price: {},
		industry_price: {}
	}
}

export default {
	brand: [],
	selectedProduct: defaultSelectedProduct(),
	related: [],
	product: [],
	group: [],
	category: [],
	imagen: '',
	error: false,
	errorMessage: '',
	params: {
		price_list_id: 0
	}
}
