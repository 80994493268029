export const defaultSelectedMenuSubscriber = () => {
    return {
        subscriber_id: '',
        module_id: '',
        order: '',
        menu_id: '',
        menu_subscriber_id: '',
        alternate_description: '',
        menu: {
            description: ''
        }
    }
}
export default {

    menu_subscriber: [],
    selected_menu_subscriber: defaultSelectedMenuSubscriber(),
    subscribers: [],
    modules: [],
    parents_menus: [],
    menus: [],
    menus_filtered: [],
    error: false,
    error_message: '',
    action: 'create',
    get_menu_subscriber: true,

}
