export function setDepartments(state, departments){
    state.departments = departments;
}

export function setDepartment(state, department){
    state.selectedDepartment = department;
}

export function departmentError(state, payload){
    state.error = true
    state.error = errorMessage = payload
    state.todo = []
}
